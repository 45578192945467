import axios from 'axios';
import Qs from 'qs'

let testUrl = '//api.csatqc.com/api';       // 生成地址
//let testUrl = '//42.194.138.214/antuo';       // 测试地址
export var base = testUrl;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
//添加请求拦截器
axios.interceptors.request.use(function (config) {
    // let userToken =  sessionStorage.getItem('userToken');
    // if(userToken){
    //     if(config.data){
    //         config.data = /userToken/g.test(config.data)?config.data:config.data+'&userToken='+userToken
    //     }else if(config.params){ 
    //         config.params['userToken'] = userToken;  
    //     }
    var token1 = ''
    if (window.location.search) {
        var str = window.location.search.substr(1);
        token1 = str.split("=")[1];
    }
    var token = localStorage.getItem('token') || token1 || '';
    if (config.data) {
        config.data = /token/g.test(config.data) ? config.data : config.data + '&token=' + token;
    } else if (config.params) {
        config.params['token'] = token;
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});