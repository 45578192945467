<template>
    <div class="approval-wrap maintenance-approval-wrap">
        <div class="apply-info">
            <div class="info-title">
                <span class="line-icon"></span>
                申请信息
            </div>
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">申请人：<span>{{detailData.driveName}}</span></div>
                    <div class="fl right">车牌号：<span>{{detailData.carNo}}</span></div>
                </div>
                <div class="cnt-item">
                    <div class="left" style="width:100%">申请类型：<span>{{detailData.repaireTypeName}}</span></div>
                   <!--  <div class="fl right">紧急程度：<span>一般</span></div> -->
                </div>
                <div class="cnt-item tool-item">
                    <div class="">主要部件：<span>{{detailData.repaireParts?detailData.repaireParts.join('，'):''}}</span></div>
                </div>
                <div class="cnt-item clear time-box">
                    <div class="fl left">期望修理时间：</div><div class="fl right">{{detailData.repaireDate|dateFormat1}}</div>
                </div>
                 <div class="cnt-item clear detail-box">
                    <div class="fl left">申请说明：</div><div class="fl right">{{detailData.applyContent}}</div>
                </div>
                <div class="img-box ui-img-wrap">
                    <ul class="img-ul clear">
                        <li class="fl" v-for="(list,index) in (applyImgs?applyImgs:[])" :key="index">
                            <div class='img'>
                                <img class='jMaxImg big-img'  :src='list' @click="handleImg(index)">
                            </div>
                         </li>
                    </ul>
                </div>
            </div>  
        </div>
        <div class="advice-info">
              <div class="info-title">
                <span class="line-icon"></span>
                审批意见
            </div>
            <form>
                <div class="form-box textarea-box">
                    <label><span class="label-star top-star">*</span><span>审批意见：</span></label>
                    <textarea name="" id="" rows="5"  placeholder="请输入内容" v-model="addForm.applyMsg"></textarea>
                </div>
                <div class="form-box clear">
                    <label class="fl"><span class="label-star">*</span>车辆修理：</label>
                    <div class="fr right radio-box">
                        <input id="noUrgentRepaire" class="radio" name="repaire" v-model="addForm.repairCompanyType" type="radio" value="1"/>
                        <label for="noUrgentRepaire">定点维修</label>
                        <input id="urgentRepaire" class="radio" name="repaire" v-model="addForm.repairCompanyType" type="radio" value="2"/>
                        <label for="urgentRepaire">应急维修</label>
                    </div>
                </div>
                <div class="form-box clear">
                    <label class="fl"><span class="label-star">*</span>维修厂：</label>
                    <input class="fr" v-show="addForm.repairCompanyType=='2'" type="text" placeholder="请输入维修公司" v-model="repaireCompany2">
                    <div class="fr right-select" v-show="addForm.repairCompanyType=='1'" :class="repaireCompany?'active':''" style="cursor:pointer;" @click="toComShow">{{repaireCompany?repaireCompany:'请选择>'}}</div>
                    <!-- <div class="fr right-select" style="cursor:pointer;" @click="toShow">{{typeDefaultData.length>0?typeDefaultData[0].text:'请选择>'}}</div> -->
                </div>
                <div class="form-box clear">
                    <label class="fl">维修地址：</label>
                    <input class="fr" v-show="addForm.repairCompanyType=='1'" type="text"  v-model="repaireAddress" readonly>
                    <input class="fr" v-show="addForm.repairCompanyType=='2'" type="text" placeholder="请输入地址" v-model="repaireAddress2">
                </div>
                <div class="form-box clear">
                    <label class="fl">联系人员：</label>
                    <input class="fr" v-show="addForm.repairCompanyType=='1'" readonly type="text"  v-model="repaireCompanyMan">
                    <input class="fr"  v-show="addForm.repairCompanyType=='2'" type="text" placeholder="请输入姓名" v-model="repaireCompanyMan2">
                </div>
                 <div class="form-box clear">
                    <label class="fl">联系电话：</label>
                    <input class="fr" v-show="addForm.repairCompanyType=='1'" readonly type="number"  v-model="repairePhone" maxlength="11" oninput="if(value.length>11)value=value.slice(0,11)">
                    <input class="fr" v-show="addForm.repairCompanyType=='2'" type="number" placeholder="请输入电话号码" v-model="repairePhone2" maxlength="11" oninput="if(value.length>11)value=value.slice(0,11)">
                </div>
                 <div class="form-box clear">
                    <label class="fl"><span class="label-star">*</span>修理时间：</label>
                    <div class="fr right-select" :class="addForm.repaireDate?'active':''" @click="toShowBeginTime">{{addForm.repaireDate|dateFormat}}</div>
                </div>
                <!-- <div class="form-box clear">
                    <label class="fl">至：</label>
                     <div class="fr right-select" style="cursor:pointer;" @click="toShowEndTime">{{endTime?endTime:'请选择>'}}</div>
                </div> -->
            </form>
        </div>
        <cover-img ref="applyImg" :imgArr="applyImgs"></cover-img>
        <div class="ui-button clear" :style="showStatic?staticStyle:fixStyle"><div class="btn btn1 fl"  @click="handleAgree('-2')">不同意</div><div class="btn fl" @click="handleAgree('2')">同意</div></div>
        <div class="cloak-wrap" v-show="companyListView">
            <div class="company-box">
                <div class="tool-box clear">
                    <span class="fl left" @click="handleCancel">取消</span>
                    <span class="fr right" @click="handleCompanyCert">确定</span>
                </div>
                <ul class="company-ul">
                    <li v-for="(list,index) in companyList" :key="index" @click="handleLi(index,list)">
                        <div class="left">
                            <div>{{list.companyName}}</div>
                            <div>{{list.detailsAddress}}</div>
                        </div>
                        <div class="right" v-show="index==activeIndex">
                            √
                        </div>
                        
                    </li>
                </ul>
            </div>
        </div>
        <mt-datetime-picker
            ref="beginPicker"
            type="datetime"
            year-format="{value}"
            month-format="{value}"
            date-format="{value}"
            hour-format="{value}"
            minute-format="{value}"
            v-model="pickerValue"
            @confirm="handleBeginTime">
        </mt-datetime-picker>
       <!--  <awesome-picker
            ref="comPicker"
            :colorConfirm="picker.colorConfirm"
            :data="repaireComList"
            @confirm="repaireConfirmFn">
        </awesome-picker> -->
    </div>  
</template>
<script>
	import adjust from '../../common/js/adjust';
    import utils from '../../common/js/utils';
    import coverImg from '../../components/coverImg'
    import {formatDate} from '../../common/js/dateformat'
    import {getRepaireDetail,getRepaireApprove,getRepaireCompany} from '../../api/maintenance'
    


    export default {
        components: {
            coverImg
        },
        data() {
            return {
                pickerValue:new Date(),
                repaireId:'',
                detailData:{},
                activeIndex:10000,
                addForm:{
                    status:'',
                    applyMsg:'',
                    repaireId:'',
                    repaireCompany:'',
					repaireCompanyUid:0,
                    repaireDate:'',
                    repaireAddress:'',
                    repaireCompanyMan:'',
                    repairePhone:'',
                    repairCompanyType:'1'
                },
                picker:{
                    colorConfirm:'rgb(30,135,242)',
                },
                repaireCompany:'',
				repaireCompanyUid:0,
                repaireCompanyMan:'',
                repaireAddress:'',
                repairePhone:'',
                repaireCompany2:'',
                repaireCompanyMan2:'',
                repaireAddress2:'',
                repairePhone2:'',
                applyImgs:[],
                repaireComList:[],
                companyList:[],
                companyObj:null,
                companyListView:false,
                type:'',
                winHeight: document.body.clientHeight,
                showStatic:false,
                fixStyle:'position:fixed',
                staticStyle:"position:static",
                isApproval:false,
                iosVaild : utils.versions.ios,
                androidVaild : utils.versions.android,
                btnClick:true,
            }
        },
        filters: {
            dateFormat(time) {
                if(time&&time!='请选择>'){
                    // var date = new Date(time);
                    // return formatDate(date,'yyyy-MM-dd hh:mm');
                    return time;
                }else{
                    return '请选择>'
                }
            },dateFormat1(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd hh:mm');
                }
            },
        },
        methods: {
            handleCancel(){
                this.companyListView = false;
                this.activeIndex = 10000;
                this.companyObj = null;
            },
            handleCompanyCert(){
                if(this.companyObj){
                    this.repaireCompany = this.companyObj.companyName;
                    this.repaireAddress = this.companyObj.detailsAddress;
                    this.repaireCompanyMan = this.companyObj.contactPerson;
                    this.repairePhone = this.companyObj.contactPhone;
                    this.companyListView = false;
					this.repaireCompanyUid = this.companyObj.companyUid;
                }else{
                    this.$toast('请选择维修公司');
                    return false;
                }
                
            },
            handleLi(index,list){
                this.activeIndex = index;
                this.companyObj = list;
            },
            toComShow(){
                //this.$refs.comPicker.show();
                this.companyListView = true;
            },
            // repaireConfirmFn(val){
            //     if(val){
            //         this.repaireCompany = val[0].value||'';
            //         var obj = this.companyList[val[0].index];
            //         this.repaireAddress = obj.detailsAddress;
            //         this.repaireCompanyMan = obj.contactPerson;
            //         this.repairePhone = obj.contactPhone;
            //     }
            // },
            //定点维修，紧急维修
            handleRepaire(){
                console.log(this.addForm.repairCompanyType)
            },
            //图片放大
            handleImg(index){
                this.$refs.applyImg.viewImgSwiper = true;
                if(this.$refs.applyImg.swiperImgBox){
                    this.$refs.applyImg.swiperImgBox.slideTo(index, 50, false);
                }
            },
            toShowBeginTime(){
                this.$refs.beginPicker.open();
            },
            handleBeginTime(val){
                this.addForm.repaireDate = formatDate(val,'yyyy-MM-dd hh:mm:ss');
            },
            getDetailFun(){
                let para = {
                    repaireId:this.repaireId
                }
                getRepaireDetail(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.detailData = data;
                            if(this.detailData.applyImages){
                                this.applyImgs = this.detailData.applyImages.split(',');
                            }
                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            //维修厂列表
            getRepaireCompanyFun(){
                var _this = this;
                let para = {}
                getRepaireCompany(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            _this.companyList = data||[];
                            // if(data.length>0){
                            //     data.forEach((v,i)=>{
                            //         let list = {};
                            //         list["code"]=v.companyUid;
                            //         list["value"]=v.companyName;
                            //         _this.repaireComList.push(list);

                            //     })
                            // }
                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            handleAgree(status){
                var _this = this;
                if(status=='-2'){//不同意
                    if(!this.addForm.applyMsg){
                        this.$toast('请输入审批意见');
                        return false;
                    }else{
                        let para = Object.assign({}, this.addForm);
                        para.status = status;
                        para.repaireId = this.repaireId;
                        if(_this.btnClick){
                            _this.btnClick = false;
                            getRepaireApprove(para).then(res => {
                                if(res&&res.resCode==0){
                                    if(res.data&&res.data.errcode==0){
                                        this.isApproval = true;
                                        this.$toast('审批成功');
                                        setTimeout(function(){
                                            if(_this.type==1){
                                                _this.$router.push({path: '/maintenance/list'});
                                            }else if(_this.type==2){
                                                _this.$router.push({path: '/maintenance/myList'});
                                            }
                                        },2000)
                                    }else{
                                        this.$toast(res.errmsg||'网络错误，请重试！');
                                        _this.btnClick = true;
                                    }
                                }else{
                                    this.$toast(res.resMsg||'网络错误，请重试！');
                                    _this.btnClick = true;
                                }
                            
                            }).catch(res => {
                                _this.btnClick = true;
                            });
                        }
                    }
                }else{
                    this.addForm.repaireCompany = this.addForm.repairCompanyType=='1'?this.repaireCompany:this.repaireCompany2;
					this.addForm.repaireCompanyUid = this.repaireCompanyUid;					
                    this.addForm.repaireCompanyMan = this.addForm.repairCompanyType=='1'?this.repaireCompanyMan:this.repaireCompanyMan2;
                    this.addForm.repaireAddress = this.addForm.repairCompanyType=='1'?this.repaireAddress:this.repaireAddress2;
                    this.addForm.repairePhone = this.addForm.repairCompanyType=='1'?this.repairePhone:this.repairePhone2;
                    this.checkForm(()=>{
                        let para = Object.assign({}, this.addForm);
                        para.status = status;
                        para.repaireId = this.repaireId;
                        if(_this.btnClick){
                            _this.btnClick = false;
                            getRepaireApprove(para).then(res => {
                                if(res&&res.resCode==0){
                                    if(res.data&&res.data.errcode==0){
                                        this.isApproval = true;
                                        this.$toast('审批成功');
                                        setTimeout(function(){
                                            if(_this.type==1){
                                                _this.$router.push({path: '/maintenance/list'});
                                            }else if(_this.type==2){
                                                _this.$router.push({path: '/maintenance/myList'});
                                            }
                                        },2000)
                                    }else{
                                        this.$toast(res.errmsg||'网络错误，请重试！');
                                        _this.btnClick = true;
                                    }
                                }else{
                                    this.$toast(res.resMsg||'网络错误，请重试！');
                                    _this.btnClick = true;
                                }
                            
                            }).catch(res => {
                                _this.btnClick = true;
                            });
                        }
                        
                    })
                }
                

            },
            checkForm(callback){
                if(!this.addForm.applyMsg){
                    this.$toast('请输入审批意见');
                    return false;
                }else if(!this.addForm.repaireCompany){
                    if(this.addForm.repairCompanyType==1){
                        this.$toast('请选择维修公司');
                    }else{
                        this.$toast('请输入维修公司');
                    }

                    return false;
                }else if(!this.addForm.repaireDate){
                    this.$toast('请选择维修时间');
                    return false;
                }else{
                    callback&&callback();
                } 

            }
        },

        mounted() {
            var _this = this;
            this.type = this.$route.params.type||'';
            this.repaireId = this.$route.params.repaireId||'';
            this.getRepaireCompanyFun();
            adjust.getTokenInfo(function(){
                _this.getDetailFun();
            });
            window.onresize = () => {
                if(_this.androidVaild){
                    var curHeight = document.body.clientHeight;
                    if(curHeight<_this.winHeight){
                        _this.showStatic = true;
                    }else{
                        _this.showStatic = false;
                    }
               }
            }
        },
        beforeRouteLeave(to, from, next) {
            if(this.isApproval){
                to.meta.isUpdate = true;  
            }else{
                to.meta.isUpdate = false; 
                
            }
           
            next();
        }
    }
</script>
<style  lang="scss">
    @import "../../styles/common";
    .approval-wrap{
        background-color:#F5F4F3;
        width:100%;
        height:100%;
        position:fixed;
        left:0;
        top:0;
        padding-bottom:1rem;
        @include box-sizing(border-box);
        overflow:auto;
        .apply-info{
            padding:0.32rem;
            @include box-sizing(border-box);
            background-color:#fff;
            margin-bottom:0.16rem;
            .cnt{
                font-size:0.3rem;
                color:rgb(51,51,51);
                .left,.right{
                    width:50%;
                }
                .cnt-item{
                    line-height:0.6rem;
                }
                .tool-item{
                    span{
                        margin-right:0.1rem;
                    }
                }
                .time-box,.detail-box,.radio-box{
                    .left{
                        width:32%;
                    }
                    .right{
                        width:68%;
                    }
                }
                .detail-box{
                    .left{
                        width:25%;
                    }
                    .right{
                        width:75%;
                    }
                }

            }

        }
        .advice-info{
            padding:0.32rem 0.32rem 0rem;
            @include box-sizing(border-box);
            background-color:#fff;
            margin-bottom:0.1rem;
        }

        .form-box{
            border-bottom:1px solid #EEEBE9;
            background-color:#fff;
            height:1rem;
            line-height:0.95rem;
            padding:0 0.30rem;
            label{
                width:30%;
                font-size:0.28rem;
                color:rgb(51,51,51);
            }
            input,.right-select,.right{
                width:70%;
                border: 0 none;
                line-height:0.95rem;
                height:0.95rem;
                text-align:right;
                font-size:0.28rem;
                &.active{
                    color:rgb(51,51,51);
                }
            }
            .right-select{
                color:rgb(153,153,153);
            }
        }
        .textarea-box{
            height:auto;
            margin-bottom:0.1rem;
            textarea{
                display:block;
                border:0 none;
                width:100%;
                font-size:0.28rem;
            }
        }
        
    }
    .radio-box {
        text-align: center;
        overflow:hidden;
        //width: 100%;
        input[type="radio"],input[type="checkbox"] {
            position: absolute;
            left: -6.8rem;
            color: transparent;

        }
        label {
            display: inline-block;
            color: #5c5c6b!important;
            position: relative;
            margin-left: 0.6rem;
            width:auto!important;
        }
        label::before, label::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -0.36rem;
            margin: auto;
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;
        }
        label::before {
            background-color: #d8d7dc;
        }
        label::after {
            background: rgb(30,135,242);
            opacity: .2;
            @include transform(scale(0));
            -webkit-transition: .3s;
            -o-transition: .3s;
            transition: .3s;
        }
        input:checked + label::after {
            @include transform(scale(0.7));
            opacity: 1;
        }
    }
    .area_ctrl{
        background-color:#fff!important;
        .area_btn_box{
            background-color:#fff!important;
            &::before,&::after{
                background-color:#fff!important;
            }
        }
    }
    .ui-button{
        width:100%;
        height:1rem;
        background-color:rgb(251,55,91);
        text-align:center;
        line-height:1rem;
        font-size:0.32rem;
        color:#fff;
        .btn{
            width:50%;
            &.btn1{
                background-color:rgb(30,135,242)
            }
        }
    }
    .maintenance-approval-wrap{
        .cloak-wrap{
            width:100%;
            height:100%;
            background-color:rgba(0,0,0,.2);
            position:fixed;
            left:0;
            top:0;
            z-index:9;
            .company-box{
                width:100%;
                height:8rem;
                position:absolute;
                left:0;
                bottom:0;
                background-color:#fff;
                @include box-sizing(border-box);
                .tool-box{
                    height:1rem;
                    line-height:1rem;
                    padding:0 0.6rem;
                    @include box-sizing(border-box);
                    border-bottom:0.01rem solid #ccc;
                    font-size:0.32rem;
                    .left,.right{
                        cursor:pointer;
                        &.right{
                            color:rgb(30,135,242);
                        }
                        &.left{
                            color:#ccc;
                        }
                    }
                }
                .company-ul{
                    width:100%;
                    height:7rem;
                    padding: 0 0.6rem;
                    @include box-sizing(border-box);
                    overflow:auto;
                    li{
                        padding:0.2rem 0;
                        border-bottom:0.01rem solid #ccc;
                        @include box-sizing(border-box);
                        position:relative;
                        cursor:pointer;
                        font-size:0.30rem;
                        .left{
                            line-height:0.46rem;
                            width:80%;
                        }
                        .right{
                            position:absolute;
                            top:50%;
                            @include transform(translateY(-50%));
                            right:0.1rem;
                            color:rgb(30,135,242);
                        }
                    }
                }
            }
        }
    }
    
    
</style>



