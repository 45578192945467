import axios from 'axios'
import { base } from './api'
import Qs from 'qs'


//待审批
export var getMyLeave = params => { return axios.get(`${base}/h5/leave/myLeave/` + params.pageNo, { params: {} }).then(res => res.data); };

//待审批
export var getApproveLeave = params => { return axios.get(`${base}/h5/leave/approveLeave/` + params.pageNo, { params: {} }).then(res => res.data); };

//已审批
export var getApprovedLeave = params => { return axios.get(`${base}/h5/leave/approvedLeave/` + params.pageNo, { params: {} }).then(res => res.data); };

//申请
export var getLeaveApply = params => { return axios.post(`${base}/h5/leave/apply`, Qs.stringify(params)).then(res => res.data); };

//详情
export var getLeaveDetail = params => { return axios.get(`${base}/h5/leave/detail/` + params.leaveId + `?approve=` + (params.approve || 0), { params: {} }).then(res => res.data); };

//撤销请假
export var getLeaveRevoke = params => { return axios.get(`${base}/h5/leave/revoke/` + params.leaveId, { params: {} }).then(res => res.data); };

//审批
export var getLeaveApprove = params => { return axios.post(`${base}/h5/leave/approve`, Qs.stringify(params)).then(res => res.data) };

//开始休假
export var getStartLeave = params => { return axios.get(`${base}/h5/leave/start/` + params.leaveId, { params: {} }).then(res => res.data); };

//结束休假
export var getEndLeave = params => { return axios.get(`${base}/h5/leave/end/` + params.leaveId, { params: {} }).then(res => res.data); };

//某段时间内休假人员列表,用于请假申请界面
export var getApprovePersons = params => { return axios.get(`${base}/h5/leave/getApprovePersons/${params.startDate}/${params.endDate}`, { params: {} }).then(res => res.data); };

//查询某个月，请假当月的已审批记录
export var getApproveSinglePersons = params => { return axios.get(`${base}/h5/leave/getApproveSinglePersons/${params.leaveId}`, { params: {} }).then(res => res.data); };