<template>
    <div class="approval-wrap oil-detail-wrap">
        <div class="apply-info send-info">
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">司机姓名：</div>
                    <div class="fl right">{{memberName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">车牌号码：</div>
                    <div class="fl right">{{detailData.carNo}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">付款方式：</div>
                    <div class="fl right">{{detailData.payMethod}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">加油金额：</div>
                    <div class="fl right">{{detailData.oilMoney}}元</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">加油量：</div>
                    <div class="fl right">{{detailData.oilMass}}L</div>
                </div>
                <div class="cnt-item clear">
                    <div class="fl left">加油时间：</div><div class="fl right">{{detailData.createDate|dateFormat}}</div>
                </div>
            </div>  
        </div>
        <div class="ui-button"  @click="handleDel" v-if="detailData.canRevoke">撤销</div>
    </div>  
</template>
<script>
    import {getOilDetail,getDeleteOil} from '../../api/oil'
    import {getMemberInfo} from '../../api/common'
    import {formatDate} from '../../common/js/dateformat'
    


    export default {
        components: {
            
        },
        data() {
            return {
                oilId:'',
                detailData:{},
                memberName:'',
                isDel:false,
                btnClick:true,
            }
        },
        filters: {
            dateFormat(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd hh:mm');
                }
               
            }
        },
        methods: {
            //获取到自己的信息
            getMyInfo(){
                getMemberInfo({}).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.memberName = data.memberName||'';

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            getDetailFun(){
                let para = {
                    oilId:this.oilId
                }
                getOilDetail(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.detailData = data;

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            //撤销
            handleDel(){
                var _this = this;
                let para = {
                    oilId:this.oilId
                }
                if(_this.btnClick){
                    _this.btnClick = false;
                    getDeleteOil(para).then(res => {
                        if(res&&res.resCode==0){
                            if(res.data&&res.data.errcode==0){
                                this.isDel = true;
                                this.$toast('撤销成功！');
                                setTimeout(function(){
                                    _this.$router.push({path: '/oil/list'});
                                },2000)
                            }else{
                                this.$toast(res.data.errmsg||'网络错误，请重试！');
                                _this.btnClick = true;
                            }
                        }else{
                            this.$toast(res.resMsg||'网络错误，请重试！');
                            _this.btnClick = true;
                        }
                        
                    }).catch(res => {
                        _this.btnClick = true;
                    });
                }
                
            },

        },

        mounted() {
            this.oilId = this.$route.params.oilId||'';
            this.getMyInfo();
            this.getDetailFun();
        },
        beforeRouteLeave(to, from, next) {
            if(this.isDel){
                to.meta.isUpdate = true;  
            }else{
                to.meta.isUpdate = false;   
            }
            next();
        }
    }
</script>
<style  lang="scss">
    @import "../../styles/common";
    .oil-detail-wrap{
        .cnt{
            .img-ul{
                li{
                    width:22%;
                    margin-right:4%;
                    margin-bottom:0.1rem;
                    cursor:pointer;
                    position:relative;
                    &:nth-of-type(4n){
                        margin-right:0;
                    }
                    &::before {
                        content: "";
                        padding-top: 100%;
                        display: block;
                    }
                    img{
                        position:absolute;
                        left:0;
                        top:0;
                        width:100%;
                        height:100%;
                    }
                }
            }
        }
    }
    .info-title{
        position:relative;
        line-height:0.4rem;
        padding-left:0.18rem;
        font-size:0.32rem;
        color:rgb(51,51,51);
        margin-bottom:0.32rem;
        .line-icon{
            position:absolute;
            left:0rem;
            top:0rem;
            width:0.08rem;
            height:0.4rem;
            background-color:rgb(38,41,56);
            @include border-radius(0.2rem);
        }
    }
    .approval-wrap{
        background-color:#F5F4F3;
        width:100%;
        .apply-info{
            padding:0.32rem;
            @include box-sizing(border-box);
            background-color:#fff;
            margin-bottom:0.16rem;
            .cnt{
                font-size:0.3rem;
                color:rgb(51,51,51);
                .left,.right{
                    width:50%;
                }
                .cnt-item{
                    line-height:0.6rem;
                }
                .time-box,.detail-box{
                    .left{
                        width:32%;
                    }
                    .right{
                        width:68%;
                    }
                }
                .detail-box{
                    .left{
                        width:25%;
                    }
                    .right{
                        width:75%;
                    }
                }

            }

        }
        .send-info{
            .cnt{
                .left{
                    width:25%;
                }
                .right{
                    width:75%;
                }
            }

        }
        .send-info{
            padding:0.32rem;
            @include box-sizing(border-box);
            background-color:#fff;
        }

        .form-box{
            border-bottom:1px solid #EEEBE9;
            background-color:#fff;
            height:1rem;
            line-height:0.95rem;
            padding:0 0.30rem;
            label{
                width:30%;
                font-size:0.28rem;
                color:rgb(51,51,51);
            }
            input,.right-select{
                width:70%;
                border: 0 none;
                line-height:0.95rem;
                height:0.95rem;
                text-align:right;
                font-size:0.28rem;
                &.active{
                    color:rgb(51,51,51);
                }
            }
            .right-select{
                color:rgb(153,153,153);
            }
        }
        .textarea-box{
            height:auto;
            margin-bottom:0.1rem;
            textarea{
                display:block;
                border:0 none;
                width:100%;
            }
        }
        
    }
    .area_ctrl{
        background-color:#fff!important;
        .area_btn_box{
            background-color:#fff!important;
            &::before,&::after{
                background-color:#fff!important;
            }
        }
    }
    .ui-button{
        width:100%;
        height:1rem;
        background-color:rgb(251,55,91);
        text-align:center;
        line-height:1rem;
        font-size:0.32rem;
        color:#fff;
        cursor:pointer;
        .btn{
            width:50%;
            &.btn1{
                background-color:rgb(30,135,242)
            }
        }
    }
    
</style>



