<template>
    <div class="cover-mod jCoverImg" v-show="viewImgSwiper" @click="closeImg">
        <ul class="items swiper-wrapper">
            <li class="swiper-slide" v-for="(list,index) in imgArr" :key="index"><img class="small":src="list"></li>
        </ul>
        <div class="swiper-pagination"></div>
    </div>
</template>
<script>
    import Swiper from 'swiper'
    import 'swiper/dist/css/swiper.min.css';
    export default {
        
        components: {
            
        },
        data() {
            return {
                viewImgSwiper:false,
                swiperImgBox:null,
            }
        },
        props: {
            imgArr: Array 
        },
        methods: {
            // handleDelImg(index){
            //     this.imgArr.splice(index,1);
            // },
            //图片放大
            // handleImg(index){
            //     this.viewImgSwiper = true;
            //     if(this.swiperImgBox){
            //         this.swiperImgBox.slideTo(index, 50, false);
            //     }
            // },
            //关闭图片轮播
            closeImg(){
                this.viewImgSwiper = false;
            }
           
        },

        mounted() {
            this.swiperImgBox = new Swiper('.jCoverImg',{
                resistanceRatio : 0,
                pagination: {el:'.swiper-pagination'},
                paginationClickable: true,
                observer:true , //修改swiper自己或子元素时，自动初始化swiper
                observeParents:true //修改swiper的父元素时，自动初始化swiper
            });

        }
    }
</script>
<style lang="scss">
    @import "../styles/coverImg";
    
</style>