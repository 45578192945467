<template>
    <div class="apply-wrap accident-add-wrap">
        <form>
            <div class="add-box">
                <div class="title-wrap">
                    <div class="info-title">
                        <span class="line-icon"></span>
                        事故信息
                    </div>
                </div>
                <div class="form-box clear">
                    <label class="fl">申报人员：</label>
                    <div class="fr right">{{memberName}}</div>
                </div>
                <div class="form-box clear">
                    <label class="fl">车牌号码：</label>
                    <div class="fr right">{{carNo}}</div>
                </div>
                <div class="form-box clear">
                    <label class="fl"><span class="label-star">*</span>事故类型：</label>
                    <div class="fr right-select" :class="accidentTypeName?'active':''" style="cursor:pointer;" @click="toTypeShow">{{accidentTypeName?accidentTypeName:'请选择>'}}</div>
                </div>
                <div class="form-box clear">
                    <label class="fl"><span class="label-star">*</span>事故时间：</label>
                     <div class="fr right-select" :class="addForm.accidentTime?'active':''" style="cursor:pointer;" @click="toAccidentTime">{{addForm.accidentTime|dateFormat}}</div>
                </div>
                <div class="form-box clear">
                    <label class="fl">通知领导：</label>
                    <span class="fr right">{{managerName}}</span>
                </div>
                <div class="form-box clear">
                    <label class="fl">抄送人：</label>
                    <span class="fr right">{{noticeMan}}</span>
                </div>
                <div class="form-box clear">
                    <label class="fl"><span class="label-star">*</span>事故地点：</label>
                    <div class="fr right-select" :class="proAddress?'active':''" style="cursor:pointer;" @click="toAddressShow">{{proAddress?proAddress:'请选择>'}}</div>
                </div>
                <div class="form-box clear">
                    <label class="fl"><span class="label-star">*</span>详细地址：</label>
                    <input class="fr" type="text" placeholder="请输入街道地址" v-model="addForm.detailAddress">
                </div>
                <div class="form-box textarea-box">
                    <label>事故描述：</label>
                    <textarea name="" id="" rows="5"  placeholder="请输入事故描述" v-model="addForm.describe"></textarea>
                </div>
                <div class="ui-img-wrap imgWrap">
                    <p class="title jImgTile">事故照片（最多上传6张照片）</p>
                    <ul class="img-ul clear">       
                        <li class='fl' v-for="(list,index) in imgArr4" :key="index">
                            <div class='img'>
                                <img class='jMaxImg big-img'  :src='list' @click="handleImg(index,4)">
                                <img class="icon-del jIconDel" src="../../common/images/icon_del.png" alt="" @click="handleDelImg4(index)">
                               <!-- <i class='icon-del jIconDel'></i> -->
                            </div>  
                        </li>                                                    
                        <li class="fl last jLast" @click="handleUpdateImg4" v-show="imgArr4.length<6">
                            <div class="img">
                                <i class="line line1"></i>
                                <i class="line line2"></i>
                            </div>
                        </li>
                    
                    </ul>
                    <div class="swiperImg"></div>
                </div>  
            </div>
            <!-- <div class="add-box">
                <div class="title-wrap">
                    <div class="info-title">
                        <span class="line-icon"></span>
                        第三方信息
                    </div>
                </div>
                <div class="form-box clear">
                    <label class="fl">车牌号码：</label>
                    <input class="fr" type="text" placeholder="请输入车牌号码" v-model="addForm.thirdCarNo">
                </div>
                <div class="form-box clear">
                    <label class="fl">车架号码：</label>
                    <input class="fr" type="text" placeholder="请输入车架号码" v-model="addForm.thirdCarVin">
                </div>
                <div class="form-box clear">
                    <label class="fl">司机姓名：</label>
                    <input class="fr" type="text" placeholder="请输入司机姓名" v-model="addForm.thirdDriver">
                </div>
                <div class="form-box clear">
                    <label class="fl">司机电话：</label>
                    <input class="fr" type="number" placeholder="请输入司机电话" v-model="addForm.thirdDriverPhone" oninput="if(value.length>11)value=value.slice(0,11)">
                </div>   
            </div> -->
           <!-- <div class="add-box">
                <div class="title-wrap">
                    <div class="info-title">
                        <span class="line-icon"></span>
                        事故评估
                    </div>
                </div>
                <div class="form-box clear">
                    <label class="fl">责任划分：</label>
                    <input class="fr" type="text" placeholder="请输入责任划分" v-model="addForm.blame">
                </div>
                <div class="form-box clear">
                    <label class="fl">事故损失(元)：</label>
                    <input class="fr" type="number" placeholder="请输入事故损失" v-model="addForm.accidentLose">
                </div>
                <div class="ui-img-wrap imgWrap">
                    <p class="title jImgTile">认定报告（最多上传6张照片）</p>
                    <ul class="img-ul clear">       
                        <li class='fl' v-for="(list,index) in imgArr1">
                            <div class='img'>
                                <img class='jMaxImg big-img'  :src='list' @click="handleImg(index,1)">
                                <img class="icon-del jIconDel" src="../../common/images/icon_del.png" alt="" @click="handleDelImg1(index)">
                               //<i class='icon-del jIconDel'></i>
                            </div>  
                        </li>                                                    
                        <li class="fl last jLast" @click="handleUpdateImg1" v-show="imgArr1.length<6">
                            <div class="img">
                                <i class="line line1"></i>
                                <i class="line line2"></i>
                            </div>
                        </li>
                    
                    </ul>
                    <div class="swiperImg"></div>
                </div>   
            </div> -->
            <div class="add-box">
                 <div class="title-wrap">
                    <div class="info-title">
                        <span class="line-icon"></span>
                        人员伤亡情况
                    </div>
                </div>
                <div class="form-box clear">
                    <label class="fl">人员伤亡：</label>
                    <div class="fr right radio-box">
                        <input id="no-hurt" class="radio" name="safe" v-model="addForm.isCasualtie" type="radio" value="0"/>
                        <label for="no-hurt">无人员伤亡</label>
                        <input id="have-hurt" class="radio" name="safe" v-model="addForm.isCasualtie" type="radio" value="1"/>
                        <label for="have-hurt">有人员伤亡</label>
                    </div>
                </div>
                <div class="form-box textarea-box">
                    <label >伤亡情况：</label>
                    <textarea name="" id="" rows="5"  placeholder="请输入伤亡情况" v-model="addForm.casualtieDesc"></textarea>
                </div>
                <div class="form-box clear">
                    <label class="fl">送往医院：</label>
                    <input class="fr" type="text" placeholder="请输入送往医院" v-model="addForm.serviceHospital">
                </div>
                <!-- <div class="form-box clear">
                    <label class="fl">医疗费用(元)：</label>
                    <input class="fr" type="number" placeholder="请输入医疗费用" v-model="addForm.medicalExpense">
                </div> -->
                 
            </div>
            <!-- <div class="add-box">
                <div class="title-wrap">
                    <div class="info-title">
                        <span class="line-icon"></span>
                        保险公司评估
                    </div>
                </div>
                <div class="form-box clear">
                    <label class="fl">保险定损(元)：</label>
                    <input class="fr" type="number" placeholder="请输入保险定损" v-model="addForm.fixLose">
                </div>
                <div class="form-box clear">
                    <label class="fl">保险理赔：</label>
                    <input class="fr" type="number" placeholder="请输入保险理赔" v-model="addForm.claimMoney">
                </div>
                <div class="ui-img-wrap imgWrap">
                    <p class="title jImgTile">勘测报告（最多上传6张照片）</p>
                    <ul class="img-ul clear">       
                        <li class='fl' v-for="(list,index) in imgArr2">
                            <div class='img'>
                                <img class='jMaxImg big-img'  :src='list' @click="handleImg(index,2)">
                                <img class="icon-del jIconDel" src="../../common/images/icon_del.png" alt="" @click="handleDelImg2(index)">
                               //<i class='icon-del jIconDel'></i>
                            </div>  
                        </li>                                                    
                        <li class="fl last jLast" @click="handleUpdateImg2" v-show="imgArr2.length<6">
                            <div class="img">
                                <i class="line line1"></i>
                                <i class="line line2"></i>
                            </div>
                        </li>
                    
                    </ul>
                    <div class="swiperImg"></div>
                </div>    
            </div> -->
            <!-- <div class="add-box">
                <div class="title-wrap">
                    <div class="info-title">
                        <span class="line-icon"></span>
                        车辆维修评估
                    </div>
                </div>
                <div class="form-box clear">
                    <label class="fl">车辆修理：</label>
                    <div class="fr right radio-box">
                        <input id="need-repaire" class="radio" name="repaire" v-model="addForm.needRepaire" type="radio" value="1"/>
                        <label for="need-repaire">需要修理</label>
                        <input id="no-repaire" class="radio" name="repaire" v-model="addForm.needRepaire" type="radio" value="0"/>
                        <label for="no-repaire">不需要修理</label>
                    </div>
                </div>
                <div class="form-box clear">
                    <label class="fl">修理厂商：</label>
                    <input class="fr" type="text" placeholder="请输入修理厂商" v-model="addForm.repaireCompany">
                </div>
                <div class="form-box clear">
                    <label class="fl">维修费用(元)：</label>
                    <input class="fr" type="number" placeholder="请输入维修费用" v-model="addForm.repaireMoney">
                </div>
                <div class="ui-img-wrap imgWrap">
                    <p class="title jImgTile">维修发票（最多上传6张照片）</p>
                    <ul class="img-ul clear">       
                        <li class='fl' v-for="(list,index) in imgArr3">
                            <div class='img'>
                                <img class='jMaxImg big-img'  :src='list' @click="handleImg(index,3)">
                                <img class="icon-del jIconDel" src="../../common/images/icon_del.png" alt="" @click="handleDelImg3(index)">
                               //<i class='icon-del jIconDel'></i>
                            </div>  
                        </li>                                                    
                        <li class="fl last jLast" @click="handleUpdateImg3" v-show="imgArr3.length<6">
                            <div class="img">
                                <i class="line line1"></i>
                                <i class="line line2"></i>
                            </div>
                        </li>
                    
                    </ul>
                    <div class="swiperImg"></div>
                </div>   
            </div> -->
           
        </form>
        <cover-img ref="applyImg" :imgArr="imgList"></cover-img>
        <div class="ui-button" :style="showStatic?staticStyle:fixStyle"  @click="handleApply">保存</div>
        <!-- 事故类型 -->
        <awesome-picker
            ref="picker"
            :colorConfirm="picker.colorConfirm"
            :data="typeList"
            @confirm="typeConfirmFn">
        </awesome-picker>
        <!-- 事故时间 -->
        <mt-datetime-picker
            ref="accidentTimePicker"
            type="datetime"
            year-format="{value}"
            month-format="{value}"
            date-format="{value}"
            hour-format="{value}"
            minute-format="{value}"
            v-model="pickerValue"
            @confirm="handleaccidentTime">
        </mt-datetime-picker>
        <awesome-picker
            ref="areaPicker"
            :anchor = "picker.anchor"
            :colorConfirm="picker.colorConfirm"
            :data="proviceList"
            @confirm="handlePickerArea">
        </awesome-picker>

    </div>  
</template>
<script>
    import utils from '../../common/js/utils';
    import coverImg from '../../components/coverImg'
    import adjust from '../../common/js/adjust';
    import {formatDate} from '../../common/js/dateformat'
    import {getCommonList,getMemberInfo,getAreaList,getManager,getNoticeMan} from '../../api/common'
    import {getAddAccident} from '../../api/accident'
    //import Calendar from 'vue-mobile-calendar'
    


    export default {
        components: {
            coverImg
        },
        data() {
            return {
                carNo:'',
                noticeMan:'',
                columns: 1,
                typeDefaultData:[],
                typeShow:false,
                pickerValue:new Date(),
                addForm:{
                    accidentType:'',
                    accidentTime:'',
                    detailAddress:'',
                    describe:'',
                    // thirdCarNo:'',
                    // thirdCarVin:'',
                    // thirdDriver:'',
                    // thirdDriverPhone:'',
                    blame:'',
                    accidentLose:'',
                    isCasualtie:'',
                    casualtieDesc:'',
                    serviceHospital:'',
                    medicalExpense:'',
                    fixLose:'',
                    claimMoney:'',
                    needRepaire:'',
                    repaireCompany:'',
                    repaireMoney:'',
                    provinceCode:'',
                    blameReport:'',
                    surveyReport:'',
                    repaireReport:'',
                    accidentScence:''


                },
                typeList:[],
                memberName:'',
                carNo:'',
                accidentTypeName:'',
                proviceList:[],
                cityList:[],
                areaList:[],
                cityList1:[],
                proAddress:'',
                cityCode:'',
                areaCode:'',
                picker:{
                    colorConfirm:'rgb(30,135,242)',
                    anchor:[
                        {index:"17"},
                        {index:"0"},
                        {index:"1"},
                       
                    ]
                },
                managerName:'',
                iosVaild : utils.versions.ios,
                androidVaild : utils.versions.android,
                winHeight: document.body.clientHeight,
                showStatic:false,
                fixStyle:'position:fixed',
                staticStyle:"position:static",
                imgArr1:[],
                imgArr2:[],
                imgArr3:[],
                imgArr4:[],
                imgList:[],
                totleImg:6,
                imgType:'',
                btnClick:true,


            }
        },
        filters: {
            dateFormat(time) {
                if(time&&time!='请选择>'){
                    // var date = new Date(time);
                    // return formatDate(date,'yyyy-MM-dd hh:mm');
                    return time;
                }else{
                    return '请选择>'
                }

                
            }
        },
        methods: {
            //从app获取到图片
            toImgValue(rst){
                var _this = this;
                var arr = rst?rst.split(","):'';
                if(!arr)return false;
                if(arr.length<=0)return false;
                //imgFlag = imgArr.length;//用来标记上次传了几张图片
                arr.forEach(function(v,i){
                    if(_this.imgType == 1){
                         _this.imgArr1.push(v);
                    }else if(_this.imgType == 2){
                         _this.imgArr2.push(v);
                    }else if(_this.imgType == 3){
                         _this.imgArr3.push(v);
                    }else if(_this.imgType == 4){
                        _this.imgArr4.push(v);
                    }
                   
                })
                //_this.lightImgBox();
            },
            //app调用toImgValue方法
            appImgUrl(){
                var _this = this;
                if(this.iosVaild){
                    utils.setupWebViewJavascriptBridge(function(bridge) {
                        bridge.registerHandler('toImgValue',function(response,call){
                            _this.toImgValue(response);
                        })          
                    })      
                        
                }else if(this.androidVaild){
                    window.toImgValue = function(str){
                        _this.toImgValue(str);
                    }
                }
            },
            //点击加号上传图片
            handleUpdateImg1(){
                var len = this.totleImg-this.imgArr1.length;
                this.imgType = 1;
                adjust.goAppUploader(len);

            },
            handleUpdateImg2(){
                var len = this.totleImg-this.imgArr2.length;
                this.imgType = 2;
                adjust.goAppUploader(len);

            },
            handleUpdateImg3(){
                var len = this.totleImg-this.imgArr3.length;
                this.imgType = 3;
                adjust.goAppUploader(len);

            },
            handleUpdateImg4(){
                var len = this.totleImg-this.imgArr4.length;
                this.imgType = 4;
                adjust.goAppUploader(len);

            },
            //删除图片
            // handleDelImg1(index){
            //     this.imgArr1.splice(index,1);
            // },
            // handleDelImg2(index){
            //     this.imgArr2.splice(index,1);
            // },
            // handleDelImg3(index){
            //     this.imgArr3.splice(index,1);
            // },
            handleDelImg4(index){
                console.log(index)
                this.imgArr4.splice(index,1);
            },
            //图片放大
            handleImg(index,type){
                if(type==1){
                    this.imgList = this.imgArr1;
                }else if(type==2){
                    this.imgList = this.imgArr2;
                }else if(type==3){
                    this.imgList = this.imgArr3;
                }else if(type==4){
                    this.imgList = this.imgArr4;
                }

                this.$refs.applyImg.viewImgSwiper = true;
                if(this.$refs.applyImg.swiperImgBox){
                    this.$refs.applyImg.swiperImgBox.slideTo(index, 50, false);
                }
            },
            //获取抄送人
            getNoticeManInfo(){
                getNoticeMan({}).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.noticeMan = res.data.data||'';

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            //获取管理员信息
            getManagerInfo(){
                getManager({}).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.managerName = data.managerName||'';

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            handlePickerArea(v){
                this.proAddress = v[0].value+'-'+v[1].value+'-'+v[2].value;
                var pObj = this.proviceList[v[0].index];
                this.addForm.provinceCode = pObj .id||'';
                var cObj = pObj.children[v[1].index];
                this.addForm.cityCode = cObj.id||'';
                var aObj = cObj.children[v[2].index];
                this.addForm.areaCode = aObj.id||'';

            },
            //获取到自己的信息
            getMyInfo(){
                getMemberInfo({}).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.memberName = data.memberName||'';
                            this.carNo = data.carNo||'';

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            getTypeList(){
                var _this = this;
                let para = {
                    name:'SYS_ACCIDENT_TYPE'
                }
                getCommonList(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            if(data.length>0){
                                data.forEach((v,i)=>{
                                    let list = {};
                                    list["code"]=v.dicCode;
                                    list["value"]=v.dicName;
                                    _this.typeList.push(list);
                                })
                            }
                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            getAreaFun(){
                var _this = this;
                getAreaList({}).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            data.forEach(function(v,i){
                                if(v.level=='1'){
                                    _this.proviceList.push({id:v.code,value:v.name,level:v.level,sheng:v.sheng})
                                }
                                if(v.level=='2'){
                                    _this.cityList1.push({id:v.code,value:v.name,level:v.level,sheng:v.sheng});
                                }
                            })
                            _this.proviceList.forEach(function(v,i){
                                var  arr = []
                                data.forEach(function(v1,i1){
                                    if(v1.level==2&&v1.sheng == v.id){
                                        arr.push({id:v1.code,value:v1.name,level:v1.level,sheng:v1.sheng});
                                    }
                                })
                                 _this.cityList[v.id] = arr


                            })
                            _this.cityList1.forEach(function(v,i){
                                var arr = []
                                data.forEach(function(v1,i1){
                                    if(v1.level==3&&v1.code.substr(0,4) == v.id){
                                        arr.push({id:v1.code,value:v1.name,level:v1.level,sheng:v1.sheng});
                                    }
                                })
                                _this.areaList[v.id] = arr;
                            })

                            _this.proviceList.forEach((province) => {
                                province.children = _this.cityList[province.id]
                                province.children.forEach((city) => {
                                    city.children = _this.areaList[city.id]
                                })
                            })
                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            typeConfirmFn(val) {
                if(val){
                    this.accidentTypeName = val[0].value||'';
                    this.addForm.accidentType = this.typeList[val[0].index].code;
                }
            },
            toTypeShow() {
                this.$refs.picker.show();
                //this.typeShow = true;
            },
            toAccidentTime(){
                this.$refs.accidentTimePicker.open();
                //this.calendarShowBegin = true;
            },
            handleaccidentTime(val){
                this.addForm.accidentTime = formatDate(val,'yyyy-MM-dd hh:mm:ss');;
            },
            toAddressShow(){
                this.$refs.areaPicker.show()
            },
            handleApply(){
                var _this = this;
                this.checkForm(()=>{
                    // _this.addForm.blameReport = _this.imgArr1.length>0?_this.imgArr1.join(','):'';
                    // _this.addForm.surveyReport = _this.imgArr2.length>0?_this.imgArr2.join(','):'';
                    // _this.addForm.repaireReport = _this.imgArr3.length>0?_this.imgArr3.join(','):'';
                    _this.addForm.accidentScence = _this.imgArr4.length>0?_this.imgArr4.join(','):'';
                    let para = Object.assign({}, _this.addForm);
                    if(_this.btnClick){
                        _this.btnClick = false;
                        getAddAccident(para).then(res => {
                            if(res&&res.resCode==0){
                                if(res.data&&res.data.errcode==0){
                                    _this.$toast('申请成功');
                                    setTimeout(function(){
                                        _this.$router.push({path: '/accident/list'});
                                    },2000)

                                }else{
                                    this.$toast(res.data.errmsg||'网络错误，请重试！');
                                    _this.btnClick = true;
                                }
                            }else{
                                this.$toast(res.resMsg||'网络错误，请重试！');
                                _this.btnClick = true;
                            }
                        
                        }).catch(res => {
                            _this.btnClick = true;
                          
                        });
                    }
                })
            },
            checkForm(callback){
                if(!this.addForm.accidentType){
                    this.$toast('请选择事故类型');
                    return false;
                }else if(!this.addForm.accidentTime){
                    this.$toast('请选择事故时间');
                    return false;
                }else if(!this.proAddress){
                    this.$toast('请选择事故地区');
                    return false;
                }else if(!this.addForm.detailAddress){
                    this.$toast('请输入事故详细地址');
                    return false;
                }
                // else if(!this.addForm.describe){
                //      this.$toast('请输入事故描述');
                //     return false;
                // }
                else{
                    callback&&callback();
                }

            }
        },

        mounted() {
            var _this = this;
            this.getMyInfo();
            this.getNoticeManInfo();
            this.getManagerInfo();
            this.getTypeList();
            this.getAreaFun();
            this.appImgUrl();
            window.onresize = () => {
                var curHeight = document.body.clientHeight;
                if(curHeight<_this.winHeight){
                    _this.showStatic = true;
                }else{
                    _this.showStatic = false;
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            if(to.name == 'accidentList'){
                to.meta.isUpdate = true;
            }
            next();
        },
    }
</script>
<style lang="scss">
    @import "../../styles/common";
    .add-box{
        margin-bottom:0.1rem;
    }
    .title-wrap{
        padding:0.32rem 0.20rem 0;
        background-color:#fff;
        height:0.4rem;
        .info-title{
            position:relative;
            line-height:0.4rem;
            padding-left:0.18rem;
            font-size:0.32rem;
            color:rgb(51,51,51);
            background-color:#fff;

            .line-icon{
                position:absolute;
                left:0rem;
                top:0rem;
                width:0.08rem;
                height:0.4rem;
                background-color:rgb(38,41,56);
                @include border-radius(0.2rem);
            }
        }
    }
    
    .apply-wrap{
        background-color:#F5F4F3;
        width:100%;
        height:100%;
        position:fixed;
        left:0;
        top:0;
        overflow:auto;
        .form-box{
            border-bottom:1px solid #EEEBE9;
            background-color:#fff;
            height:1rem;
            line-height:0.9rem;
            padding:0 0.50rem;
            label{
                width:30%;
                font-size:0.28rem;
                color:rgb(51,51,51);
            }
            input,.right-select,.right{
                width:70%;
                border: 0 none;
                line-height:1rem;
                height:1rem;
                text-align:right;
                font-size:0.28rem;
                &.active{
                    color:rgb(51,51,51);
                }
            }
            .right-select{
                color:rgb(153,153,153);
            }
            .right{
                font-size:0.28rem;
                color:rgb(51,51,51);
            }
            .radio{
                width:0.28rem;
                vertical-align:middle;
                margin-left:0.2rem;
                margin-right:0.2rem;
            }
        }
        .textarea-box{
            height:auto;
            margin:0;
            textarea{
                display:block;
                border:0 none;
                width:100%;

            }
        }
        
    }
    .area_ctrl{
        background-color:#fff!important;
        .area_btn_box{
            background-color:#fff!important;
            &::before,&::after{
                background-color:#fff!important;
            }
        }
    }
    .ui-button{
        width:100%;
        height:1rem;
        background-color:rgb(251,55,91);
        text-align:center;
        line-height:1rem;
        font-size:0.32rem;
        color:#fff;
    }

    .accident-add-wrap{
        .form-box{
            label{
                height:1rem;
                line-height:1rem;
            }
            .label-star{
                top:0.55rem;
            }
            .label-star1{
                top:0.24rem;
            }
        }
        .ui-img-wrap{
            padding:0 0.5rem;
            .img-ul{
                li{
                    margin-right:5%;
                    &:nth-of-type(3n){
                        margin-right:0;
                    }
                }
            }
        }
        .textarea-box{
            margin:0;
        }
    }
    .radio-box {
        text-align: center;
        width: 100%;
        over-flow:hidden;
        input[type="radio"],input[type="checkbox"] {
            position: absolute;
            left: -6.8rem;
            color: transparent;

        }
        label {
            display: inline-block;
            color: #5c5c6b!important;
            position: relative;
            margin-left: 0.6rem;
            width:auto!important;
        }
        label::before, label::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -0.36rem;
            margin: auto;
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;
        }
        label::before {
            background-color: #d8d7dc;
        }
        label::after {
            background: rgb(30,135,242);
            opacity: .2;
            @include transform(scale(0));
            -webkit-transition: .3s;
            -o-transition: .3s;
            transition: .3s;
        }
        input:checked + label::after {
            @include transform(scale(0.7));
            opacity: 1;
        }
    }
</style>



