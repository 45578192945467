
import Main from './views/Main.vue'


import MaintenanceMyList from './views/maintenance/myList'
import MaintenanceList from './views/maintenance/list'
import MaintenanceApply from './views/maintenance/apply'
import MaintenanceApproval from './views/maintenance/approval'
import MaintenanceSendCar from './views/maintenance/sendCar'
import MaintenanceReceiveCar from './views/maintenance/receiveCar'
import MaintenanceDetail from './views/maintenance/detail'

import LeaveMyList from './views/leave/myList'
import LeaveList from './views/leave/list'
import LeaveApply from './views/leave/apply'
import LeaveDetail from './views/leave/detail'
import LeaveApproval from './views/leave/approval'

import OilReportList from './views/oilReport/list'
import OilReportDetail from './views/oilReport/detail'
import OilReportAdd from './views/oilReport/add'

import OilList from './views/oil/list'
import OilDetail from './views/oil/detail'
import OilAdd from './views/oil/add'

import AccidentList from './views/accident/list'
import AccidentDetail from './views/accident/detail'
import AccidentAdd from './views/accident/add'

import DocumentMyList from './views/document/myList'
import DocumentList from './views/document/list'
import DocumentDetail from './views/document/detail'
import DocumentApproval from './views/document/approval'

import CasePreList from './views/casePre/list'

import InvoiceList from './views/invoice/list'
import InvoiceDetail from './views/invoice/detail'


let routes = [

    // {
    //     path: '/404',
    //     component: NotFound,
    //     name: '',
    //     hidden: true,
    //     meta: {
    //         title: '找不到页面'
    //     }
    // },
    // {
    //     path: '/',
    //     component: Home,
    //     name: '主页',
    // },
    { path: '/index', component: Main, name: 'index' },
    // { path: '/list', component: Home, name: 'list' },
    // { path: '/detail', component: Detail, name: 'detail' },
    // { path: '/start/:createUserId/:receiveUserId/:groupId', component: Start, name: 'start' },
    // { path: '/statistics/:createUserId/:receiveUserId/:groupId', component: Statistics, name: 'statistics' },
    // { path: '/explain', component: Explain, name: 'explain' },
    { path: '/maintenance/myList', component: MaintenanceMyList, name: 'maintenanceMyList', meta: { title: "维修保养", keepAlive: true, isUpdate: false } },
    { path: '/maintenance/list', component: MaintenanceList, name: 'maintenanceList', meta: { title: "维修保养", keepAlive: true, isUpdate: false } },
    { path: '/maintenance/apply/:type', component: MaintenanceApply, name: 'maintenanceApply', meta: { title: "维修申请" } },
    { path: '/maintenance/approval/:repaireId/:type', component: MaintenanceApproval, name: 'maintenanceApproval', meta: { title: "维修审批" } },
    { path: '/maintenance/sendCar/:repaireId/:type', component: MaintenanceSendCar, name: 'maintenanceSendCar', meta: { title: "维修送车" } },
    { path: '/maintenance/receiveCar/:repaireId/:type', component: MaintenanceReceiveCar, name: 'maintenanceReceiveCar', meta: { title: "维修接车" } },
    { path: '/maintenance/detail/:repaireId/:type', component: MaintenanceDetail, name: 'maintenanceDetail', meta: { title: "维修详情" } },

    { path: '/leave/myList', component: LeaveMyList, name: 'leaveMyList', meta: { title: "请假审批", keepAlive: true, isUpdate: false } },
    { path: '/leave/list', component: LeaveList, name: 'leaveList', meta: { title: "请假审批", keepAlive: true, isUpdate: false } },
    { path: '/leave/apply/:type', component: LeaveApply, name: 'leaveApply', meta: { title: "请假申请" } },
    { path: '/leave/detail/:leaveId/:type', component: LeaveDetail, name: 'leaveDetail', meta: { title: "请假详情" } },
    { path: '/leave/approval/:leaveId/:type', component: LeaveApproval, name: 'leaveapproval', meta: { title: "审批意见" } },

    { path: '/oilReport/list', component: OilReportList, name: 'oilReportList', meta: { title: "每月油报", keepAlive: true, isUpdate: false } },
    { path: '/oilReport/detail/:reportId', component: OilReportDetail, name: 'oilReportDetail', meta: { title: "油报详情" } },
    { path: '/oilReport/add', component: OilReportAdd, name: 'oilReportAdd', meta: { title: "添加油报" } },

    { path: '/oil/list', component: OilList, name: 'oilList', meta: { title: "加油记录", keepAlive: true, isUpdate: false } },
    { path: '/oil/detail/:oilId', component: OilDetail, name: 'oilDetail', meta: { title: "加油详情" } },
    { path: '/oil/add', component: OilAdd, name: 'oilAdd', meta: { title: "加油" } },

    { path: '/accident/list', component: AccidentList, name: 'accidentList', meta: { title: "事故申报", keepAlive: true, isUpdate: false } },
    { path: '/accident/detail/:accidentId', component: AccidentDetail, name: 'accidentDetail', meta: { title: "事故详情" } },
    { path: '/accident/add', component: AccidentAdd, name: 'accidentAdd', meta: { title: "申报事故" } },

    { path: '/document/myList', component: DocumentMyList, name: 'documentMyList', meta: { title: "文件资料", keepAlive: true, isUpdate: false } },
    { path: '/document/list', component: DocumentList, name: 'documentList', meta: { title: "文件资料", keepAlive: true, isUpdate: false } },
    { path: '/document/approval/:docId', component: DocumentApproval, name: 'documentApproval', meta: { title: "文件审批" } },
    { path: '/document/detail/:docId', component: DocumentDetail, name: 'documentDetail', meta: { title: "文件详情" } },

    { path: '/casePre/list', component: CasePreList, name: 'casePreList', meta: { title: "案件预告" } },

    { path: '/invoice/list', component: InvoiceList, name: 'invoiceList', meta: { title: "发票配送", keepAlive: true, isUpdate: false } },
    { path: '/invoice/detail/:invoiceId', component: InvoiceDetail, name: 'invoiceDetail', meta: { title: "发票详情" } },


];

export default routes;