<template>
    <div class="doc-detail-wrap">
        <div class="title">{{detailData.docName}}</div>
        <div class="cnt" v-html="detailData.docContent" @click="handleImg($event)"></div>
        <div class="bottom">
            <div class="name">{{detailData.dispatch}}</div>
            <div class="time">{{detailData.createDate|dateFormat}}</div>
        </div>
        <div class="cover-mod jCoverImg" v-show="viewImgSwiper" @click="closeImg">
            <ul class="items swiper-wrapper">
                <li class="swiper-slide" v-for="(list,index) in imgArr" :key="index"><img class="small" :src="list"></li>
            </ul>
            <div class="swiper-pagination"></div>
        </div>
    </div>
    
</template>
<script>
    import Swiper from 'swiper'
    import 'swiper/dist/css/swiper.min.css';
    import {formatDate} from '../../common/js/dateformat'
    import {getDucumentDetail} from '../../api/document'
    import adjust from '../../common/js/adjust';
  

    export default {
        data() {
            return {
                detailData:[],
                docId:'',
                imgArr:[],
                swiperImgBox:null,
                viewImgSwiper:false,
                
            }
        },
        filters: {
            dateFormat(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd hh:mm');
                }   
            }
        },
        methods: {
            //图片放大
            handleImg(event){
                if (event.target.nodeName === 'IMG') {
                    var index = 0;
                    if(event.toElement.src){
                        index = this.imgArr.indexOf(event.toElement.src);
                        index = index == '-1'?0:index;
                    }
                    this.viewImgSwiper = true;
                    if(this.swiperImgBox){
                        this.swiperImgBox.slideTo(index, 50, false);
                    }
                }
                
            },
            //关闭图片轮播
            closeImg(){
                this.viewImgSwiper = false;
            },
            getDetailFun(){
                var _this = this;
                let para = {
                    docId:this.docId
                }
                getDucumentDetail(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.detailData = data;
                            this.detailData.docContent.replace(/<script.*?>.*?<\/script>/ig,'');

                            var imgReg = /<img.*?(?:>|\/>)/gi;//匹配所有img标签
                            var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;//匹配img标签里面的src
                            var arr = this.detailData.docContent.match(imgReg);
                            if(arr.length>0){
                                arr.forEach(function(v,i){
                                    var srcImg = v.match(srcReg);
                                    if(srcImg){
                                         _this.imgArr.push(srcImg[1]);
                                    }
                                   
                                })
                            }

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            // lightImgBox:function(){
            //     var $imgList= $detailWrap.find('.jMaxImg'),array=[];
            //     if($imgList.length>0){
            //         $.each($imgList,function(i,v){
            //             var src = $(this).attr('data-url');
            //             $(this).attr('data-index',i);
            //             if(/,/.test(src)){
            //                 var srcArry = src.split(',');
            //                 array.push(srcArry[0].replace(/\/$/,""));
            //                 $(this).attr('data-url',srcArry[0]).after('<span class="img-txt"><font>'+srcArry[1]+'</font></span>');
            //             }else{
            //                 array.push($(this).attr('data-url').replace(/\/$/,""));
            //             }
            //         });
            //         $detailWrap.append(template("tpl-image-cnt",{'imgObj':array,'imgWidth':$(window).width()}));
            //         swiperImgBox = new swiper('.jCoverImg',{
            //             resistanceRatio : 0,
            //             pagination:'.swiper-pagination2'
            //         });
            //         var $item=$('.jCoverImg li');
            //         $.each($item,function(i,n){
            //             $(this).find('img')[0].onload = function(){
            //                 var img=$(this);
            //                 if(img.height()<parseInt($(window).height())){
            //                     img.addClass('small');
            //                 }
            //             };
            //         });
            //     }
            // },
            
        },

        mounted() {
       	 	var _this = this;
            this.docId = this.$route.params.docId||'';
            this.swiperImgBox = new Swiper('.jCoverImg',{
                resistanceRatio : 0,
                pagination: {el:'.swiper-pagination'},
                paginationClickable: true,
                observer:true ,//修改swiper自己或子元素时，自动初始化swiper
                observeParents:true,//修改swiper的父元素时，自动初始化swiper
            });
            adjust.getTokenInfo(function(){
                _this.getDetailFun();
            });
            
        },
        beforeRouteLeave(to, from, next) {
            to.meta.isUpdate = false; 
            next();
        }
    }
</script>
<style  lang="scss">
    @import "../../styles/coverImg";
    .doc-detail-wrap{
        padding:0.32rem;
        .title{
            text-align:center;
            font-size:0.4rem;
            line-height:0.6rem;
            //padding:0.2rem 0;

        }
        .cnt{
            padding-top:0.3rem;
            //padding: 0 0.32rem;
            font-size: 0.32rem;
            line-height: 0.5rem;
            color:#595350;
            word-wrap: break-word;
            -webkit-box-orient: vertical; 
            text-align:justify;
            text-justify:inter-ideograph;/*IE*/
            img{
                padding:0.1rem 0rem;
                margin:0 auto;
                vertical-align:middle;

            }
        }
        .bottom{
            text-align:right;
            font-size:0.32rem;
            padding-top:0.5rem;
            .name{
                line-height:0.5rem;
                color: #333;
            }
            .time{
                color:#888;
            }

        }
    }
</style>