<template>
    <div class="apply-wrap">
        <form>
            <div class="form-box clear">
                <label class="fl"><span class="label-star">*</span>司机姓名：</label>
                <span class="fr right">{{memberName}}</span>
            </div>
            <div class="form-box clear">
                <label class="fl"><span class="label-star">*</span>车牌号码：</label>
                <input class="fr" type="text"  placeholder="请输入车牌号码" v-model="addForm.carNo">
            </div>
            <div class="form-box clear">
                <label class="fl"><span class="label-star">*</span>付款方式：</label>
                <div class="fr right-select" :class="addForm.payMethod?'active':''" @click="toTypeShow">{{addForm.payMethod?addForm.payMethod:'请选择>'}}</div>
            </div>
            <!-- <div class="form-box clear">
                <label class="fl"><span class="label-star">*</span>开始时间：</label>
                <div class="fr right-select" style="cursor:pointer;" @click="toShowBeginTime">{{addForm.startTime|dateFormat}}</div>
            </div>
            <div class="form-box clear">
                <label class="fl"><span class="label-star">*</span>结束时间：</label>
                 <div class="fr right-select" style="cursor:pointer;" @click="toShowEndTime">{{addForm.endTime|dateFormat}}</div>
            </div> -->
            <div class="form-box clear">
                <label class="fl"><span class="label-star">*</span>加油金额(元)：</label>
                <input class="fr" type="number" placeholder="请输入加油金额" v-model="addForm.oilMoney">
            </div>
             <div class="form-box clear">
                <label class="fl"><span class="label-star">*</span>加油量(L)：</label>
                <input class="fr" type="number" placeholder="请输入加油量" v-model="addForm.oilMass">
            </div>
        </form>
        <div class="ui-button" @click="handleApply" :style="showStatic?staticStyle:fixStyle">保存</div>
        <awesome-picker
            ref="picker"
            :data="picker.data"
            @confirm="typeConfirmFn">
        </awesome-picker>
    </div>
</template>
<script>
import utils from '../../common/js/utils';
// import { formatDate } from '../../common/js/dateformat'
import { getAddOil } from '../../api/oil'
import { getMemberInfo } from '../../api/common'
// import Calendar from 'vue-mobile-calendar'
export default {
  components: {
  },
  data() {
    return {
      addForm: {
        carNo: '',
        payMethod: '',
        oilMass: '',
        oilMoney: ''
      },
      memberName: '',
      carNo: '',
      picker: {
        data: [['加油卡', '现金', '积分']]
      },
      iosVaild: utils.versions.ios,
      androidVaild: utils.versions.android,
      winHeight: document.body.clientHeight,
      showStatic: false,
      fixStyle: 'position:fixed',
      staticStyle: 'position:static',
      btnClick: true
    }
  },
  filters: {
    dateFormat(time) {
      if (time && time !== '请选择>') {
        // var date = new Date(time);
        // return formatDate(date,'yyyy-MM-dd hh:mm');
        return time;
      } else {
        return '请选择>'
      }
    }
  },
  methods: {
    // 获取到自己的信息
    getMyInfo() {
      getMemberInfo({}).then(res => {
        if (res && res.resCode === 0) {
          if (res.data && res.data.errcode === 0 && res.data.data) {
            var data = res.data.data;
            this.memberName = data.memberName || '';
            this.carNo = data.carNo || '';
            this.addForm.carNo = this.carNo;
          } else {
            this.$toast(res.data.errmsg || '网络错误，请重试！');
          }
        } else {
          this.$toast(res.resMsg || '网络错误，请重试！');
        }
      }).catch(res => {
      });
    },
    toTypeShow() {
      this.$refs.picker.show();
    },
    typeConfirmFn(val) {
      this.addForm.payMethod = val[0].value || '';
    },
    handleApply() {
      var _this = this;
      this.checkForm(() => {
        let para = Object.assign({}, this.addForm);
        if (_this.btnClick) {
          _this.btnClick = false;
          getAddOil(para).then(res => {
            if (res && res.resCode === 0) {
              if (res.data && res.data.errcode === 0) {
                this.$toast('添加成功');
                setTimeout(function() {
                  _this.$router.push({ path: '/oil/list' });
                }, 2000)
              } else {
                this.$toast(res.data.errmsg || '网络错误，请重试！');
                _this.btnClick = true;
              }
            } else {
              this.$toast(res.resMsg || '网络错误，请重试！');
              _this.btnClick = true;
            }
          }).catch(res => {
            _this.btnClick = true;
          });
        }
      })
    },
    checkForm(callback) {
      if (!this.addForm.carNo) {
        this.$toast('请输入车牌号码');
        return false;
      } else if (!this.addForm.payMethod) {
        this.$toast('请选择付款方式');
        return false;
      } else if (!this.addForm.oilMoney) {
        this.$toast('请输入加油金额');
        return false;
      } else if (!this.addForm.oilMass) {
        this.$toast('请输入加油量');
        return false;
      } else {
        callback && callback();
      }
    }
  },
  mounted() {
    var _this = this;
    this.getMyInfo();
    window.onresize = () => {
      if (_this.androidVaild) {
        var curHeight = document.body.clientHeight;
        if (curHeight < _this.winHeight) {
          _this.showStatic = true;
        } else {
          _this.showStatic = false;
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'oilList') {
      to.meta.isUpdate = true;
    }
    next();
  }
}
</script>
<style lang="scss">
    @import "../../styles/common";
    .apply-wrap{
        background-color:#F5F4F3;
        width:100%;
        height:100%;
        position:fixed;
        left:0;
        top:0;
        .form-box{
            border-bottom:1px solid #EEEBE9;
            background-color:#fff;
            height:1rem;
            line-height:0.9rem;
            padding:0 0.30rem;
            label{
                width:30%;
                font-size:0.28rem;
                color:rgb(51,51,51);
            }
            input,.right-select{
                width:70%;
                border: 0 none;
                line-height:1rem;
                height:1rem;
                text-align:right;
                font-size:0.28rem;
                &.active{
                    color:rgb(51,51,51);
                }
            }
            .right-select{
                color:rgb(153,153,153);
            }
        }
        .textarea-box{
            height:auto;
            margin-bottom:0.1rem;
            textarea{
                display:block;
                border:0 none;
                width:100%;
            }
        }
    }
    .area_ctrl{
        background-color:#fff!important;
        .area_btn_box{
            background-color:#fff!important;
            &::before,&::after{
                background-color:#fff!important;
            }
        }
    }
    .ui-button{
        width:100%;
        height:1rem;
        background-color:rgb(251,55,91);
        text-align:center;
        line-height:1rem;
        font-size:0.32rem;
        color:#fff;
    }
</style>
