<template>
  <div class="apply-wrap">
    <form>
      <div class="form-box clear">
        <label class="fl">
          <span class="label-star">*</span>司机姓名：
        </label>
        <span class="fr right">{{memberName}}</span>
      </div>
      <div class="form-box clear">
        <label class="fl">
          <span class="label-star">*</span>车牌号码：
        </label>
        <input class="fr" type="text" placeholder="请输入车牌号" v-model="addForm.carNo" />
      </div>
      <div class="form-box clear">
        <label class="fl">
          <span class="label-star">*</span>上报月份：
        </label>
        <div
          class="fr right-select"
          :class="addForm.reportDate?'active':''"
          @click="toShowBeginTime"
        >{{addForm.reportDate|dateFormat}}</div>
      </div>
      <div class="form-box clear">
        <label class="fl">
          <span class="label-star">*</span>车辆里程(km)：
        </label>
        <input
          class="fr"
          type="number"
          placeholder="请输入车辆实际里程数"
          v-model="addForm.carKm"
          @input="changeNum"
        />
      </div>
      <div class="form-box clear">
        <label class="fl">
          <span class="label-star">*</span>剩余油量(L)：
        </label>
        <input class="fr" type="number" placeholder="请输入车辆剩余油量" v-model="addForm.oilMass" />
      </div>
      <div class="ui-img-wrap imgWrap">
        <p class="title jImgTile" style="position:relative;">
          <span class="label-star" style="top:0.42rem">*</span>油量/里程照片（最多上传2张照片）
        </p>
        <ul class="img-ul clear">
          <li class="fl" v-for="(list,index) in imgArr" :key="index">
            <div class="img">
              <img class="jMaxImg big-img" :src="list" @click="handleImg(index)" />
              <img
                class="icon-del jIconDel"
                src="../../common/images/icon_del.png"
                alt
                @click="handleDelImg(index)"
              />
              <!-- //<i class='icon-del jIconDel'></i> -->
            </div>
          </li>
          <li class="fl last jLast" @click="handleUpdateImg" v-show="imgArr.length<6">
            <div class="img">
              <i class="line line1"></i>
              <i class="line line2"></i>
            </div>
          </li>
        </ul>
        <div class="swiperImg"></div>
      </div>
    </form>
    <div class="ui-button" @click="handleApply" :style="showStatic?staticStyle:fixStyle">保存</div>
    <mt-datetime-picker
      ref="beginPicker"
      type="date"
      year-format="{value} 年"
      month-format="{value} 月"
      date-format="{value} 日"
      v-model="pickerValue"
      @confirm="handleBeginTime"
    ></mt-datetime-picker>
    <cover-img ref="applyImg" :imgArr="imgArr"></cover-img>
  </div>
</template>
<script>
import utils from '../../common/js/utils'
import adjust from '../../common/js/adjust'
import coverImg from '../../components/coverImg'
import { formatDate } from '../../common/js/dateformat'
import { getRepaireAdd } from '../../api/oilReport'
import { getMemberInfo } from '../../api/common'
// import Calendar from 'vue-mobile-calendar'

export default {
  components: {
    coverImg
  },
  data () {
    return {
      addForm: {
        images: '',
        carKm: '',
        reportDate: '',
        oilMass: '',
        carNo: ''
      },
      memberName: '',
      carNo: '',
      pickerValue: new Date(),
      iosVaild: utils.versions.ios,
      androidVaild: utils.versions.android,
      // imgArr:['http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111328219571.jpg','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111328219571.jpg','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111338540662.jpg ','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111338540662.jpg ','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111338540662.jpg ','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111338540662.jpg '],
      imgArr: [],
      tatolImg: 2,
      winHeight: document.body.clientHeight,
      showStatic: false,
      fixStyle: 'position:fixed',
      staticStyle: 'position:static',
      btnClick: true
    }
  },
  filters: {
    dateFormat (time) {
      if (time && time !== '请选择>') {
        // var date = new Date(time);
        // return formatDate(date,'yyyy-MM');
        return time
      } else {
        return '请选择>'
      }
    }
  },
  methods: {
    changeNum () {
      if (this.addForm.carKm.length > 6) {
        this.addForm.carKm = this.addForm.carKm.slice(0, 6)
      }
    },
    // 删除图片
    handleDelImg (index) {
      this.imgArr.splice(index, 1)
    },
    // 从app获取到图片
    toImgValue (rst) {
      var _this = this
      var arr = rst ? rst.split(',') : ''
      if (!arr) return false
      if (arr.length <= 0) return false
      // imgFlag = imgArr.length;//用来标记上次传了几张图片
      arr.forEach(function (v, i) {
        _this.imgArr.push(v)
      })
      // _this.lightImgBox();
    },
    // app调用toImgValue方法
    appImgUrl () {
      var _this = this
      if (this.iosVaild) {
        utils.setupWebViewJavascriptBridge(function (bridge) {
          bridge.registerHandler('toImgValue', function (response, call) {
            _this.toImgValue(response)
          })
        })
      } else if (this.androidVaild) {
        window.toImgValue = function (str) {
          _this.toImgValue(str)
        }
      }
    },
    // 点击加号上传图片
    handleUpdateImg () {
      let len = this.tatolImg - this.imgArr.length
      adjust.goAppUploader(len)
    },
    // 图片放大
    handleImg (index) {
      this.$refs.applyImg.viewImgSwiper = true
      if (this.$refs.applyImg.swiperImgBox) {
        this.$refs.applyImg.swiperImgBox.slideTo(index, 50, false)
      }
    },
    // 获取到自己的信息
    getMyInfo () {
      getMemberInfo({})
        .then(res => {
          if (res && res.resCode === 0) {
            if (res.data && res.data.errcode === 0 && res.data.data) {
              var data = res.data.data
              this.memberName = data.memberName || ''
              this.carNo = data.carNo || ''
              this.addForm.carNo = this.carNo
            } else {
              this.$toast(res.data.errmsg || '网络错误，请重试！')
            }
          } else {
            this.$toast(res.resMsg || '网络错误，请重试！')
          }
        })
        .catch(res => {})
    },
    toShowBeginTime () {
      this.$refs.beginPicker.open()
    },
    handleBeginTime (val) {
      this.addForm.reportDate = formatDate(val, 'yyyy-MM')
      console.log(this.addForm.reportDate)
    },

    handleApply () {
      var _this = this
      this.addForm.images = this.imgArr.join(',')
      this.checkForm(() => {
        let para = Object.assign({}, this.addForm)
        if (_this.btnClick) {
          _this.btnClick = false
          getRepaireAdd(para)
            .then(res => {
              if (res && res.resCode === 0) {
                if (res.data && res.data.errcode === 0) {
                  this.$toast('添加成功')
                  setTimeout(function () {
                    _this.$router.push({ path: '/oilReport/list' })
                  }, 2000)
                } else {
                  this.$toast(res.data.errmsg || '网络错误，请重试！')
                  _this.btnClick = true
                }
              } else {
                this.$toast(res.resMsg || '网络错误，请重试！')
                _this.btnClick = true
              }
            })
            .catch(res => {
              _this.btnClick = true
            })
        }
      })
    },
    checkForm (callback) {
      if (!this.addForm.carNo) {
        this.$toast('请输入车牌号')
        return false
      } else if (!this.addForm.reportDate) {
        this.$toast('请选择上报报月份')
        return false
      } else if (!this.addForm.carKm) {
        this.$toast('请输入车辆里程')
        return false
      } else if (!this.addForm.oilMass) {
        this.$toast('请输入车辆油量')
        return false
      } else if (!this.addForm.images) {
        this.$toast('请上传里程/油量照片')
        return false
      } else {
        callback && callback()
      }
    }
  },

  mounted () {
    var _this = this
    this.getMyInfo()
    this.appImgUrl()
    window.onresize = () => {
      if (_this.androidVaild) {
        var curHeight = document.body.clientHeight
        if (curHeight < _this.winHeight) {
          _this.showStatic = true
        } else {
          _this.showStatic = false
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'oilReportList') {
      to.meta.isUpdate = true
    }
    next()
  }
}
</script>
<style lang="scss">
@import '../../styles/common';
.area_ctrl {
  background-color: #fff !important;
  .area_btn_box {
    background-color: #fff !important;
    &::before,
    &::after {
      background-color: #fff !important;
    }
  }
}
.ui-button {
  width: 100%;
  height: 1rem;
  background-color: rgb(251, 55, 91);
  text-align: center;
  line-height: 1rem;
  font-size: 0.32rem;
  color: #fff;
}
</style>
