import axios from 'axios'
import {base} from './api'
import Qs from 'qs'

//未签收列表
export const getWaitSignList = params => { return axios.get(`${base}/h5/invoice/waitSignList/`+params.pageNo, {params:{}}).then(res => res.data); };

//已签收列表
export const getSignList = params => { return axios.get(`${base}/h5/invoice/signList/`+params.pageNo, {params:{}}).then(res => res.data); };

//发票详情
export const getInvoiceDetail = params => { return axios.get(`${base}/h5/invoice/detail/`+params.invoiceId, {params:{}}).then(res => res.data); };

//发票签收
export const getInvoiceSign= params => { return axios.post(`${base}/h5/invoice/sign`, Qs.stringify(params)).then(res => res.data); };



