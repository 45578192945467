<template>
    <div class="approval-wrap invoice-detail-wrap">
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                发票信息
            </div>
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">发票类型：</div>
                    <div class="fl right">{{detailData.invoiceTypeName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">发票号码：</div>
                    <div class="fl right">{{detailData.receiptNo}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">发票金额：</div>
                    <div class="fl right">￥{{detailData.receiptMoney}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">收票公司：</div>
                    <div class="fl right">{{detailData.disCompanyName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">开户银行：</div>
                    <div class="fl right">{{detailData.invoiceBankName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">银行账号：</div>
                    <div class="fl right">{{detailData.invoiceBankNo}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">税务编码：</div>
                    <div class="fl right">{{detailData.invoiceDutyNo}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">联系电话：</div>
                    <div class="fl right">{{detailData.invoicePhone}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">联系地址：</div>
                    <div class="fl right">{{detailData.invoiceAddress}}</div>
                </div>
            </div>  
        </div>
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                配送信息
            </div>
             <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">收票人：</div>
                    <div class="fl right">{{detailData.receiveName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">送票人：</div>
                    <div class="fl right">{{detailData.sendName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">配送方式：</div>
                    <div class="fl right">{{detailData.sendTypeName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">开票时间：</div>
                    <div class="fl right">{{detailData.receiptDate|dateFormat1}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">发票备注：</div>
                    <div class="fl right">{{detailData.receiptRemark}}</div>
                </div>
            </div> 
        </div>
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                签收信息
            </div>
            <div class="ui-img-wrap imgWrap" v-if="!detailData.signTime">
                <p class="title jImgTile">签收照片（最多上传6张照片）</p>
                <ul class="img-ul clear">       
                    <li class='fl' v-for="(list,index) in imgArr" :key="index">
                        <div class='img'>
                            <img class='jMaxImg big-img'  :src='list' @click="handleImg(index)">
                            <img class="icon-del jIconDel" src="../../common/images/icon_del.png" alt="" @click="handleDelImg(index)">
                           <!-- <i class='icon-del jIconDel'></i> -->
                        </div>  
                    </li>                                                    
                    <li class="fl last jLast" @click="handleUpdateImg" v-show="imgArr.length<6">
                        <div class="img">
                            <i class="line line1"></i>
                            <i class="line line2"></i>
                        </div>
                    </li>
                
                </ul>
                <div class="swiperImg"></div>
                <div class="ui-button"  @click="handleSign">签收</div>
                <!-- <div class="sign-btn-box">
                    <span class="sign-btn" @click="handleSign">签收</span>
                </div> -->
            </div>  
            <div class="cnt" v-else>
                <div class="clear cnt-item">
                    <div class="fl left">签收时间：</div>
                    <div class="fl right">{{detailData.signTime|dateFormat}}</div>
                </div>
                <div class="cnt-item">
                    <div>签收照片：</div>
                    <div class="img-box ui-img-wrap">
                        <ul class="img-ul clear">
                            <li class="fl" v-for="(list,index) in (receiveImgs?receiveImgs:[])" :key="index">
                                <div class='img'>
                                    <img class='jMaxImg big-img'  :src='list' @click="handleImg(index,1)">
                                </div>
                             </li>
                        </ul>
                    </div>
                </div>
            </div>  
        </div>
        <cover-img ref="applyImg" :imgArr="imgArr"></cover-img>
    </div>  
</template>
<script>
    import utils from '../../common/js/utils'
    import adjust from '../../common/js/adjust'
    import {getInvoiceDetail,getInvoiceSign} from '../../api/invoice'
    import {formatDate} from '../../common/js/dateformat'
    import coverImg from '../../components/coverImg'


    export default {
        components: {
            coverImg
        },
        data() {
            return {
                receiveImgs:'',
                invoiceId:'',
                detailData:{},
                viewImgSwiper:false,
                // imgArr:["http://static.csatqc.com/rescue/images/ios_webview181215184316.jpg","http://static.csatqc.com/rescue/images/ios_webview181215184316.jpg","http://static.csatqc.com/rescue/images/ios_webview181215184316.jpg","http://static.csatqc.com/rescue/images/ios_webview181215184316.jpg","http://static.csatqc.com/rescue/images/ios_webview181215184316.jpg"],
                imgArr:[],
                isDel:false,
                totleImg:6,
                iosVaild : utils.versions.ios,
                androidVaild : utils.versions.android,
                btnClick:true,
            }
        },
        filters: {
            dateFormat(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd hh:mm');
                } 
            },
            dateFormat1(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd');
                }
                
            }
        },
        methods: {
            //从app获取到图片
            toImgValue(rst){
                var _this = this;
                var arr = rst?rst.split(","):'';
                if(!arr)return false;
                if(arr.length<=0)return false;
                //imgFlag = imgArr.length;//用来标记上次传了几张图片
                arr.forEach(function(v,i){
                     _this.imgArr.push(v);
                   
                })
                //_this.lightImgBox();
            },
            //app调用toImgValue方法
            appImgUrl(){
                var _this = this;
                if(this.iosVaild){
                    utils.setupWebViewJavascriptBridge(function(bridge) {
                        bridge.registerHandler('toImgValue',function(response,call){
                            _this.toImgValue(response);
                        })          
                    })      
                        
                }else if(this.androidVaild){
                    window.toImgValue = function(str){
                        _this.toImgValue(str);
                    }
                }
            },
            handleSign(){
                var _this = this;
                var data = {
                    images: _this.imgArr.length>0?_this.imgArr.join(','):'',
                    invoiceUid: _this.invoiceId,
                }
                let para = Object.assign({}, data);
                if(_this.btnClick){
                    _this.btnClick = false;
                    getInvoiceSign(para).then(res => {
                        if(res&&res.resCode==0){
                            if(res.data&&res.data.errcode==0){
                                _this.$toast('签收成功');
                                _this.getDetailFun();
                                // setTimeout(function(){
                                //     _this.$router.push({path: '/accident/list'});
                                // },2000)
                            }else{
                                this.$toast(res.data.errmsg||'网络错误，请重试！');
                                _this.btnClick = true;
                            }
                        }else{
                            this.$toast(res.resMsg||'网络错误，请重试！');
                            _this.btnClick = true;
                        }
                    
                    }).catch(res => {
                        _this.btnClick = true;
                    });
                }
                
            },
            //删除图片
            handleDelImg(index){
                this.imgArr.splice(index,1);
            },
            //点击加号上传图片
            handleUpdateImg(){
                var len = this.totleImg-this.imgArr.length;
                adjust.goAppUploader(len);

            },
             //图片放大
            handleImg(index,type){
                if(this.detailData.signTime){
                    this.imgArr = this.receiveImgs;
                }
                this.$refs.applyImg.viewImgSwiper = true;
                if(this.$refs.applyImg.swiperImgBox){
                    this.$refs.applyImg.swiperImgBox.slideTo(index, 50, false);
                }
            },
            getDetailFun(){
                let para = {
                    invoiceId:this.invoiceId
                }
                getInvoiceDetail(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.detailData = data;
                            if(this.detailData.receiveImgs){
                                this.receiveImgs = this.detailData.receiveImgs.split(',');
                            }

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
        },

        mounted() {
            var _this = this;
            this.appImgUrl();
            // this.toImgValue("http://static.csatqc.com/rescue/images/ios_webview181215184316.jpg");
            this.invoiceId = this.$route.params.invoiceId||'';
            adjust.getTokenInfo(function(){
                _this.getDetailFun();
            });
        },
        beforeRouteLeave(to, from, next) {
            if(this.isDel){
                //to.meta.keepAlive = false;
                to.meta.isUpdate = true;  
            }else{
                to.meta.isUpdate = false; 
                
            }
            next();
        }
    }
</script>
<style scoped lang="scss">
    @import "../../styles/common";
    .info-title{
        position:relative;
        line-height:0.4rem;
        padding-left:0.18rem;
        font-size:0.32rem;
        color:rgb(51,51,51);
        margin-bottom:0.32rem;
        .line-icon{
            position:absolute;
            left:0rem;
            top:0rem;
            width:0.08rem;
            height:0.4rem;
            background-color:rgb(38,41,56);
            @include border-radius(0.2rem);
        }
    }
    .approval-wrap{
        background-color:#F5F4F3;
        width:100%;
        .apply-info{
            padding:0.32rem;
            @include box-sizing(border-box);
            background-color:#fff;
            margin-bottom:0.16rem;
            .cnt{
                font-size:0.3rem;
                color:rgb(51,51,51);
                .left,.right{
                    width:50%;
                }
                .cnt-item{
                    line-height:0.6rem;
                }
                .time-box,.detail-box{
                    .left{
                        width:32%;
                    }
                    .right{
                        width:68%;
                    }
                }
                .detail-box{
                    .left{
                        width:25%;
                    }
                    .right{
                        width:75%;
                    }
                }
                

            }

        }
        .send-info{
            .cnt{
                .left{
                    width:25%;
                }
                .right{
                    width:75%;
                }
            }

        }
        .send-info{
            padding:0.32rem;
            @include box-sizing(border-box);
            background-color:#fff;
        }

        .form-box{
            border-bottom:1px solid #EEEBE9;
            background-color:#fff;
            height:1rem;
            line-height:0.95rem;
            padding:0 0.30rem;
            label{
                width:30%;
                font-size:0.28rem;
                color:rgb(51,51,51);
            }
            input,.right-select{
                width:70%;
                border: 0 none;
                line-height:0.95rem;
                height:0.95rem;
                text-align:right;
                font-size:0.28rem;
                &.active{
                    color:rgb(51,51,51);
                }
            }
            .right-select{
                color:rgb(153,153,153);
            }
        }
        .textarea-box{
            height:auto;
            margin-bottom:0.1rem;
            textarea{
                display:block;
                border:0 none;
                width:100%;
            }
        }
        
    }
    .area_ctrl{
        background-color:#fff!important;
        .area_btn_box{
            background-color:#fff!important;
            &::before,&::after{
                background-color:#fff!important;
            }
        }
    }
    .ui-button{
        width:100%;
        height:1rem;
        background-color:rgb(251,55,91);
        text-align:center;
        line-height:1rem;
        font-size:0.32rem;
        color:#fff;
        cursor:pointer;
        .btn{
            width:50%;
            &.btn1{
                background-color:rgb(30,135,242)
            }
        }
    }
    .invoice-detail-wrap{
        padding-bottom:1.1rem;
        .ui-img-wrap{
            padding:0;
        }
    }
       
    
</style>



