<template>
  <div id="app">
    <keep-alive>    
        <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang="scss">
  @import "common/css/reset.css";
  @import "common/css/common.css";
</style>
