import axios from 'axios'
import {base} from './api'
import Qs from 'qs'


//我的维修列表
export var getMyRepaire = params => { return axios.get(`${base}/h5/repaire/myRepaire/`+params.pageNo, {params:{}}).then(res => res.data); };

//待审批
export var getWaitAudit = params => { return axios.get(`${base}/h5/repaire/waitAudit/`+params.pageNo, {params:{}}).then(res => res.data); };

//已审批
export var getAuditRepaire = params => { return axios.get(`${base}/h5/repaire/auditRepaire/`+params.pageNo, {params:{}}).then(res => res.data); };

//详情
export var getRepaireDetail = params => { return axios.get(`${base}/h5/repaire/detail/`+params.repaireId, {params:{}}).then(res => res.data); };

//审批
export var getRepaireApprove = params => { return axios.post(`${base}/h5/repaire/approve`, Qs.stringify(params)).then(res => res.data) };

//撤销
export var getRepaireDelete = params => { return axios.get(`${base}/h5/repaire/delete/`+params.repaireId, {params:{}}).then(res => res.data); };

//送车
export var getRepaireSendCar = params => { return axios.post(`${base}/h5/repaire/sendCar`, Qs.stringify(params)).then(res => res.data); };

//申请
export var getRepaireApply = params => { return axios.post(`${base}/h5/repaire/apply`, Qs.stringify(params)).then(res => res.data); };

//保存维修信息
export var getRepaireSave = params => { return axios.post(`${base}/h5/repaire/save`, Qs.stringify(params)).then(res => res.data); };

//保存维修信息
export var getRepaireGetCar = params => { return axios.post(`${base}/h5/repaire/getCar`, Qs.stringify(params)).then(res => res.data); };

//维修厂列表
export const getRepaireCompany = params => { return axios.get(`${base}/h5/repaire/getRepaireCompany`, {params:{}}).then(res => res.data); };

//维修类型
export const getRepaireTypeNew = params => { return axios.get(`${base}/h5/common/repaire`, {params:{}}).then(res => res.data); };
