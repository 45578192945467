import axios from 'axios'
import {base} from './api'
import Qs from 'qs'

//类型
export const getCommonList = params => { return axios.get(`${base}/h5/common/list/`+params.name, {params:{}}).then(res => res.data); };

//获取自己的资料
export const getMemberInfo = params => { return axios.get(`${base}/h5/member/getMemberInfo`, {params:{}}).then(res => res.data); };

//地区
export const getAreaList = params => { return axios.get(`${base}/h5/area/list`, {params:{}}).then(res => res.data); };

//获取主管资料
export const getManager = params => { return axios.get(`${base}/h5/member/getManager`, {params:{}}).then(res => res.data); };

//获取抄送人
export const getNoticeMan = params => { return axios.get(`${base}/h5/member/getNoticeMan`, {params:{}}).then(res => res.data); };