<template>
    <div class="apply-wrap">
        <form>
            <div class="form-box clear">
                <label class="fl">申请人：</label>
                <span class="fr right">{{memberName}}</span>
            </div>
            <div class="form-box clear">
                <label class="fl"><span class="label-star">*</span>车牌号：</label>
                <input class="fr" placeholder="请输入车牌号" v-model="addForm.carNo">
               <!--<span class="fr right">{{carNo}}</span>-->
            </div>
            <div class="form-box clear">
                <label class="fl"><span class="label-star">*</span>类型：</label>
                <div class="fr right-select" style="overflow:hidden;" :class="repaireTypeName?'active':''"  @click="toShow">{{repaireTypeName?repaireTypeName:'请选择>'}}</div>
            </div>
             <div class="form-box clear">
                <label class="fl"><span class="label-star">*</span>时间：</label>
                <div class="fr right-select" :class="addForm.repaireDate?'active':''" @click="toShowBeginTime">{{addForm.repaireDate|dateFormat}}</div>
            </div>
            <div class="form-box clear">
                <label class="fl">审批人：</label>
                <span class="fr right">{{managerName}}</span>
            </div>
            <div class="form-box clear">
                <label class="fl">抄送人：</label>
                <span class="fr right">{{noticeMan}}</span>
            </div>
            <div class="form-box textarea-box">
                <label><span class="label-star top-star">*</span>说明：</label>
                <textarea name="" id="" rows="5"  placeholder="请输入内容" v-model="addForm.applyContent"></textarea>
            </div>
            <div class="ui-img-wrap imgWrap">
                <p class="title jImgTile">故障图片（最多上传6张照片）</p>
                <ul class="img-ul clear">       
                    <li class='fl' v-for="(list,index) in imgArr" :key="index">
                        <div class='img'>
                            <img class='jMaxImg big-img'  :src='list' @click="handleImg(index)">
                            <img class="icon-del jIconDel" src="../../common/images/icon_del.png" alt="" @click="handleDelImg(index)">
                           <!-- //<i class='icon-del jIconDel'></i> -->
                        </div>  
                    </li>                                                    
                    <li class="fl last jLast" @click="handleUpdateImg" v-show="imgArr.length<6">
                        <div class="img">
                            <i class="line line1"></i>
                            <i class="line line2"></i>
                        </div>
                    </li>
                
                </ul>
                <div class="swiperImg"></div>
            </div>
        </form>
        <div class="ui-button" @click="handleApply" :style="showStatic?staticStyle:fixStyle">提交申请</div>
        <div class="cover-mod jCoverImg" v-show="viewImgSwiper" @click="closeImg">
            <ul class="items swiper-wrapper">
                <li class="swiper-slide" v-for="(list,index) in imgArr" :key="index"><img class="small" :src="list"></li>
            </ul>
            <div class="swiper-pagination"></div>
        </div>
        <!--<awesome-picker
            ref="typePicker"
            :data="typeList"
            :colorConfirm="picker.colorConfirm"
            @confirm="confirmFn">
        </awesome-picker>-->
        <mt-datetime-picker
            ref="beginPicker"
            type="datetime"
            year-format="{value}"
            month-format="{value}"
            date-format="{value}"
            hour-format="{value}"
            minute-format="{value}"
            v-model="pickerValue"
            @confirm="handleBeginTime">
        </mt-datetime-picker>
        <!--<mt-actionsheet
            :actions="typeList"
            v-model="typeVisible">
        </mt-actionsheet>-->
        <div class="cover-level" v-if="typeVisible">
            <div class="cover-box">
                <div class="cover-box-title clear">
                    <span class="left fl" @click="cancleType">取消</span>
                    <span class="right fr" @click="certType">确定</span>
                </div>
                <div class="cover-box-content">
                    <more-select ref="moreSelectRef" :options="selectTypeList"></more-select>
                    <!-- <mt-checklist
                        v-model="repaireArr"
                        :options="typeList"
                        align="right">
                    </mt-checklist> -->
                </div>
            </div>

        </div>

    </div>  
</template>
<script>
    import utils from '../../common/js/utils';
    import adjust from '../../common/js/adjust';
    import Swiper from 'swiper'
    import MoreSelect from './components/moreSelect'
    import 'swiper/dist/css/swiper.min.css';
    import {formatDate} from '../../common/js/dateformat'
    import {getRepaireApply,getRepaireTypeNew} from '../../api/maintenance'
    import {getCommonList,getMemberInfo,getManager,getNoticeMan} from '../../api/common'
    


    export default {
        components: {
            MoreSelect
        },
        data() {
            return {
                memberName:'',
                noticeMan:'',
                carNo:'',
                managerName:'',
                defaultDate:null,
                isDateTimePickerShow:true,
                value:'',
                typeDefaultData: [
                ],
                typeList:[],
                selectTypeList:[],
                pickerValue:new Date(),
                repaireTypeName:'',
                typeVisible:false,
                repaireArr:[],
                addForm:{
                    repaireType:'',
                    carNo:'',
                    //repaireParts:'',
                    repaireDate:'',
                    // applyImages:'http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111328219571.jpg',
                    applyImages:'',
                    applyContent:''

                },
                picker:{
                    colorConfirm:'rgb(30,135,242)',
                },
                imgArr:[],
                totleImg:6,
                swiperImgBox:null,
                viewImgSwiper:false,
                type:'',
                winHeight: document.body.clientHeight,
                showStatic:false,
                fixStyle:'position:fixed',
                staticStyle:"position:static",
                iosVaild : utils.versions.ios,
                androidVaild : utils.versions.android,
                btnClick:true,

            }
        },
        filters: {
            dateFormat(time) {
                if(time&&time!='请选择>'){
                    return time;
                }else{
                    return '请选择>'
                }

                
            }
        },
        methods: {
            //类型取消
            cancleType(){
                this.typeVisible = false
            },
            certType(){
                let repaireTreeList = this.$refs.moreSelectRef.dataList || []
                let repaireTypeCodeArr = []
                let repaireTypeNameArr = []
                if(repaireTreeList&&repaireTreeList.length>0) {
                    repaireTreeList.forEach(v=>{
                        if(v.active) {
                            repaireTypeNameArr.push(v.chassisName)
                            repaireTypeCodeArr.push(v.chassisCode)
                            if(v.child&&v.child.length>0) {
                                v.child.forEach(e=>{
                                    if(e.active) {
                                        repaireTypeNameArr.push(e.chassisName)
                                        repaireTypeCodeArr.push(e.chassisCode)
                                    }
                                })
                            }
                        }
                    })
                }
                if(repaireTypeCodeArr.length<=0) {
                    this.$toast('请选择类型！');
                    return
                }
                this.selectTypeList = JSON.parse(JSON.stringify(repaireTreeList))
                this.typeVisible = false
                this.addForm.repaireType = repaireTypeCodeArr.join(',')
                this.repaireTypeName = repaireTypeNameArr.join(',')
            },
            //删除图片
            handleDelImg(index){
                this.imgArr.splice(index,1);
            },
            //图片放大
            handleImg(index){
                this.viewImgSwiper = true;
                if(this.swiperImgBox){
                    this.swiperImgBox.slideTo(index, 50, false);
                }
            },
            //关闭图片轮播
            closeImg(){
                this.viewImgSwiper = false;
            },
            //点击加号上传图片
            handleUpdateImg(){
                var len = this.totleImg-this.imgArr.length;
                adjust.goAppUploader(len);

            },
            //从app获取到图片
            toImgValue(rst){
                var _this = this;
                var arr = rst?rst.split(","):'';
                if(!arr)return false;
                if(arr.length<=0)return false;
                //imgFlag = imgArr.length;//用来标记上次传了几张图片
                arr.forEach(function(v,i){
                    _this.imgArr.push(v);
                })
                //_this.lightImgBox();
            },
            //app调用toImgValue方法
            appImgUrl(){
                var _this = this;
                if(this.iosVaild){
                    utils.setupWebViewJavascriptBridge(function(bridge) {
                        bridge.registerHandler('toImgValue',function(response,call){
                            _this.toImgValue(response);
                        })          
                    })      
                        
                }else if(this.androidVaild){
                    window.toImgValue = function(str){
                        _this.toImgValue(str);
                    }
                }
            },
             //获取抄送人
            getNoticeManInfo(){
                getNoticeMan({}).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.noticeMan = res.data.data||'';

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            //获取管理员信息
            getManagerInfo(){
                getManager({}).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.managerName = data.managerName||'';

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            //获取到自己的信息
            getMyInfo(){
                getMemberInfo({}).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.memberName = data.memberName||'';
                            this.carNo = data.carNo||'';
                            this.addForm.carNo = data.carNo||'';

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            getTypeList(){
                var _this = this;
                let para = {}
                getRepaireTypeNew(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.typeList = data || []
                            this.selectTypeList = JSON.parse(JSON.stringify(this.typeList))
                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            toShow(){
                // this.$refs.typePicker.show();
                this.typeVisible = true
            },
            confirmFn(val) {
                if(val){
                    this.repaireTypeName = val[0].value||'';
                    this.addForm.repaireType = this.typeList[val[0].index].code;
                    console.log(this.addForm.repaireType)
                }
            },
            toShowBeginTime(){
                this.$refs.beginPicker.open();
                //this.calendarShowBegin = true;
            },
            handleBeginTime(val){
                this.addForm.repaireDate = formatDate(val,'yyyy-MM-dd hh:mm:ss');
                console.log(this.addForm.repaireDate)
            },
            handleApply(){
                var _this = this
                this.checkForm(()=>{
                    let para = Object.assign({}, this.addForm);
                    para.applyImages = _this.imgArr.join(',');
                    if(_this.btnClick){
                        _this.btnClick = false;
                        getRepaireApply(para).then(res => {
                            if(res&&res.resCode==0){
                                    if(res.data&&res.data.errcode==0){
                                        this.$toast('申请成功');
                                        setTimeout(function(){
                                            if(_this.type==1){
                                               _this.$router.push({path: '/maintenance/list'});
                                             }else if(_this.type==2){
                                               _this.$router.push({path: '/maintenance/myList'});
                                             }
                                           
                                        },2000)
                                    }else{
                                        this.$toast(res.data.errmsg||'网络错误，请重试！');
                                        _this.btnClick = true;
                                    }
                            }else{
                                this.$toast(res.resMsg||'网络错误，请重试！');
                                _this.btnClick = true;
                            }
                    
                        }).catch(res => {
                            _this.btnClick = true;
                        });
                    }
                    
                })
            },
            checkForm(callback){
                if(!this.addForm.repaireType){
                    this.$toast('请选择类型');
                    return false;
                } if(!this.addForm.carNo){
                    this.$toast('请选择车牌号');
                    return false;
                }else if(!this.addForm.repaireDate){
                    this.$toast('请选择维修时间');
                    return false;
                }else{
                    callback&&callback();
                }

            }
        },
        beforeRouteLeave(to, from, next) {
            if(to.name == 'maintenanceMyList'||'maintenanceList'){
                to.meta.isUpdate = true;
            }
            next();
        },
        mounted() {
            var _this = this;
            this.type = this.$route.params.type||'';
            this.getMyInfo();
            this.getTypeList();
            this.getNoticeManInfo();
            this.getManagerInfo();//获取主管资料
            this.appImgUrl();
            this.swiperImgBox = new Swiper('.jCoverImg',{
                resistanceRatio : 0,
                pagination: {el:'.swiper-pagination'},
                paginationClickable: true,
                observer:true ,//修改swiper自己或子元素时，自动初始化swiper
                observeParents:true,//修改swiper的父元素时，自动初始化swiper
            });
            window.onresize = () => {
                if(_this.androidVaild){
                    var curHeight = document.body.clientHeight;
                    if(curHeight<_this.winHeight){
                        _this.showStatic = true;
                    }else{
                        _this.showStatic = false;
                    }
               }
            }
          

        }
    }
</script>
<style lang="scss">
    @import "../../styles/common";
    @import "../../styles/coverImg";
    .area_ctrl{
        background-color:#fff!important;
        .area_btn_box{
            background-color:#fff!important;
            &::before,&::after{
                background-color:#fff!important;
            }
        }
    }
    
    
    
</style>
<style lang="scss" scoped>
    .cover-level{
        width:100%;
        height:100%;
        background:rgba(0,0,0,.6);
        position:fixed;
        left:0;
        top:0;
        z-index:999;
        .cover-box{
            width:100%;
            height:100%;
            background-color:#fff;
            position:fixed;
            bottom:0;
            left:0;
            overflow:auto;
            box-sizing:border-box;
            padding-top:0.8rem;
            box-sizing: border-box;
            .cover-box-title{
                font-size:0.28rem;
                height:0.8rem;
                line-height:0.8rem;
                border-bottom: solid 0.013333rem #eaeaea;
                cursor:pointer;
                position: fixed;
                left:0;
                top:0;
                width:100%;
                background:#fff;
                z-index: 1;
                .left{
                    color:#999;
                    padding:0 0.24rem;
                }
                .right{
                    color:#1e87f2;
                    padding:0 0.24rem;
                }
            }
            .cover-box-content{
                // height:6rem;
                overflow:auto;
            }
        }
    }
</style>



