<template>
    <div class="approval-wrap">
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                事故信息
            </div>
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">申报人员：</div>
                    <div class="fl right">{{detailData.createUserName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">车牌号码：</div>
                    <div class="fl right">{{detailData.plateNum}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">事故类型：</div>
                    <div class="fl right">{{detailData.accidentTypeName}}</div>
                </div>
                <div class="cnt-item clear">
                    <div class="fl left">事故时间：</div><div class="fl right">{{detailData.accidentTime|dateFormat}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">通知领导：</div>
                    <div class="fl right">{{detailData.chiefDriverName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">事故地点：</div>
                    <div class="fl right">{{detailData.provinceName}}-{{detailData.cityName}}-{{detailData.areaName}}-{{detailData.detailAddress}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">事故描述：</div>
                    <div class="fl right">{{detailData.describe}}</div>
                </div>
            </div>  
        </div>
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                第三方信息
            </div>
            <div class="cnt">
                <ul class="third-ul">
                    <li v-for="(list,index) in detailData.thirdList" :key="index" >
                        <div class="clear">
                            <span class="fl left">车牌：{{list.thirdCarNo}}</span>
                            <span class="fl right">车架：{{list.thirdCarVin}}</span>
                        </div>
                        <div class="clear">
                            <span class="fl left">姓名：{{list.thirdDriver}}</span>
                            <span class="fl right">电话：{{list.thirdDriverPhone}}</span>
                        </div>
                        <div class="clear">
                            <span class="fl left">定损费用：{{list.claimMoney/100}}元</span>
                            <span class="fl right">维修费用：{{list.repaireMoney/100}}元</span>
                        </div>
                    </li>
                </ul>
                <!-- <div class="clear cnt-item">
                    <div class="fl left">车牌：</div>
                    <div class="fl right">{{detailData.thirdCarNo}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">车架：</div>
                    <div class="fl right">{{detailData.thirdCarVin}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">姓名：</div>
                    <div class="fl right">{{detailData.thirdDriver}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">电话：</div>
                    <div class="fl right">{{detailData.thirdDriverPhone}}</div>
                </div> -->
                <!-- <div class="img-box">
                    <ul class="img-ul clear">
                         <li class="fl" v-for="list in (detailData.applyImages?detailData.applyImages.split(';'):[])"><img :src="list"></li>
                    </ul>
                </div> -->
            </div>  
        </div>
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                事故评估
            </div>
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">责任划分：</div>
                    <div class="fl right">{{detailData.blame}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">事故损失：</div>
                    <div class="fl right">{{detailData.accidentLose}}</div>
                </div>
                <div class="cnt-item">
                    <div>认定报告：</div>
                    <div class="img-box ui-img-wrap">
                        <ul class="img-ul clear">
                            <li class="fl" v-for="(list,index) in (blameReport?blameReport:[])" :key="index">
                                <div class='img'>
                                    <img class='jMaxImg big-img'  :src='list' @click="handleImg(index,1)">
                                </div>
                             </li>
                        </ul>
                    </div>
                </div>
            </div>  
        </div>
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                人员伤亡情况
            </div>
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">人员伤亡：</div>
                    <div class="fl right" v-if="detailData.isCasualtie=='1'">有人员伤亡</div>
                    <div class="fl right" v-else-if="detailData.isCasualtie=='0'">无人员伤亡</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">伤亡情况：</div>
                    <div class="fl right">{{detailData.casualtieDesc}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">送往医院：</div>
                    <div class="fl right">{{detailData.serviceHospital}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">医疗费用：</div>
                    <div class="fl right">{{detailData.medicalExpense/100}}元</div>
                </div>
                <div class="cnt-item">
                    <div>事故照片：</div>
                    <div class="img-box ui-img-wrap">
                        <ul class="img-ul clear">
                            <li class="fl" v-for="(list,index) in (accidentScence?accidentScence:[])" :key="index">
                                <div class='img'>
                                    <img class='jMaxImg big-img'  :src='list' @click="handleImg(index,4)">
                                </div>
                             </li>
                        </ul>
                    </div>
                </div>
            </div>  
        </div>
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                保险公司评估
            </div>
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">保险定损：</div>
                    <div class="fl right">{{detailData.fixLose}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">保险理赔：</div>
                    <div class="fl right">{{detailData.claimMoney/100}}</div>
                </div>
                <div class="cnt-item">
                    <div>勘测报告：</div>
                    <div class="img-box ui-img-wrap">
                        <ul class="img-ul clear">
                            <li class="fl" v-for="(list,index) in (surveyReport?surveyReport:[])" :key="index">
                                <div class='img'>
                                    <img class='jMaxImg big-img'  :src='list' @click="handleImg(index,2)">
                                </div>
                             </li>
                        </ul>
                    </div>
                </div>
            </div>  
        </div>
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                车辆维修评估
            </div>
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">车辆修理：</div>
                    <div class="fl right" v-if="detailData.needRepaire='1'">需要修理</div>
                    <div class="fl right" v-else="detailData.needRepaire='0'">不需要修理</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">修理厂商：</div>
                    <div class="fl right">{{detailData.repaireCompany}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">维修费用：</div>
                    <div class="fl right">{{detailData.repaireMoney/100}}</div>
                </div>
                <div class="cnt-item">
                    <div>维修发票：</div>
                    <div class="img-box ui-img-wrap">
                        <ul class="img-ul clear">
                            <li class="fl" v-for="(list,index) in (repaireReport?repaireReport:[])" :key="index">
                                <div class='img'>
                                    <img class='jMaxImg big-img'  :src='list' @click="handleImg(index,3)">
                                </div>
                             </li>
                        </ul>
                    </div>
                </div>
            </div>  
        </div>
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                领导事故处理意见
            </div>
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">车队长：</div>
                    <div class="fl right">{{detailData.chiefDriverMsg}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">总经理：</div>
                    <div class="fl right">{{detailData.ceoMsg}}</div>
                </div>
            </div>  
        </div>
        <cover-img ref="applyImg" :imgArr="imgArr"></cover-img>
    </div>  
</template>
<script>
    import adjust from '../../common/js/adjust';
    import {getAccidentDetail} from '../../api/accident'
    import {formatDate} from '../../common/js/dateformat'
    import coverImg from '../../components/coverImg'


    export default {
        components: {
            coverImg
        },
        data() {
            return {
                accidentId:'',
                detailData:{},
                blameReport:'',
                surveyReport:'',
                repaireReport:'',
                accidentScence:'',
                viewImgSwiper:false,
                imgArr:[],
                isDel:false,
            }
        },
        filters: {
            dateFormat(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd hh:mm');
                }
               
            }
        },
        methods: {
             //图片放大
            handleImg(index,type){
                if(type==1){
                    this.imgArr = this.blameReport;
                }else if(type==2){
                    this.imgArr = this.surveyReport;
                }else if(type==3){
                    this.imgArr = this.repaireReport;
                }else if(type==3){
                    this.imgArr = this.accidentScence;
                }else{
                    this.imgArr = [];
                }
                this.$refs.applyImg.viewImgSwiper = true;
                if(this.$refs.applyImg.swiperImgBox){
                    this.$refs.applyImg.swiperImgBox.slideTo(index, 50, false);
                }
            },			
            getDetailFun(){
                let para = {
                    accidentId:this.accidentId
                }
                getAccidentDetail(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.detailData = data;
                            if(this.detailData.blameReport){
                                this.blameReport = this.detailData.blameReport.split(',');
                            }
                            if(this.detailData.surveyReport){
                                this.surveyReport = this.detailData.surveyReport.split(',');
                            }
                            if(this.detailData.repaireReport){
                                this.repaireReport = this.detailData.repaireReport.split(',');
                            }
                            if(this.detailData.accidentScence){
                                this.accidentScence = this.detailData.accidentScence.split(',');
                            }

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
        },

        mounted() {
       	 	var _this = this;
            this.accidentId = this.$route.params.accidentId||'';
           	adjust.getTokenInfo(function(){
                _this.getDetailFun();
            });
        },
        beforeRouteLeave(to, from, next) {
            if(this.isDel){
                //to.meta.keepAlive = false;
                to.meta.isUpdate = true;  
            }else{
                to.meta.isUpdate = false; 
                
            }
            next();
        }
    }
</script>
<style scoped lang="scss">
    @import "../../styles/common";
    .info-title{
        position:relative;
        line-height:0.4rem;
        padding-left:0.18rem;
        font-size:0.32rem;
        color:rgb(51,51,51);
        margin-bottom:0.32rem;
        .line-icon{
            position:absolute;
            left:0rem;
            top:0rem;
            width:0.08rem;
            height:0.4rem;
            background-color:rgb(38,41,56);
            @include border-radius(0.2rem);
        }
    }
    .approval-wrap{
        background-color:#F5F4F3;
        width:100%;
        .apply-info{
            padding:0.32rem;
            @include box-sizing(border-box);
            background-color:#fff;
            margin-bottom:0.16rem;
            .cnt{
                font-size:0.3rem;
                color:rgb(51,51,51);
                .left,.right{
                    width:50%;
                }
                .third-ul{
                    li{
                        margin-bottom:0.4rem;
                        div{
                            line-height:0.5rem;
                        }
                        .left,.right{
                            width:50%;
                        }
                    }
                }
                .cnt-item{
                    line-height:0.6rem;
                }
                .time-box,.detail-box{
                    .left{
                        width:32%;
                    }
                    .right{
                        width:68%;
                    }
                }
                .detail-box{
                    .left{
                        width:25%;
                    }
                    .right{
                        width:75%;
                    }
                }
                

            }

        }
        .send-info{
            .cnt{
                .left{
                    width:25%;
                }
                .right{
                    width:75%;
                }
            }

        }
        .send-info{
            padding:0.32rem;
            @include box-sizing(border-box);
            background-color:#fff;
        }

        .form-box{
            border-bottom:1px solid #EEEBE9;
            background-color:#fff;
            height:1rem;
            line-height:0.95rem;
            padding:0 0.30rem;
            label{
                width:30%;
                font-size:0.28rem;
                color:rgb(51,51,51);
            }
            input,.right-select{
                width:70%;
                border: 0 none;
                line-height:0.95rem;
                height:0.95rem;
                text-align:right;
                font-size:0.28rem;
                &.active{
                    color:rgb(51,51,51);
                }
            }
            .right-select{
                color:rgb(153,153,153);
            }
        }
        .textarea-box{
            height:auto;
            margin-bottom:0.1rem;
            textarea{
                display:block;
                border:0 none;
                width:100%;
            }
        }
        
    }
    .area_ctrl{
        background-color:#fff!important;
        .area_btn_box{
            background-color:#fff!important;
            &::before,&::after{
                background-color:#fff!important;
            }
        }
    }
    .ui-button{
        width:100%;
        height:1rem;
        background-color:rgb(251,55,91);
        text-align:center;
        line-height:1rem;
        font-size:0.32rem;
        color:#fff;
        cursor:pointer;
        .btn{
            width:50%;
            &.btn1{
                background-color:rgb(30,135,242)
            }
        }
    }
       
    
</style>



