<template>
  <div class="approval-wrap leave-approval-wrap">
    <div class="apply-info">
      <div class="info-title">
        <span class="line-icon"></span>
        申请信息
      </div>
      <div class="cnt">
        <div class="clear cnt-item">
          <div class="fl left">请假人：</div>
          <div class="fl right">{{detailData.applyName}}</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">请假类型：</div>
          <div class="fl right">{{detailData.leaveTypeName}}</div>
        </div>
        <div class="cnt-item clear">
          <div class="fl left">开始时间：</div>
          <div class="fl right">{{detailData.startTime|dateFormat}}</div>
        </div>
        <div class="cnt-item clear">
          <div class="fl left">结束时间：</div>
          <div class="fl right">{{detailData.endTime|dateFormat}}</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">时长(小时)：</div>
          <div class="fl right">{{detailData.leaveLong}}h</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">请假天数：</div>
          <div class="fl right">{{detailData.leaveDays}}天</div>
        </div>
        <div class="cnt-item clear detail-box">
          <div class="fl left">请假事由：</div>
          <div class="fl right">{{detailData.reason}}</div>
        </div>
        <div class="cnt-item clear">
          <div class="fl left">申请时间：</div>
          <div class="fl right">{{detailData.createTime|dateFormat}}</div>
        </div>
      </div>
    </div>
    <div class="advice-info">
      <div class="info-title">
        <span class="line-icon"></span>
        审批信息
      </div>
      <form>
        <div class="form-box clear">
          <label class="fl">
            <span class="label-star">*</span>批假人：
          </label>
          <span class="fr right">{{managerName}}</span>
        </div>
        <div class="form-box textarea-box">
          <label>
            <span class="label-star top-star">*</span>
            <span>审批意见：</span>
          </label>
          <textarea name id rows="3" placeholder="请输入内容" v-model="addForm.approveContent"></textarea>
        </div>
      </form>
    </div>
    <div class="table-data-box" v-if="detailData.leaveList&&detailData.leaveList.length>0">
      <div class="table-data">
        <ul class="flex tr">
          <li class="th line1">请假时间</li>
          <li class="th line2">区域</li>
          <li class="th line3">驾驶员</li>
          <li class="th line4">请假天数</li>
        </ul>
        <ul class="flex dr" v-for="(item,index) in detailData.leaveList||[]" :key="index">
          <li class="td line1 flex" style="flex-direction:column">
            <span class="lineh5">{{item.startTime}}</span>
            <!-- <span style="text-align:center;">至</span> -->
            <span class="lineh5">{{item.endTime}}</span>
          </li>
          <li class="td line2">{{item.areaName}}</li>
          <li class="td line3">{{item.realName}}</li>
          <li class="td line4">{{item.leaveDays}}</li>
        </ul>
      </div>
    </div>
    <div class="advice-info" style="padding: 0.32rem 0">
      <div class="info-title" style="padding-left:0.5rem">
        <span class="line-icon" style="left:0.32rem"></span>
        已批假列表
      </div>
      <div class="table-data-box">
        <div class="table-data">
          <ul class="flex tr">
            <li class="th line1">请假时间</li>
            <li class="th line2">区域</li>
            <li class="th line3">驾驶员</li>
            <li class="th line4">请假天数</li>
          </ul>
          <ul class="flex dr" v-for="(item,index) in leavePersonData" :key="index">
            <li class="td line1 flex" style="flex-direction:column">
              <span class="lineh5">{{item.startTime}}</span>
              <!-- <span style="text-align:center;">至</span> -->
              <span class="lineh5">{{item.endTime}}</span>
            </li>
            <li class="td line2">{{item.areaName}}</li>
            <li class="td line3">{{item.realName}}</li>
            <li class="td line4">{{item.leaveDays}}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ui-button clear" :style="showStatic?staticStyle:fixStyle">
      <div class="btn btn1 fl" @click="handleAgree('2')">不同意</div>
      <div class="btn fl" @click="handleAgree('1')">同意</div>
      <div class="btn fl" @click="handleAgree('3')">转批</div>
    </div>
  </div>
</template>
<script>
// import mui from '../../common/js/plug/mui/mui.min.js'
// import muipicker from '../../common/js/plug/mui/mui.picker.js'
// import muipoppicker from '../../common/js/plug/mui/mui.poppicker.js'
import adjust from '../../common/js/adjust'
import utils from '../../common/js/utils'
import { formatDate } from '../../common/js/dateformat'
import { getLeaveDetail, getLeaveApprove, getApproveSinglePersons } from '../../api/leave'
import { getManager } from '../../api/common'

export default {
  components: {},
  data () {
    return {
      repaireId: '',
      detailData: {},
      addForm: {
        status: '',
        approveContent: ''
      },
      managerName: '',
      type: '',
      iosVaild: utils.versions.ios,
      androidVaild: utils.versions.android,
      winHeight: document.body.clientHeight,
      showStatic: false,
      fixStyle: 'position:fixed',
      staticStyle: 'position:static',
      isApproval: false,
      btnClick: true,
      leavePersonData: []
    }
  },
  filters: {
    dateFormat (time) {
      var date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    }
  },
  methods: {
    // 获取管理员信息
    getManagerInfo () {
      getManager({})
        .then(res => {
          if (res && res.resCode == 0) {
            if (res.data && res.data.errcode == 0 && res.data.data) {
              var data = res.data.data
              this.managerName = data.managerName || ''
            } else {
              this.$toast(res.data.errmsg || '网络错误，请重试！')
            }
          } else {
            this.$toast(res.resMsg || '网络错误，请重试！')
          }
        })
        .catch(res => {})
    },
    getDetailFun () {
      let para = {
        leaveId: this.leaveId,
        approve: 1
      }
      getLeaveDetail(para)
        .then(res => {
          if (res && res.resCode == 0) {
            if (res.data && res.data.errcode == 0 && res.data.data) {
              var data = res.data.data
              this.detailData = data
            } else {
              this.$toast(res.data.errmsg || '网络错误，请重试！')
            }
          } else {
            this.$toast(res.resMsg || '网络错误，请重试！')
          }
        })
        .catch(res => {})
    },
    handleAgree (status) {
      var _this = this
      this.checkForm(() => {
        let para = Object.assign({}, this.addForm)
        para.status = status
        para.leaveId = this.leaveId
        if (_this.btnClick) {
          _this.btnClick = false
          getLeaveApprove(para)
            .then(res => {
              if (res && res.resCode == 0) {
                if (res.data && res.data.errcode == 0) {
                  this.isApproval = true
                  this.$toast('审批成功')
                  setTimeout(function () {
                    if (_this.type == 1) {
                      _this.$router.push({ path: '/leave/list' })
                    } else if (_this.type == 2) {
                      _this.$router.push({ path: '/leave/myList' })
                    }
                  }, 2000)
                } else {
                  this.$toast(res.data.errmsg || '网络错误，请重试！')
                  _this.btnClick = true
                }
              } else {
                this.$toast(res.resMsg || '网络错误，请重试！')
                _this.btnClick = true
              }
            })
            .catch(res => {
              _this.btnClick = true
            })
        }
      })
    },
    checkForm (callback) {
      if (!this.addForm.approveContent) {
        this.$toast('请输入审批意见')
        return false
      } else {
        callback && callback()
      }
    },
    // 查询某个月，请假当月的已审批记录
    getLeavePersonResordFn () {
      getApproveSinglePersons({ leaveId: this.leaveId })
          .then(res => {
            if (res && res.resCode === 0) {
              if (res.data && res.data.errcode === 0) {
                this.leavePersonData = res.data.data || []
              } else {
                this.$toast(res.data.errmsg || '网络错误，请重试！')
              }
            } else {
              this.$toast(res.resMsg || '网络错误，请重试！')
            }
          })
          .catch(res => {})
    },
  },

  mounted () {
    var _this = this
    this.type = this.$route.params.type || ''
    this.leaveId = this.$route.params.leaveId || ''
    this.getManagerInfo()
    adjust.getTokenInfo(function () {
      _this.getDetailFun()
      _this.getLeavePersonResordFn()
    })
    window.onresize = () => {
      if (_this.androidVaild) {
        var curHeight = document.body.clientHeight
        if (curHeight < _this.winHeight) {
          _this.showStatic = true
        } else {
          _this.showStatic = false
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isApproval) {
      to.meta.isUpdate = true
    } else {
      to.meta.isUpdate = false
    }
    next()
  }
}
</script>
<style scoped lang="scss">
@import '../../styles/common';
.leave-approval-wrap {
  padding-bottom: 1.5rem;
  overflow: auto;
    .table-data-box{
      background:#fff;
      margin-bottom: 0.1rem;
    }
  .table-data {
    font-size: 0;
    width: 100%;
    .line1 {
      width: 46%;
      padding-left: 0.2rem;
      box-sizing: border-box;
    }
    .line2,
    .line3 {
      width: 17%;
    }
    .line4 {
      width: 20%;
      padding-right: 0.2rem;
      box-sizing: border-box;
    }
    .th,
    .td {
      overflow: hidden;
      font-size: 0.28rem;
      text-align: left;
      list-style: none;
    }
    .td {
      font-size: 0.28rem;
      height: 1rem;
      line-height: 1rem;
      word-break: break-all;
      .lineh5 {
        line-height: 0.5rem;
      }
    }
    .tr {
      list-style: none;
      background: #eee;
      line-height: 0.8rem;
    }
    .dr {
      list-style: none;
      padding: 0.1rem 0;
      box-sizing: border-box;
      &:nth-child(odd) {
        background-color: #f8f8f9;
      }
    }
  }
}
.info-title {
  position: relative;
  line-height: 0.4rem;
  padding-left: 0.18rem;
  font-size: 0.32rem;
  color: rgb(51, 51, 51);
  margin-bottom: 0.32rem;
  .line-icon {
    position: absolute;
    left: 0rem;
    top: 0rem;
    width: 0.08rem;
    height: 0.4rem;
    background-color: rgb(38, 41, 56);
    @include border-radius(0.2rem);
  }
}
.approval-wrap {
  background-color: #f5f4f3;
  width: 100%;
  .apply-info {
    padding: 0.32rem;
    @include box-sizing(border-box);
    background-color: #fff;
    margin-bottom: 0.16rem;
    .cnt {
      font-size: 0.3rem;
      color: rgb(51, 51, 51);
      .left {
        width: 25%;
      }
      .right {
        width: 75%;
      }
      .cnt-item {
        line-height: 0.6rem;
      }
      .tool-item {
        span {
          margin-right: 0.1rem;
        }
      }
      .time-box,
      .detail-box {
        .left {
          width: 32%;
        }
        .right {
          width: 68%;
        }
      }
      .detail-box {
        .left {
          width: 25%;
        }
        .right {
          width: 75%;
        }
      }
    }
  }
  .advice-info {
    padding: 0.32rem;
    @include box-sizing(border-box);
    background-color: #fff;
    .right {
      font-size: 0.28rem;
      color: #333;
    }
  }

  .form-box {
    border-bottom: 1px solid #eeebe9;
    background-color: #fff;
    height: 1rem;
    line-height: 0.95rem;
    padding: 0 0.3rem;
    label {
      width: 30%;
      font-size: 0.28rem;
      color: rgb(51, 51, 51);
    }
    input,
    .right-select {
      width: 70%;
      border: 0 none;
      line-height: 0.95rem;
      height: 0.95rem;
      text-align: right;
      font-size: 0.28rem;
      &.active {
        color: rgb(51, 51, 51);
      }
    }
    .right-select {
      color: rgb(153, 153, 153);
    }
  }
  .tip-box {
    border-bottom: 0 none;
    color: red;
    line-height: 0.4rem;
    label {
      color: red;
    }
    .right {
      color: red;
    }
  }
  .textarea-box {
    height: auto;
    margin-bottom: 0.1rem;
    textarea {
      display: block;
      border: 0 none;
      width: 100%;
      font-size: 0.28rem;
    }
  }
}
.area_ctrl {
  background-color: #fff !important;
  .area_btn_box {
    background-color: #fff !important;
    &::before,
    &::after {
      background-color: #fff !important;
    }
  }
}
.ui-button {
  width: 100%;
  height: 1rem;
  background-color: rgb(251, 55, 91);
  text-align: center;
  line-height: 1rem;
  font-size: 0.32rem;
  color: #fff;
  .btn {
    width: 50%;
    &.btn1 {
      background-color: rgb(30, 135, 242);
    }
  }
}
</style>
