<template>
    <div class="case-pre-list-wrap">
        <ul class="cnt-ul" v-if="dataList.length>0">
            <li class="" v-for='(list,index) in dataList' :key="index" >
                <div>
				<div class="item1" v-html="list.msg"> 
                    
                </div>
				<div class="paidan" @click="pushCase(list.caseUid)">立即派单</div>
				</div>
            </li>
        </ul>
        <div class="mescroll-empty" v-else>
            <img class="empty-icon" src="../../common/images/icon_empty.png" alt="">
            <div class="empty-tip">暂无数据</div>
        </div>
    </div>
    
</template>
<script>
    import adjust from '../../common/js/adjust';
    import MeScroll from 'mescroll.js'
    import 'mescroll.js/mescroll.min.css'
    import {formatDate} from '../../common/js/dateformat'
    import {getCasePreList,getPushCasePre} from '../../api/casePre'
    

    export default {
        data() {
            return {
                dataList:[],
                scrollData:0,
                
            }
        },
        filters: {
            dateFormat(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd');
                }   
            }
        },
        methods: {
			pushCase(caseUid){
				let para = {
					caseUid:caseUid
				}
				getPushCasePre(para).then(res => {
                    if(res&&res.resCode==0){                        
						if(res.data&&res.data.errcode==0&&res.data.data){
						   this.$toast('派单成功！');
						}else{
						    this.$toast(res.data.errmsg||'网络错误，请重试！');
						}
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                
                }).catch(res => {
                   //this.mescroll.endErr();
                });
			},
            getList() {
                let para = {
                
                }
                getCasePreList(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){                            
                            var data = res.data.data;
                            this.dataList = this.dataList.concat(data);
                            //console.log(this.dataList)
                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                
                }).catch(res => {
                   //this.mescroll.endErr();
                });
                
            }
        },

        mounted() {
            this.scrollData = 0;
            var _this = this;
            adjust.getTokenInfo(function(){
                _this.getList()
            });
           
        },
    }
</script>
<style lang="scss">
@import "../../styles/common";
    .case-pre-list-wrap{
        background-color:#F5F4F3;
        width:100%;
        height:100%;
        position:fixed;
        left:0;
        top:0;
        overflow-y:auto;
        .cnt-ul{
        li{
            font-size:0.28rem;
            background-color:#fff;
            padding:0.3rem 0.3rem;
            margin-bottom:0.1rem;
            .item1{
                line-height:0.5rem;
                word-wrap: break-word;
            }
			.paidan{
				background-color: black;
				color: white;
				height: 0.6rem;
				line-height: 0.6rem;
				width: 80%;
				margin: 0.1rem auto;
				border-radius: 0.2rem;
				text-align: center;
			}
        }
    }
    }
    
    
</style>