import axios from 'axios'
import {base} from './api'
import Qs from 'qs'

//列表
export var getOilList = params => { return axios.get(`${base}/h5/oil/myOilList/`+params.pageNo, {params:{}}).then(res => res.data); };

//详情
export var getOilDetail = params => { return axios.get(`${base}/h5/oil/detail/`+params.oilId, {params:{}}).then(res => res.data); };

//撤销
export var getDeleteOil = params => { return axios.get(`${base}/h5/oil/deleteOil/`+params.oilId, {params:{}}).then(res => res.data); };

//添加:
export var getAddOil= params => { return axios.post(`${base}/h5/oil/addOil`, Qs.stringify(params)).then(res => res.data); };
