import axios from 'axios'
import {base} from './api'
import Qs from 'qs'

//列表
export const getAccidentList = params => { return axios.get(`${base}/h5/accident/myList/`+params.pageNo, {params:{}}).then(res => res.data); };

//详情
export const getAccidentDetail = params => { return axios.get(`${base}/h5/accident/detail/`+params.accidentId, {params:{}}).then(res => res.data); };


//添加:
export const getAddAccident= params => { return axios.post(`${base}/h5/accident/add`, Qs.stringify(params)).then(res => res.data); };




// //添加油报
// export const getRepaireAdd = params => { return axios.post(`${base}/h5/report/addReport`, Qs.stringify(params)).then(res => res.data) };