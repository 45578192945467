'use strict';
import utils from './utils';
var iosVaild = utils.versions.ios,
    androidVaild = utils.versions.android;

export default {
    //调用app上传组件,传还可以上传几张图片
    goAppUploader:function(num){
        if(iosVaild){ 
            utils.getWebViewJsBridge('toPickImageActivity',num);
        }else if(androidVaild){ 
            if(window.android && typeof(window.android.toPickImageActivity) === "function"){
                window.android.toPickImageActivity(num);
            }
        } 
    },
    
    gotoDetail:function(detailId){
    		if(iosVaild){ 
            utils.getWebViewJsBridge('gotoDetail',detailId);
        }else if(androidVaild){ 
            if(window.android && typeof(window.android.gotoDetail) === "function"){
                window.android.gotoDetail(detailId);
            }
        } 
    },
    
    
    getTokenInfo_test:function(callback){
    		localStorage.removeItem("token");
        localStorage.setItem("token","f5df5d2f3dfd4bc38a9d85de59c4788a");
        callback&&callback();
        console.log("getTokenInfo===")
    },
    getTokenInfo:function(callback){
        if(iosVaild){ 
            utils.getWebViewJsBridge('getToken','',function(response){
                if(response){
                    localStorage.removeItem("token");
                    localStorage.setItem("token",response);
                    callback&&callback();
                }
            }); 
        }else if(androidVaild){ 
            if(window.android && typeof(window.android.getToken) === "function"){
                if(window.android.getToken()){
                    localStorage.removeItem("token");
                    localStorage.setItem("token",window.android.getToken());
                    callback&&callback();
                }
            }
        } 
    },
    backUrlFun:function(callback){
        if(iosVaild){
            utils.setupWebViewJavascriptBridge(function(bridge) {
                bridge.registerHandler('backUrlFun',function(){
                    window.history.go(-1)
                });
            });
        }else if(androidVaild){
            window.backUrlFun = function(){
                 window.history.go(-1);
            };
        }
    },
//     //是否显示评论框  隐藏为1,0为显示
//     showComment:function(num){
//         var num = num?num:1;
//         //隐藏评论浮层
//         if(iosVaild){
//             utils.getWebViewJsBridge('showComment',num);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.showComment) === "function"){
//                 window.android.showComment(num);
//             }
//         }
//     },
//     //返回方法触发操作
//     backFun:function(callback){
//         if(iosVaild){
//             utils.setupWebViewJavascriptBridge(function(bridge) {
//                 //监听输入框内容
//                 bridge.registerHandler('callActionBack',function(_data){
//                     callback && callback(_data);

//                 })
//             });
//         }else if(androidVaild){
//             window.callActionBack = function(_data){
//                 callback && callback(_data);
//             }

//         }
//     },
//     //箭头返回的方法
//     backUrl:function(val){
//         if(iosVaild){
//             utils.getWebViewJsBridge('actionBack',(val || ""));
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.clickBackOnAndroid) === "function"){
//                 window.android.clickBackOnAndroid((val || ""));
//             }
//         }
//     },
//     //复制功能操作
//     copyFun:function(val,callback){
//         if(utils.versions.ios){
//             utils.getWebViewJsBridge('copyOrderFun',val);
//         }else if(utils.versions.android){
//             if(window.android && typeof(window.android.copyOrderFun) === "function"){
//                 window.android.copyOrderFun(val);
//             }
//         }
//         callback && callback();
//     },
//     //提供给app刷新页面方法
//     refreshFun:function(callback){
//         if(iosVaild){
//             utils.setupWebViewJavascriptBridge(function(bridge) {
//                 bridge.registerHandler('refreshFun',function(){
//                     callback&&callback();
//                     window.location.reload();
//                 });
//             });
//         }else if(androidVaild){
//             window.refreshFun = function(){
//                 callback&&callback();
//                 window.location.reload();
//             };
//         }
//     },
//     //左边的返回箭头的显示与隐藏，0是显示1是不显示
//     hideBack:function(num){
//         if(iosVaild){
//             utils.getWebViewJsBridge('visibleBack',num);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.visibleBack) === "function"){
//                 window.android.visibleBack(num);
//             }
//         }
//     },
//     //banner按钮跳app页面的方法（内部跳转）||调用方法toApp({"page":params.pageId,"params":params.params||""}),具体规则看文档
//     //传8表示弹出升级的框框
//     toApp:function(obj){
//         if(iosVaild){
//             utils.getWebViewJsBridge('toPageActivity',obj);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.toPageActivity) === "function"){
//                 console.log(utils.toJSONSTR(obj));
//                 window.android.toPageActivity(utils.toJSONSTR(obj));
//             }
//         }
//     },
//     //调用app方法，为读信状态改为已读
//     isCanCheck: function (obj) {
//         if(iosVaild){
//             utils.getWebViewJsBridge('isCanCheck',obj);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.isCanCheck) === "function"){
//                 window.android.isCanCheck();
//             }
//         }
//     },
//     //更新返回箭头的颜色(目前红包详情页在用)
//     //1是默认值（绿色），2是红包的箭头（金色），3是白色的箭头
//     backColor:function(num){
//         if(iosVaild){
//             utils.getWebViewJsBridge('updateBackColor',num);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.updateBackColor) === "function"){
//                 window.android.updateBackColor(num);
//             }
//         }
//     },
//     //更新顶部文字和颜色
//     //如果文字为空直接从title中取，color传色值没传的话就是默认的黑色
//     topText:function(obj){
//         if(iosVaild){
//             utils.getWebViewJsBridge('updateTopTextAndColor',obj||{});
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.updateTopTextAndColor) === "function"){
//                 window.android.updateTopTextAndColor(obj.text,obj.color);
//             }
//         }
//     },
//     //顶部导航栏的背景颜色，有值的话就是色值，没值就是默认的白色
//     topBg:function(val){
//         if(iosVaild){
//             utils.getWebViewJsBridge('updateTopTitleColor',val||"");
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.updateTopTitleColor) === "function"){
//                 window.android.updateTopTitleColor(val||"");
//             }
//         }
//     },
//     //导航栏的底部线是否出现
//     //等于1的时候是有线条  其他都没有
//     isShowLine:function(num){
//         if(iosVaild){
//             utils.getWebViewJsBridge('isShowLine',num||"");
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.isShowLine) === "function"){
//                 window.android.isShowLine(num||"");
//             }
//         }
//     },
//     //头部右边的内容显示与不显示
//     //-1表示没有值 默认显示分享，0都不显示,isShow：show显示，notShow不显示
//     showShare:function(isShow,num,name){
//         if(iosVaild){
//             utils.getWebViewJsBridge('rightText',{'num':num,'name':name});
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.IsShowShare) === "function"){
//                 window.android.IsShowShare(isShow);
//             }
//         }
//     },
//     //头部右边的颜色
//     rightTextColor:function(str){
//         if(iosVaild){
//             utils.getWebViewJsBridge(str);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.rightTextColor) === "function"){
//                 window.android.rightTextColor(str);
//             }
//         }
//     },
//     //头部右边的内容
//     //-1表示没有值 默认显示分享，0都不显示，1是显示确定，2是显示书信赛，3参赛记录,4积分记录,5客服,6签到说明,7日历,8完成,9说明
//     rightTextFun:function(num,name){
//         if(iosVaild){
//             utils.getWebViewJsBridge('rightText',{'num':num,'name':name});
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.rightText) === "function"){
//                 window.android.rightText(num,name);
//             }
//         }
//     },
//     rightFun(callback){
//         var iosVaild = utils.versions.ios,
//             androidVaild = utils.versions.android;
//         if(iosVaild){
//             utils.setupWebViewJavascriptBridge(function(bridge) {
//                 bridge.registerHandler('getBox',function(){
//                     callback && callback();
//                 });
//             });
//         }else if(androidVaild){
//             window.getBox = function(){
//                 callback && callback();
//             };
//         }
//     },
//     //头部右边的分享图标 0位默认绿色分享图标 1为白色分享图标
//     setRightIcon:function(num){
//         var num = num?num:0;
//         if(iosVaild){
//             utils.getWebViewJsBridge('rightIcon',{'num':num});
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.setRightIcon) === "function"){
//                 window.android.setRightIcon(num);
//             }
//         }
//     },
//     //隐藏和显示头部 (导航栏)0是显示 1是不显示2显示浮层
//     hideTitle: function(num){
//         if(iosVaild){
//             utils.getWebViewJsBridge('visibleTitle',num);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.visibleTitle) === "function"){
//                 window.android.visibleTitle(num);
//             }
//         }
//     },
//     //左边箭头显示与不显示   1是不显示 0 显示
//     //第二个参数：是否拦截返回intercept 是否是拦截物理的返回事件 1是拦截 其他则是不拦截
//     hideLeft: function(num1,num2){
//         if(iosVaild){
//             utils.getWebViewJsBridge('notUserLeft',num1);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.notUserLeft) === "function"){
//                 window.android.notUserLeft(num1,num2);
//             }
//         }
//     },
//     //不走返回的方法(弹出框)
//     //0是关闭，1是进入
//     setDissArea: function(num){
//         if(iosVaild){
//             utils.getWebViewJsBridge('setDissmArea',num);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.setDissmArea) === "function"){
//                 window.android.setDissmArea(num);
//             }
//         }
//     },
//     //控制返回按钮 num:5 (亲密信箱)返回“我的”界面  1是直接返回到订单列表 2是关闭界面 3是返回home界面 4是祝福礼的返回
//     goAppPage:function(num){
//         if(iosVaild){
//             utils.getWebViewJsBridge('toPage',num);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.toPage) === "function"){
//                 window.android.toPage(num);
//             }
//         }
//     },
//     //分享的方法 shareObj = {"title":"","content":"","shareUrl":window.location.href.replace(/&curUserId=\d+/g,''),"shareImage":"","type":""};
//     getShareFun:function(shareObj){
//         if(iosVaild){
//             utils.getWebViewJsBridge('CallShare',shareObj);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.shareFun) === "function"){
//                 window.android.shareFun(JSON.stringify(shareObj));
//             }
//         }
//     },
//     //分享弹窗 一定要有值，num 0分享之后不做其他的事情，1分享回来跳转到别的页面
//     getClickShare:function(num){
//         if(iosVaild){
//             utils.getWebViewJsBridge('clickShare',num);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.clickShare) === "function"){
//                 window.android.clickShare(num);
//             }
//         }
//     },
// //      getShareImg:function(str){
// //           if(iosVaild){
// //                utils.getWebViewJsBridge('showSharePopupWindow',str);
// //            }else if(androidVaild){
// //                if(window.android && typeof(window.android.showSharePopupWindow) === "function"){
// //                    window.android.showSharePopupWindow(str);
// //                }
// //            }
// //      },
//     /**
//      *
//      * @param msgId   我的订单页面那里的老版本的分享才会有，没有就不传
//      * @param productSubImg 缩略图或分享出去的图片
//      * @param type 默认0连接分享，1图片分享
//      */
//     sharingGifts:function(obj,str){
//         if(iosVaild){
//             utils.getWebViewJsBridge('sharinggifts',obj);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.sharinggifts) === "function"){
//                 if(str&&str=='1'){//如果有str表示是2.11.0以下的版本，不支持type
//                     console.log(((!obj.msgId||obj.msgId=="null")?'':obj.msgId),obj.productSubImg||'')
//                     window.android.sharinggifts(((!obj.msgId||obj.msgId=="null")?'':obj.msgId),obj.productSubImg||'');
//                 }else{
//                     window.android.sharinggifts(((!obj.msgId||obj.msgId=="null")?'':obj.msgId),obj.productSubImg||'',obj.type||'');

//                 }
//             }
//         }
//     },
//     //haveBtn 是否需要下面的按钮 0需要 1不需要
//     haveShareBtn:function(opts){
//         if(androidVaild){
//             if(window.android && typeof(window.android.isShowButton) === "function"){
//                 window.android.isShowButton(opts.haveBtn);
//             }
//         }
//     },
//     //跳转到app的看信页面，传msgId
//     jumpAppLetter:function(str){
//         if(iosVaild){
//             utils.getWebViewJsBridge('xinhanjump',str);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.xinhanjump) === "function"){
//                 window.android.xinhanjump(str);
//             }
//         }
//     },
//     //这是跳转界面RESULT 127我的邀礼列表页
//     typeValue:function(str){
//         if(androidVaild){
//             if(window.android && typeof(window.android.typeValue) === "function"){
//                 window.android.typeValue(str);
//             }
//         }
//     },
//     /**
//      *
//      * @param id   就是id
//      * @param type 类型
//      * @param isClose 是否关闭 1 是关闭  0是不关闭
//      */
//     updataValue:function(obj){
//         if(iosVaild){
//             utils.getWebViewJsBridge('updataValue',obj);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.updataValue) === "function"){
//                 window.android.updataValue(obj.id,obj.type,obj.isClose);
//             }
//         }
//     },
//     //我的订单详情页用到，给返回时传值
//     Closedetails:function(obj){
//         if(iosVaild){
//             utils.getWebViewJsBridge('Closedetails',obj);
//         }else if(androidVaild){
//             if(window.android && typeof(window.android.Closedetails) === "function"){
//                 window.android.Closedetails(obj.msgId,obj.type);
//             }
//         }
//     }
}