<template>
    <div class="more-select_wrap">
       <ul class="item_wrap">
        <li class="item_box" v-for="(item,index) in dataList" :key="index">
                <div class="item">
                    <div class="check-group"><i class="iconfont icon" :class="item.active?'checkboxmarkedcircle active':'checkbox-blank-circle-line'" @click="selectSelfBtn(item)"></i></div>
                    <div class="text" @click="clickSelf(item)">{{item.chassisName}}</div>
                </div>
            <div class="item-child_box" v-if="(item.child&&item.child.length>0)" v-show="item.expand">
                <ul class="item_box" >
                    <li v-for="(it,i) in item.child" :key="i">
                        <div class="item" >
                            <div class="check-group"><i class="iconfont icon" :class="it.active?'checkboxmarkedcircle active':'checkbox-blank-circle-line'" @click="selectSelfBtn(it)"></i></div>
                            <div class="text" @click="clickSelf(it)">{{it.chassisName}}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
    </div>
</template>
<script>
export default {
    components: {},
    props:{options:Array},
    watch:{
        options:{
            handler:function(val) {
                this.dataList = JSON.parse(JSON.stringify(val))
            },
            deep:true
        }
    },
    data() {
        return {
            dataList:JSON.parse(JSON.stringify(this.options))
        }
    },
    created() {

    },
    methods: {
        // 点击按钮
        selectSelfBtn(row) {
            this.isSelectFn(row)
            // 勾选之后，其子集做出变化
            if(row.child&&row.child.length>0) {
               this.setChildSelect(row.child,row.active)
            }
            // 勾选之后，其父级做出变化
            if(row.chassisPcode!==[0]){
                this.setParentSelect(row,row.active)
            }
            
           
        },
           // 勾选还是取消勾选
        isSelectFn(row) {
            if(row.active) { // 取消勾选
                row.active = false 
            }else { // 勾选
                this.$set(row,'active',true)
            }
        },
        // 勾选父级之后，子集做出相应的变化
        setChildSelect(list,isActive) {
            list.forEach(v=>{
                this.$set(v,'active',isActive)
                if(v.child&&v.child.length>0) {
                    this.setChildSelect(v.child,isActive)
                }
            })
        },
        // 勾选子集后，父级做出变化
        setParentSelect (row,isActive) {
            if(isActive) { // 勾选， 只要有一个子集勾选，其父级就勾选
                this.dataList.forEach(v=>{
                    if(v.chassisCode === row.chassisPcode) {
                        this.$set(v,'active',true)
                    }
                })
            }else { // 取消勾选，如果所有子集取消勾选，其父级才取消勾选
                 this.dataList.forEach(v=>{
                    if(v.chassisCode === row.chassisPcode) {
                        if(v.child&&v.child.length>0) {
                            let num = 0
                            let activeNum = 0
                            v.child.forEach(e=>{
                                num ++ 
                                let curActive = !!e.active
                                if(curActive === isActive) {
                                    activeNum ++
                                }
                            })
                            this.$set(v,'active',!(num === activeNum))
                            
                        }
                    }
                })
            }
        },
        // 点击自身
        clickSelf(row) {
            // 如果有子集，那就是展开，没有子集，那就是勾选
            if(row.child&&row.child.length>0) {
                if(row.expand) {
                    row.expand = false
                }else {
                    this.$set(row,'expand',true)
                }
            } else {
                this.selectSelfBtn(row)
            }
        }
    }
    
}
</script>
<style lang="scss" scoped>
.more-select_wrap {
    width:100%;
    padding: 0 0.32rem;
    box-sizing: border-box;
    font-size: 0.28rem;
    .item_box {
            .item {
                position: relative;
                box-sizing: border-box;
                padding: 0.22rem 0rem;
                border-bottom:1px solid #f4f4f4;
                 padding-left:0.6rem;
                 cursor: pointer;
               
            }
            .check-group {
                width:0.6rem;
                position: absolute;
                left: 0;
                top:0.22rem;
                .icon {
                    font-size:0.36rem;
                    color:#898989;
                    &.active {
                        color:#1c71fb;
                    }
                    
                }
            }
        
    }
    .item-child_box {
        padding-left: 0.22rem;
        box-sizing: border-box;
    }
}
</style>