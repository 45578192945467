<template>
    <div class="list-wrap invoice-list-wrap">
        <div class="tab-wrap">
            <ul class="tab-ul clearfix">
                <li v-for="(list,index) in tabList" :key="index" class="fl" :class="list.value==tabActive?'active':''" @click="handleTab(index,list)">{{list.name}}</li>
            </ul>
        </div>
        <div id="mescroll" class="mescroll">
            <ul class="cnt-ul">
                <li class="" v-for='(list,index) in dataList' :key="index" @click="handleDetail(list)">
                    <div class="item0" v-if="list.signTime">{{list.signTime | dateFormat}}</div>
                    <div class="item0" v-else>{{list.receiptDate | dateFormat1}}</div>
                    <div class="item1"> 
                            <span class="it11">{{list.disCompanyName}}</span>
                            <!-- <span class="it12">(很急)</span> -->
                    </div>
                    <div class="item2 clear">
                        <div class="fl">
                            <span class="it21">{{list.receiptNo}}</span>
                        </div>
                        <div class="item fr">￥<span class="it11">{{list.receiptMoney}}</span></div>
                    </div>
                    <div class="item3">{{list.receiveName}}</div>
                    <div class="item4">{{list.invoiceAddress}}</div>
                </li>
            </ul>
        </div>
        <!--
       <div class="apply-btn" @click="handleApply">申请</div>
        -->
    </div>
    
</template>
<script>
    import adjust from '../../common/js/adjust';
    import MeScroll from 'mescroll.js'
    import 'mescroll.js/mescroll.min.css'
    import {formatDate} from '../../common/js/dateformat'
    import {getWaitSignList,getSignList} from '../../api/invoice'

    export default {
        data() {
            return {
                mescroll: null,
                tabActive:'1',
                tabList:[
                    {
                        name:'未签收',
                        value:'1'
                    },
                    {
                        name:'已签收',
                        value:'2'
                    }
                ],
                pageNo:1,
                pageSize:10,
                dataList:null,
                timer:null,
                scrollData:0,
                
            }
        },
        filters: {
            dateFormat(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd hh:mm');
                }
                
            },
            dateFormat1(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd');
                }
                
            }
        },
        methods: {
            // handleApply(){
            //     this.$router.push({path: '/leave/apply/1'});
            // },
            handleTab(index,list){
                var _this = this;
                clearTimeout(this.timer);
                this.timer = setTimeout(function(){
                    _this.tabActive = list.value;
                    document.getElementById('mescroll').scrollTop = 0;
                    //重置列表数据
                    _this.mescroll.resetUpScroll();
                    //隐藏回到顶部按钮
                    //mescroll.hideTopBtn();
                },500)
                
            },
            handleDetail(list){
                this.$router.push({path: '/invoice/detail/'+list.invoiceUid});
            },
            //上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
            upCallback(page) {
                let para = {
                    pageNo : page.num,
                }
                if(this.tabActive == '1'){
                    getWaitSignList(para).then(res => {
                        if(res&&res.resCode==0){
                            if(res.data&&res.data.errcode==0&&res.data.data){
                                var data = res.data.data;
                                if (page.num == 1) this.dataList = [];
                                this.dataList = this.dataList.concat(data);
                                this.$nextTick(() => {
                                    this.mescroll.endSuccess(data.length);
                                })
                            }else{
                                this.$toast(res.data.errmsg||'网络错误，请重试！');
                            }
                        }else{
                            this.$toast(res.resMsg||'网络错误，请重试！');
                        }
                    
                    }).catch(res => {
                       this.mescroll.endErr();
                    });
                }else if(this.tabActive == '2'){
                    getSignList(para).then(res => {
                        if(res&&res.resCode==0){
                            if(res.data&&res.data.errcode==0&&res.data.data){
                                var data = res.data.data;
                                if (page.num == 1) this.dataList = [];
                                this.dataList = this.dataList.concat(data);
                                this.$nextTick(() => {
                                    this.mescroll.endSuccess(data.length);
                                })
                            }else{
                                this.$toast(res.data.errmsg||'网络错误，请重试！');
                            }
                        }else{
                            this.$toast(res.resMsg||'网络错误，请重试！');
                        }
                    
                    }).catch(res => {
                       this.mescroll.endErr();
                    });
                }
                
            }
        },

        mounted() {
            var _this = this;
            adjust.getTokenInfo(function(){
                //创建MeScroll对象
                _this.mescroll = new MeScroll("mescroll", { //在vue的mounted生命周期初始化mescroll,确保此处配置的id能够被找到
                    up: {
                        callback: _this.upCallback,
                        toTop: { //配置回到顶部按钮
                           //src: require('@/assets/img/mescroll-totop.png') //此处动态引入assets的文件需用require
                        },
                        empty: {
                        //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
                            warpId: "mescroll", //父布局的id (1.3.5版本支持传入dom元素)
                            icon: require('@/common/images/icon_empty.png'), //图标,默认null,支持网络图
                            tip: "暂无数据" //提示
                        },
                    }
                });
            });
            
        },
        activated(){
            var _this = this;
            if(_this.$route.meta.isUpdate){
                _this.scrollData = 0;
                _this.tabActive = '1';
                _this.mescroll.resetUpScroll();
            }
            document.getElementById('mescroll').scrollTop = this.scrollData;
        },
        beforeRouteEnter(to, from, next) {
            var _this = this;
            next();
        },
        beforeRouteLeave(to, from, next) {
            this.scrollData = document.getElementById('mescroll').scrollTop;
            next();
        }
    }
</script>
<style lang="scss">
@import "../../styles/common";
    .list-wrap{
        background-color:#F5F4F3;
        width:100%;
        height:100%;
        position:fixed;
        left:0;
        top:0;
        .apply-btn{
            position:fixed;
            background-color:rgba(251,55,91,1);
            left:50%;
            @include transform(translate(-50%));
            width:40%;
            height:0.7rem;
            line-height:0.7rem;
            bottom:0.1rem;
            cusrsor:pointer;
            @include border-radius(0.5rem);
            font-size:0.26rem;
            color:#fff;
            text-align:center;
        }
    }
    .tab-wrap{
        height:0.8rem;
        line-height:0.8rem;
        background-color:#fff;
        margin-bottom:0.16rem;
        position:fixed;
        left:0;
        top:0;
        width:100%;
    }
    .tab-ul{
        li{
            width:50%;
            height:0.8rem;
            text-align:center;
            line-height:0.8rem;            
            font-size:0.30rem;
            box-sizing:border-box;
            cursor:pointer;
            color:rgb(153,153,153);
            &.active{
                color:rgb(51,51,51);
                border-bottom:0.03rem solid rgb(51,51,51);
            }
        }
    }
    .invoice-list-wrap{
        .mescroll {
            position: fixed;
            top: 0.9rem!important;
            bottom: 0rem;
            height: auto;
        }
    }
     
    .cnt-ul{
        li{
            font-size:0.28rem;
            background-color:#fff;
            padding:0.3rem 0.3rem;
            margin-bottom:0.1rem;
            .item0{
                text-align:right;
                font-size:0.24rem;
                color:#999;
            }
            .item1{
                line-height:0.5rem;
                .date{
                    color:rgb(102,102,102);
                }
            }
            .it11{
                color:rgb(51,51,51);
                margin-right:0.08rem;
            }
            .it12{
                color:rgb(254,52,90);
            }
            .item2,.item3,.item4{
                color:rgb(102,102,102);
                line-height:0.5rem;
                .it21{
                    margin-right:0.08rem;
                }
            }
        }
    }
</style>