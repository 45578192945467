<template>
    <div class="container">
        页面内容
    </div>
</template>

<script>
    //import Ajax from '../api/index';
    import Url from '../common/js/url'

    export default {
        data() {
            return {
                loading: false,
            }
        },
        methods: {

        },

        mounted() {
            var that = this;

        }
    }

</script>

<style scoped lang="scss">
    @import "../styles/common";

    .container{

    }
</style>