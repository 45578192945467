import axios from 'axios'
import {base} from './api'
import Qs from 'qs'

//列表
export var getOilReportList = params => { return axios.get(`${base}/h5/report/myReportList/`+params.pageNo, {params:{}}).then(res => res.data); };

//详情
export var getOilReportDetail = params => { return axios.get(`${base}/h5/report/detail/`+params.reportId, {params:{}}).then(res => res.data); };

//撤销
export var getOilReportDelete = params => { return axios.get(`${base}/h5/report/deleteReport/`+params.reportId, {params:{}}).then(res => res.data); };

//添加油报
export var getRepaireAdd = params => { return axios.post(`${base}/h5/report/addReport`, Qs.stringify(params)).then(res => res.data) };