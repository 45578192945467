<template>
    <div class="doc-detail-wrap">
        <div class="title">{{detailData.docName}}</div>
        <div class="cnt" v-html="detailData.docContent" @click="handleImg($event)"></div>
        <div class="bottom">
            <div class="name">{{detailData.dispatch}}</div>
            <div class="time">{{detailData.createDate|dateFormat}}</div>
        </div>
        <div class="cover-mod jCoverImg" v-show="viewImgSwiper" @click="closeImg">
            <ul class="items swiper-wrapper">
                <li class="swiper-slide" v-for="(list,index) in imgArr" :key="index"><img class="small" :src="list"></li>
            </ul>
            <div class="swiper-pagination"></div>
        </div>
        <div class="textarea-box">
            <label><span class="label-star top-star">*</span><span>审批意见：</span></label>
            <textarea name="" id="" rows="5"  placeholder="请输入内容" v-model="auditMsg"></textarea>
        </div>
        <div class="ui-button clear" :style="showStatic?staticStyle:fixStyle"><div class="btn btn1 fl"  @click="handleAgree('2')">不同意</div><div class="btn fl" @click="handleAgree('1')">同意</div></div>
    </div>
    
</template>
<script>
    import Swiper from 'swiper'
    import 'swiper/dist/css/swiper.min.css';
    import utils from '../../common/js/utils';
    import {formatDate} from '../../common/js/dateformat'
    import {getDucumentDetail,getDucumentSeeDetail,getDocumentApprove} from '../../api/document'
    import adjust from '../../common/js/adjust';
  

    export default {
        data() {
            return {
                detailData:[],
                docId:'',
                imgArr:[],
                swiperImgBox:null,
                viewImgSwiper:false,
                auditMsg:'',
                isApproval:false,
                showStatic:false,
                fixStyle:'position:fixed',
                staticStyle:"position:static",
                winHeight: document.body.clientHeight,
                iosVaild : utils.versions.ios,
                androidVaild : utils.versions.android,
                btnClick:true,
                
            }
        },
        filters: {
            dateFormat(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd hh:mm');
                }   
            }
        },
        methods: {
            //同意/不同意
            handleAgree(status){
                var _this = this;
                if(!this.auditMsg){
                     this.$toast('请输入审批意见！');
                     return false;
                }else{
                    let para = {
                        auditMsg : this.auditMsg,
                        auditStatus : status,
                        docId : this.docId,
                    }
                    if(_this.btnClick){
                        _this.btnClick = false;
                        getDocumentApprove(para).then(res => {
                            if(res&&res.resCode==0){
                                if(res.data&&res.data.errcode==0){
                                    this.isApproval = true;
                                    this.$toast('审批成功');
                                    setTimeout(function(){
                                        _this.$router.push({path: '/document/list'});
                                    },2000)
                                }else{
                                    this.$toast(res.errmsg||'网络错误，请重试！');
                                    _this.btnClick = true;
                                }
                            }else{
                                this.$toast(res.resMsg||'网络错误，请重试！');
                                _this.btnClick = true;
                            }
                        
                        }).catch(res => {
                            _this.btnClick = true;
                        });
                    }
                    
                }

            },
            //图片放大
            handleImg(event){
                if (event.target.nodeName === 'IMG') {
                    var index = 0;
                    if(event.toElement.src){
                        index = this.imgArr.indexOf(event.toElement.src);
                        index = index == '-1'?0:index;
                    }
                    this.viewImgSwiper = true;
                    if(this.swiperImgBox){
                        this.swiperImgBox.slideTo(index, 50, false);
                    }
                }
                
            },
            //关闭图片轮播
            closeImg(){
                this.viewImgSwiper = false;
            },
            getDetailFun(){
                var _this = this;
                let para = {
                    docId:this.docId
                }
                getDucumentSeeDetail(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.detailData = data;
                            this.detailData.docContent.replace(/<script.*?>.*?<\/script>/ig,'');

                            var imgReg = /<img.*?(?:>|\/>)/gi;//匹配所有img标签
                            var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;//匹配img标签里面的src
                            var arr = this.detailData.docContent.match(imgReg);
                            if(arr.length>0){
                                arr.forEach(function(v,i){
                                    var srcImg = v.match(srcReg);
                                    if(srcImg){
                                         _this.imgArr.push(srcImg[1]);
                                    }
                                   
                                })
                            }

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            // lightImgBox:function(){
            //     var $imgList= $detailWrap.find('.jMaxImg'),array=[];
            //     if($imgList.length>0){
            //         $.each($imgList,function(i,v){
            //             var src = $(this).attr('data-url');
            //             $(this).attr('data-index',i);
            //             if(/,/.test(src)){
            //                 var srcArry = src.split(',');
            //                 array.push(srcArry[0].replace(/\/$/,""));
            //                 $(this).attr('data-url',srcArry[0]).after('<span class="img-txt"><font>'+srcArry[1]+'</font></span>');
            //             }else{
            //                 array.push($(this).attr('data-url').replace(/\/$/,""));
            //             }
            //         });
            //         $detailWrap.append(template("tpl-image-cnt",{'imgObj':array,'imgWidth':$(window).width()}));
            //         swiperImgBox = new swiper('.jCoverImg',{
            //             resistanceRatio : 0,
            //             pagination:'.swiper-pagination2'
            //         });
            //         var $item=$('.jCoverImg li');
            //         $.each($item,function(i,n){
            //             $(this).find('img')[0].onload = function(){
            //                 var img=$(this);
            //                 if(img.height()<parseInt($(window).height())){
            //                     img.addClass('small');
            //                 }
            //             };
            //         });
            //     }
            // },
            
        },

        mounted() {
            var _this = this;
            this.docId = this.$route.params.docId||'';
            this.swiperImgBox = new Swiper('.jCoverImg',{
                resistanceRatio : 0,
                pagination: {el:'.swiper-pagination'},
                paginationClickable: true,
                observer:true ,//修改swiper自己或子元素时，自动初始化swiper
                observeParents:true,//修改swiper的父元素时，自动初始化swiper
            });
            adjust.getTokenInfo(function(){
                _this.getDetailFun();
            });
            window.onresize = () => {
                if(_this.androidVaild){
                    var curHeight = document.body.clientHeight;
                    if(curHeight<_this.winHeight){
                        _this.showStatic = true;
                    }else{
                        _this.showStatic = false;
                    }
               }
            }
        },
        beforeRouteLeave(to, from, next) {
            if(this.isApproval){
                to.meta.isUpdate = true;  
            }else{
                to.meta.isUpdate = false; 
                
            }
            next();
        }
    }
</script>
<style  lang="scss">
    @import "../../styles/common";
    @import "../../styles/coverImg";
    .doc-detail-wrap{
        padding:0.32rem;
        padding-bottom:1.5rem;
        .title{
            text-align:center;
            font-size:0.4rem;
            line-height:0.6rem;
            //padding:0.2rem 0;

        }
        .cnt{
            padding-top:0.3rem;
            //padding: 0 0.32rem;
            font-size: 0.32rem;
            line-height: 0.5rem;
            color:#595350;
            word-wrap: break-word;
            -webkit-box-orient: vertical; 
            text-align:justify;
            text-justify:inter-ideograph;/*IE*/
            img{
                padding:0.1rem 0rem;
                margin:0 auto;
                vertical-align:middle;

            }
        }
        .bottom{
            text-align:right;
            font-size:0.32rem;
            padding-top:0.5rem;
            .name{
                line-height:0.5rem;
                color: #333;
            }
            .time{
                color:#888;
            }

        }
        .ui-button{
            width:100%;
            height:1rem;
            background-color:rgb(251,55,91);
            text-align:center;
            line-height:1rem;
            font-size:0.32rem;
            color:#fff;
            .btn{
                width:50%;
                &.btn1{
                    background-color:rgb(30,135,242)
                }
            }
        }
        .textarea-box{
            height:auto;
            margin-bottom:0.1rem;
            padding-top:0.4rem;
            label{
                line-height:1rem;
            }
            textarea{
                display:block;
                border:0 none;
                width:100%;
                font-size:0.28rem;
            }
        }
    }
</style>