<template>
  <div class="apply-wrap leave-apply-wrap">
    <form>
      <div class="form-box clear">
        <label class="fl">
          <span class="label-star">*</span>请假人：
        </label>
        <span class="fr right">{{memberName}}</span>
      </div>
      <div class="form-box clear">
        <label class="fl">
          <span class="label-star">*</span>请假类型：
        </label>
        <div
          class="fr right-select"
          :class="leaveType?'active':''"
          @click="toTypeShow"
        >{{leaveType?leaveType:'请选择>'}}</div>
      </div>
      <div class="form-box clear">
        <label class="fl">
          <span class="label-star">*</span>开始时间：
        </label>
        <div
          class="fr right-select"
          :class="addForm.startTime?'active':''"
          @click="toShowBeginTime"
        >{{addForm.startTime|dateFormat}}</div>
      </div>
      <div class="form-box clear">
        <label class="fl">
          <span class="label-star">*</span>结束时间：
        </label>
        <div
          class="fr right-select"
          :class="addForm.endTime?'active':''"
          @click="toShowEndTime"
        >{{addForm.endTime|dateFormat}}</div>
      </div>
      <div class="form-box textarea-box">
        <label>
          <span class="label-star top-star">*</span>请假事由：
        </label>
        <textarea name id rows="3" placeholder="请输入请假事由" v-model="addForm.reason"></textarea>
      </div>      
      <div class="form-box clear">
        <label class="fl">
          <span class="label-star">*</span>审批人：
        </label>
        <span class="fr right">{{managerName}}</span>
      </div>
    </form>
	
	<div class="table-data-box" v-if="addForm.leaveType==='9'">
	  <div class="info-title1">		 
		  该时间段已请假人员列表
	  </div>
	  <div class="table-data">
	    <ul class="flex tr">
	      <li class="th line1">请假时间</li>
	      <li class="th line2">区域</li>
	      <li class="th line3">驾驶员</li>
	      <li class="th line4">请假天数</li>
	    </ul>
	    <ul class="flex dr" v-for="(item,index) in leavePersonData" :key="index">
	      <li class="td line1 flex" style="flex-direction:column">
	        <span class="lineh5">{{item.startTime}}</span>
	        <!-- <span style="text-align:center;">至</span> -->
	        <span class="lineh5">{{item.endTime}}</span>
	      </li>
	      <li class="td line2">{{item.areaName}}</li>
	      <li class="td line3">{{item.realName}}</li>
	      <li class="td line4">{{item.leaveDays}}</li>
	    </ul>
	  </div>
	</div>
    <div class="ui-button" :style="showStatic?staticStyle:fixStyle" @click="handleApply">提交申请</div>
    <awesome-picker
      ref="picker"
      :colorConfirm="picker.colorConfirm"
      :data="typeList"
      @confirm="typeConfirmFn"
    ></awesome-picker>
    <mt-datetime-picker
      ref="beginPicker"
      type="datetime"
      year-format="{value}"
      month-format="{value}"
      date-format="{value}"
      hour-format="{value}"
      minute-format="{value}"
      v-model="pickerValue"
      @confirm="handleBeginTime"
    ></mt-datetime-picker>
    <mt-datetime-picker
      ref="endPicker"
      type="datetime"
      year-format="{value}"
      month-format="{value}"
      date-format="{value}"
      hour-format="{value}"
      minute-format="{value}"
      v-model="pickerValue"
      @confirm="handleEndTime"
    ></mt-datetime-picker>
    <van-calendar v-model="calendarShow" @confirm="calendarConfirm"   type="multiple" :max-range="4" />
  </div>
</template>
<script>
import utils from '../../common/js/utils'
import { formatDate } from '../../common/js/dateformat'
import { getLeaveApply,getApprovePersons } from '../../api/leave'
import { getCommonList, getMemberInfo, getManager } from '../../api/common'
// import Calendar from 'vue-mobile-calendar'

export default {
  components: {},
  data () {
    return {
      memberName: '',
      typeDefaultData: [],
      typeShow: false,
      pickerValue: new Date(),
      addForm: {
        leaveType: '',
        startTime: '',
        endTime: '',
        leaveLong: '',
        leaveDays: ''
      },
      typeList: [],
      leaveType: '',
      managerName: '',
      picker: {
        colorConfirm: 'rgb(30,135,242)'
      },
      type: '',
      iosVaild: utils.versions.ios,
      androidVaild: utils.versions.android,
      winHeight: document.body.clientHeight,
      showStatic: false,
      fixStyle: 'position:fixed',
      staticStyle: 'position:static',
      calendarShow: false,
      leavePersonData:[]
    }
  },
  filters: {
    dateFormat (time) {
      if (time && time !== '请选择>') {
        // var date = new Date(time);
        // return formatDate(date,'yyyy-MM-dd hh:mm');
        return time
      } else {
        return '请选择>'
      }
    }
  },
  methods: {
    // 获取管理员信息
    getManagerInfo () {
      getManager({})
        .then(res => {
          if (res && res.resCode === 0) {
            if (res.data && res.data.errcode === 0 && res.data.data) {
              var data = res.data.data
              this.managerName = data.managerName || ''
            } else {
              this.$toast(res.data.errmsg || '网络错误，请重试！')
            }
          } else {
            this.$toast(res.resMsg || '网络错误，请重试！')
          }
        })
        .catch(res => {})
    },
    // 获取到自己的信息
    getMyInfo () {
      getMemberInfo({})
        .then(res => {
          if (res && res.resCode === 0) {
            if (res.data && res.data.errcode === 0 && res.data.data) {
              var data = res.data.data
              this.memberName = data.memberName || ''
            } else {
              this.$toast(res.data.errmsg || '网络错误，请重试！')
            }
          } else {
            this.$toast(res.resMsg || '网络错误，请重试！')
          }
        })
        .catch(res => {})
    },
    getTypeList () {
      var _this = this
      let para = {
        name: 'SYS_LEAVE_TYPE'
      }
      getCommonList(para)
        .then(res => {
          if (res && res.resCode === 0) {
            if (res.data && res.data.errcode === 0 && res.data.data) {
              var data = res.data.data
              if (data.length > 0) {
                data.forEach((v, i) => {
                  let list = {}
                  list['code'] = v.dicCode
                  list['value'] = v.dicName
                  _this.typeList.push(list)
                })
              }
            } else {
              this.$toast(res.data.errmsg || '网络错误，请重试！')
            }
          } else {
            this.$toast(res.resMsg || '网络错误，请重试！')
          }
        })
        .catch(res => {})
    },
    close () {
      // this.typeShow = false;
    },
    typeConfirmFn (val) {
      if (val) {
        this.leaveType = val[0].value || ''
        this.addForm.leaveType = this.typeList[val[0].index].code
        this.addForm.reason = this.addForm.leaveType === '9' ? '正常休假' : ''
        if (this.addForm.leaveType === '9') {
          this.addForm.startTime = ''
          this.addForm.endTime = ''
        }
      }
    },
    toTypeShow () {
      this.$refs.picker.show()
      // this.typeShow = true;
    },
    beginHandelChange (val, format) {
      // this.beginTime = format;
    },
    toShowBeginTime () {
      if (this.addForm.leaveType === '9') { //  正常休假
        this.calendarShow = true
      } else {
        this.$refs.beginPicker.open()
      }
    },
    toShowEndTime () {
      if (this.addForm.leaveType === '9') { //  正常休假
        this.calendarShow = true
      } else {
        this.$refs.endPicker.open()
      }
    },
    handleBeginTime (val) {
      let startTime = formatDate(val, 'yyyy-MM-dd hh:mm:ss')
      this.addForm.startTime = ''
      if (startTime) {
        let timeArr = startTime.split(':')
        this.addForm.startTime = timeArr[0] + ':' + '00' + ':' + timeArr[2]
      }
    },
    // 日期选择确认
    calendarConfirm(dateArr) {
      if (dateArr.length<=0) {
        return
      }
      // 日期排序
      let sortDate = dateArr.sort((a,b)=>{
        return Date.parse(a) - Date.parse(b)
      })
      let flag = this.continueDays(sortDate)
      if (!flag) {
         this.$toast('请选择连续的日期')
         return
      }
      this.calendarShow = false;
      this.addForm.startTime = formatDate(sortDate[0], 'yyyy-MM-dd');
      this.addForm.endTime = formatDate(sortDate[sortDate.length-1], 'yyyy-MM-dd');
      this.leavePersonData = []
      this.getLeavePersonFn()

    },
    // 判断日期是否连续
    continueDays(arr_days) {
      // 先排序，然后转时间戳
      let days = arr_days.map((d, i) => {
        let dt = new Date(d)
        console.log(dt.getDate())
        dt.setDate(dt.getDate() + arr_days.length - 1 - i) // 处理为相同日期
        // 抹去 时 分 秒 毫秒
        dt.setHours(0)
        dt.setMinutes(0)
        dt.setSeconds(0)
        dt.setMilliseconds(0)
       
        console.log(new Date(dt))
        return +dt
      })
      console.log(days)
      let ret = true
      days.forEach(d => {
        if (days[0] !== d) {
        ret = false
        }
      })
      return ret
      },
    // 根据时间，获取请假人数
    getLeavePersonFn () {
      getApprovePersons({ startDate: this.addForm.startTime, endDate: this.addForm.endTime })
          .then(res => {
            if (res && res.resCode === 0) {
              if (res.data && res.data.errcode === 0) {
                this.leavePersonData = res.data.data || []
                console.log(res.data.data)
              } else {
                this.$toast(res.data.errmsg || '网络错误，请重试！')
              }
            } else {
              this.$toast(res.resMsg || '网络错误，请重试！')
            }
          })
          .catch(res => {})
    },
    handleEndTime (val) {
      let endTime = formatDate(val, 'yyyy-MM-dd hh:mm:ss')
      this.addForm.endTime = ''
      if (endTime) {
        let timeArr = endTime.split(':')
        this.addForm.endTime = timeArr[0] + ':' + '00' + ':' + timeArr[2]
      }
    },
    handleApply () {
      var _this = this
      this.checkForm(() => {
        let para = Object.assign({}, this.addForm)
        if(para.leaveType === '9') {
          para.startTime = para.startTime + ' 00:00:00'
          para.endTime = para.endTime + ' 23:59:59'
        }
        para.startTime = 
        getLeaveApply(para)
          .then(res => {
            if (res && res.resCode === 0) {
              if (res.data && res.data.errcode === 0) {
                this.$toast('申请成功')
                setTimeout(function () {
                  if (_this.type == 1) {
                    _this.$router.push({ path: '/leave/list' })
                  } else if (_this.type == 2) {
                    _this.$router.push({ path: '/leave/myList' })
                  }
                }, 2000)
              } else {
                this.$toast(res.data.errmsg || '网络错误，请重试！')
              }
            } else {
              this.$toast(res.resMsg || '网络错误，请重试！')
            }
          })
          .catch(res => {})
      })
    },
    checkForm (callback) {
      if (!this.addForm.leaveType) {
        this.$toast('请选择请假类型', {
          durtaion: 200,
          location: 'center' // 默认在中间
        })
        return false
      } else if (!this.addForm.startTime) {
        this.$toast('请选择开始时间', {
          durtaion: 200,
          location: 'center' // 默认在中间
        })
        return false
      } else if (!this.addForm.endTime) {
        this.$toast('请选择结束', {
          durtaion: 200,
          location: 'center' // 默认在中间
        })
        return false
      } else {
        callback && callback()
      }
    }
  },

  mounted () {
    var _this = this
    this.type = this.$route.params.type || ''
    this.getMyInfo()
    this.getManagerInfo()
    this.getTypeList()
    window.onresize = () => {
      if (_this.androidVaild) {
        var curHeight = document.body.clientHeight
        if (curHeight < _this.winHeight) {
          _this.showStatic = true
        } else {
          _this.showStatic = false
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.leave-apply-wrap {
	.info-title1{	  
	  line-height: 0.8rem;
	  height: 0.8rem;
	  padding-left: 0.18rem;
	  font-size: 0.32rem;
	  color: rgb(51, 51, 51);	 	 
	}
  .table-data-box {
	margin-top: 0.2rem;
    margin-bottom: 0.1rem;
    background:#fff;
  }
  .table-data {
    font-size: 0;
    width: 100%;
    .line1 {
      width: 46%;
      padding-left: 0.2rem;
      box-sizing: border-box;
    }
    .line2,
    .line3 {
      width: 17%;
    }
    .line4 {
      width: 20%;
      padding-right: 0.2rem;
      box-sizing: border-box;
    }
    .th,
    .td {
      overflow: hidden;
      font-size: 0.28rem;
      text-align: left;
      list-style: none;
    }
    .td {
      font-size: 0.28rem;
      height: 1rem;
      line-height: 1rem;
      word-break: break-all;
      .lineh5 {
        line-height: 0.5rem;
      }
    }
    .tr {
      list-style: none;
      background: #eee;
      line-height: 0.8rem;
    }
    .dr {
      list-style: none;
      padding: 0.1rem 0;
      box-sizing: border-box;
      &:nth-child(odd) {
        background-color: #f8f8f9;
      }
    }
  }
 }
</style>
<style lang="scss">
@import '../../styles/common';
.area_ctrl {
  background-color: #fff !important;
  .area_btn_box {
    background-color: #fff !important;
    &::before,
    &::after {
      background-color: #fff !important;
    }
  }
}
.ui-button {
  width: 100%;
  height: 1rem;
  background-color: rgb(251, 55, 91);
  text-align: center;
  line-height: 1rem;
  font-size: 0.32rem;
  color: #fff;
}
.ui-img-wrap {
  background-color: #fff;
  padding: 0.1rem;
  margin-bottom: 0.1rem;
  .title {
    color: rgb(153, 153, 153);
    font-size: 0.28rem;
    line-height: 0.8rem;
  }
  .img-ul {
    width: 100%;
    li {
      width: 22%;
      margin-right: 4%;
      margin-bottom: 0.1rem;
      cursor: pointer;
      &:nth-of-type(4n) {
        margin-right: 0;
      }
      .img {
        width: 100%;
        display: block;
        position: relative;
        &::before {
          content: '';
          padding-top: 100%;
          display: block;
        }
        img {
          width: 100%;
          height: 100%;
          vertical-align: middle;
          position: absolute;
          top: 0;
          left: 0;
        }
        .icon-del {
          position: absolute;
          right: 0;
          top: 0;
          @include transform(translate(50%, -50%));
          z-index: 1;
          /*color:$iconColor;*/
          font-size: 0.28rem;
          width: 0.32rem;
          height: 0.32rem;
          /*background:img-url("gift/bg_del.png") no-repeat center;*/
          @include background-size(100%);
        }
      }
    }
    .last {
      border: 0.01rem solid rgb(238, 238, 238);
      @include box-sizing(border-box);
      .line {
        background-color: rgb(204, 204, 204);
        position: absolute;
        &.line1 {
          width: 60%;
          height: 2px;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
        }
        &.line2 {
          width: 2px;
          height: 60%;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
        }
      }
    }
  }
}
</style>
