import axios from 'axios'
import {base} from './api'
import Qs from 'qs'

//列表
export var getDucumentList = params => { return axios.get(`${base}/h5/document/documentList/`+params.pageNo, {params:{}}).then(res => res.data); };

//详情
export var getDucumentDetail = params => { return axios.get(`${base}/h5/document/detail/`+params.docId, {params:{}}).then(res => res.data); };

//待审批
export const getWaitApproveList = params => { return axios.get(`${base}/h5/document/waitApproveList/`+params.pageNo, {params:{}}).then(res => res.data); };

//已审批 
export const getApprovedList = params => { return axios.get(`${base}/h5/document/approvedList/`+params.pageNo, {params:{}}).then(res => res.data); };

//审批时详情
export const getDucumentSeeDetail = params => { return axios.get(`${base}/h5/document/seeDetail/`+params.docId, {params:{}}).then(res => res.data); };

//审批
export const getDocumentApprove= params => { return axios.post(`${base}/h5/document/approve`, Qs.stringify(params)).then(res => res.data); };

