<template>
  <div class="approval-wrap">
    <div class="apply-info">
      <div class="info-title">
        <span class="line-icon"></span>
        申请信息
      </div>
      <div class="cnt">
        <div class="clear cnt-item">
          <div class="fl left">
            申请人：
            <span>{{detailData.driveName}}</span>
          </div>
          <div class="fl right">
            车牌号：
            <span>{{detailData.carNo}}</span>
          </div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">
            申请类型：
            <span>{{detailData.repaireType==='normal'?'正常维修保养':'事故'}}</span>
          </div>
          <!-- <div class="fl right">紧急程度：<span>一般</span></div> -->
        </div>
        <div class="cnt-item tool-item">
          <div class>
            主要部件：
            <span>{{detailData.repaireParts?detailData.repaireParts.join('，'):''}}</span>
          </div>
        </div>
        <div class="cnt-item clear time-box">
          <div class="fl left">期望修理时间：</div>
          <div class="fl right">{{detailData.applyDate|dateFormat}}</div>
        </div>
        <div class="cnt-item clear detail-box">
          <div class="fl left">申请说明：</div>
          <div class="fl right">{{detailData.applyContent}}</div>
        </div>
        <div class="img-box ui-img-wrap">
          <ul class="img-ul clear">
            <li class="fl" v-for="(list,index) in (applyImgs?applyImgs:[])" :key="index">
              <div class="img">
                <img class="jMaxImg big-img" :src="list" @click="handleImg(index,1)" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="apply-info advice-info">
      <div class="info-title">
        <span class="line-icon"></span>
        审批意见
      </div>
      <div class="cnt">
        <div class="clear cnt-item">
          <div class="fl left">审批意见：</div>
          <div class="fl right">{{detailData.applyMsg}}</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">维修厂：</div>
          <div class="fl right">{{detailData.repaireCompany}}</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">维修地址：</div>
          <div class="fl right">{{detailData.repaireAddress}}</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">联系人员：</div>
          <div class="fl right">{{detailData.repaireCompanyMan}}</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">联系电话：</div>
          <div class="fl right">{{detailData.repairePhone}}</div>
        </div>
        <div class="cnt-item clear">
          <div class="fl left">维修时间：</div>
          <div class="fl right">{{detailData.repaireDate|dateFormat}}</div>
        </div>
      </div>
    </div>
    <div class="apply-info dec-info">
      <div class="info-title">
        <span class="line-icon"></span>
        维修描述
      </div>
      <div>{{detailData.repaireDetail?detailData.repaireDetail:'暂无描述'}}</div>
    </div>
    <div class="apply-info send-info">
      <div class="info-title">
        <span class="line-icon"></span>
        送车信息
      </div>
      <div class="cnt">
        <div class="clear cnt-item">
          <div class="fl left">车辆里程：</div>
          <div class="fl right">{{detailData.carKm}}km</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">车辆油量：</div>
          <div class="fl right">{{detailData.carOil}}L</div>
        </div>
        <div class="cnt-item">
          <div>里程照片：</div>
          <div class="img-box ui-img-wrap">
            <ul class="img-ul clear">
              <li class="fl" v-for="(list,index) in (carOilKmImages?carOilKmImages:[])" :key="index">
                <div class="img">
                  <img class="jMaxImg big-img" :src="list" @click="handleImg(index,2)" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="send-info">
      <div class="info-title">
        <span class="line-icon"></span>
        维修信息
      </div>
      <form>
        <!-- <div class="form-box clear">
          <label class="fl">维修费用：</label>
          <input class="fr" type="number" placeholder="请输入维修费用" v-model="addForm.repaireMoney" />
        </div>
        <div class="form-box clear">
          <label class="fl">人工费用：</label>
          <input class="fr" type="number" placeholder="请输入人工费用" v-model="addForm.manCost" />
        </div>-->
        <div class="ui-img-wrap imgWrap">
          <p class="title jImgTile">维修和工单照片（最多上传6张照片）</p>
          <ul class="img-ul clear">
            <li class="fl" v-for="(list,index) in imgArr" :key="index">
              <div class="img">
                <img class="jMaxImg big-img" :src="list" @click="handleImg(index,3)" />
                <img
                  class="icon-del jIconDel"
                  src="../../common/images/icon_del.png"
                  alt
                  @click="handleDelImg(index,'repaire')"
                />
                <!-- //<i class='icon-del jIconDel'></i> -->
              </div>
            </li>
            <li class="fl last jLast" @click="handleUpdateImg('repaire')" v-show="imgArr.length<6">
              <div class="img">
                <i class="line line1"></i>
                <i class="line line2"></i>
              </div>
            </li>
          </ul>
          <div class="swiperImg"></div>
        </div>
      </form>
    </div>
    <div class="send-info">
      <div class="info-title">
        <span class="line-icon"></span>
        接车信息
      </div>
      <form>
        <div class="form-box clear">
          <label class="fl">车辆里程：</label>
          <input
            class="fr"
            type="number"
            placeholder="请输入车辆公里后6位"
            v-model="addForm.carKmAfter"
            @input="changeNum"
          />
        </div>
        <div class="form-box clear">
          <label class="fl">车辆油量：</label>
          <input class="fr" type="number" placeholder="请输入车辆油量" v-model="addForm.carOilAfter" />
        </div>
        <div class="ui-img-wrap imgWrap">
          <p class="title jImgTile">油量照片（最多上传6张照片）</p>
          <ul class="img-ul clear">
            <li class="fl" v-for="(list,index) in imgOilArr" :key="index">
              <div class="img">
                <img class="jMaxImg big-img" :src="list" @click="handleImg(index,4)" />
                <img
                  class="icon-del jIconDel"
                  src="../../common/images/icon_del.png"
                  alt
                  @click="handleDelImg(index,'oil')"
                />
                <!-- //<i class='icon-del jIconDel'></i> -->
              </div>
            </li>
            <li class="fl last jLast" @click="handleUpdateImg('oil')" v-show="imgOilArr.length<6">
              <div class="img">
                <i class="line line1"></i>
                <i class="line line2"></i>
              </div>
            </li>
          </ul>
          <div class="swiperImg"></div>
        </div>
      </form>
    </div>

    <div class="ui-button clear" :style="showStatic?staticStyle:fixStyle">
      <div class="btn btn1 fl" @click="handleSave">保存</div>
      <div class="btn fl" @click="handleGetCar">完成接车</div>
    </div>
    <cover-img ref="applyImg" :imgArr="imgList"></cover-img>
  </div>
</template>
<script>
import utils from '../../common/js/utils'
import adjust from '../../common/js/adjust'
import coverImg from '../../components/coverImg'
import {
  getRepaireDetail,
  getRepaireSave,
  getRepaireGetCar
} from '../../api/maintenance'
import { formatDate } from '../../common/js/dateformat'

export default {
  components: {
    coverImg
  },
  data () {
    return {
      repaireId: '',
      detailData: {},
      repaireId: '',
      addForm: {
        carKmAfter: '',
        carOilAfter: '',
        carOilKmImagesAfter: '',
        // repaireMoney: '',
        repaireImages: ''
        // manCost: ''
      },
      applyImgs: [],
      carOilKmImages: [],
      imgList: [],
      // imgArr:['http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111328219571.jpg','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111328219571.jpg','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111338540662.jpg ','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111338540662.jpg ','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111338540662.jpg ','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111338540662.jpg '],
      imgArr: [],
      iosVaild: utils.versions.ios,
      androidVaild: utils.versions.android,
      // imgOilArr:['http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111328219571.jpg','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111328219571.jpg','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111328219571.jpg','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111338540662.jpg ','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111338540662.jpg ','http://p6w6s2i05.bkt.clouddn.com/rescue/images/CS1806300920038180630111338540662.jpg '
      // ],
      imgOilArr: [],
      updateType: '',
      totleImg: 6,
      type: '',
      winHeight: document.body.clientHeight,
      showStatic: false,
      fixStyle: 'position:fixed',
      staticStyle: 'position:static'
    }
  },
  filters: {
    dateFormat (time) {
      if (time) {
        var date = new Date(time)
        return formatDate(date, 'yyyy-MM-dd hh:mm')
      }
    }
  },
  methods: {
    changeNum () {
      if (this.addForm.carKmAfter.length > 6) {
        this.addForm.carKmAfter = this.addForm.carKmAfter.slice(0, 6)
      }
    },
    // 删除图片
    handleDelImg (index, type) {
      if (type == 'repaire') {
        this.imgArr.splice(index, 1)
      } else if (type == 'oil') {
        this.imgOilArr.splice(index, 1)
      }
    },
    // 从app获取到图片
    toImgValue (rst) {
      var _this = this
      var arr = rst ? rst.split(',') : ''
      if (!arr) return false
      if (arr.length <= 0) return false
      // imgFlag = imgArr.length;//用来标记上次传了几张图片
      arr.forEach(function (v, i) {
        if (_this.updateType == 'repaire') {
          _this.imgArr.push(v)
        } else if (_this.updateType == 'oil') {
          _this.imgOilArr.push(v)
        }
      })
      // _this.lightImgBox();
    },
    // app调用toImgValue方法
    appImgUrl () {
      var _this = this
      if (this.iosVaild) {
        utils.setupWebViewJavascriptBridge(function (bridge) {
          bridge.registerHandler('toImgValue', function (response, call) {
            _this.toImgValue(response)
          })
        })
      } else if (this.androidVaild) {
        window.toImgValue = function (str) {
          _this.toImgValue(str)
        }
      }
    },
    // 点击加号上传图片
    handleUpdateImg (type) {
      var len = 0
      if (type == 'repaire') {
        this.updateType = 'repaire'
        len = this.totleImg - this.imgArr.length
      } else if (type == 'oil') {
        this.updateType = 'oil'
        len = this.totleImg - this.imgOilArr.length
      }
      adjust.goAppUploader(len)
    },
    // 图片放大
    handleImg (index, type) {
      if (type == 1) {
        this.imgList = this.applyImgs
      } else if (type == 2) {
        this.imgList = this.carOilKmImages
      } else if (type == 3) {
        this.imgList = this.imgArr
      } else if (type == 4) {
        this.imgList = this.imgOilArr
      }
      this.$refs.applyImg.viewImgSwiper = true
      if (this.$refs.applyImg.swiperImgBox) {
        this.$refs.applyImg.swiperImgBox.slideTo(index, 50, false)
      }
    },
    getDetailFun () {
      let para = {
        repaireId: this.repaireId
      }
      getRepaireDetail(para)
        .then(res => {
          if (res && res.resCode == 0) {
            if (res.data && res.data.errcode == 0 && res.data.data) {
              var data = res.data.data
              this.detailData = data
              if (this.detailData.applyImages) {
                this.applyImgs = this.detailData.applyImages.split(',')
              }
              if (this.detailData.carOilKmImages) {
                this.carOilKmImages = this.detailData.carOilKmImages.split(',')
              }
            } else {
              this.$toast(res.data.errmsg || '网络错误，请重试！')
            }
          } else {
            this.$toast(res.resMsg || '网络错误，请重试！')
          }
        })
        .catch(res => {})
    },
    // 保存
    handleSave () {
      var _this = this
      let para = Object.assign({}, this.addForm)
      para.repaireId = this.repaireId
      para.repaireImages = _this.imgArr.join(',')
      para.carOilKmImagesAfter = _this.imgOilArr.join(',')
      getRepaireSave(para)
        .then(res => {
          if (res && res.resCode == 0) {
            if (res.data && res.data.errcode == 0) {
              this.$toast('保存成功')
              setTimeout(function () {
                if (_this.type == 1) {
                  _this.$router.push({ path: '/maintenance/list' })
                } else if (_this.type == 2) {
                  _this.$router.push({ path: '/maintenance/myList' })
                }
              }, 2000)
            } else {
              this.$toast(res.data.errmsg || '网络错误，请重试！')
            }
          } else {
            this.$toast(res.resMsg || '网络错误，请重试！')
          }
        })
        .catch(res => {})
    },
    // 接车
    handleGetCar () {
      var _this = this
      this.checkForm(() => {
        let para = Object.assign({}, this.addForm)
        para.repaireId = this.repaireId
        para.repaireImages = _this.imgArr.join(',')
        para.carOilKmImagesAfter = _this.imgOilArr.join(',')
        getRepaireGetCar(para)
          .then(res => {
            if (res && res.resCode == 0) {
              if (res.data && res.data.errcode == 0) {
                this.$toast('接车成功')
                setTimeout(function () {
                  if (_this.type == 1) {
                    _this.$router.push({ path: '/maintenance/list' })
                  } else if (_this.type == 2) {
                    _this.$router.push({ path: '/maintenance/myist' })
                  }
                }, 2000)
              } else {
                this.$toast(res.data.errmsg || '网络错误，请重试！')
              }
            } else {
              this.$toast(res.resMsg || '网络错误，请重试！')
            }
          })
          .catch(res => {})
      })
    },
    checkForm (callback) {
      //   if (!this.addForm.repaireMoney) {
      //     this.$toast('请输入维修费用')
      //     return false
      //   } else if (!this.addForm.manCost) {
      //     this.$toast('请输入人工费用')
      //     return false
      //   } else
      if (!this.imgArr) {
        this.$toast('请上传维修照片')
        return false
      } else if (!this.addForm.carKmAfter) {
        this.$toast('请输入车辆里程')
        return false
      } else if (!this.addForm.carOilAfter) {
        this.$toast('请输入车辆油量')
        return false
      } else if (!this.imgOilArr) {
        this.$toast('请上传油量照片')
        return false
      } else {
        callback && callback()
      }
    }
  },

  mounted () {
    var _this = this
    this.type = this.$route.params.type || ''
    this.repaireId = this.$route.params.repaireId || ''
    this.getDetailFun()
    this.appImgUrl()
    window.onresize = () => {
      if (_this.androidVaild) {
        var curHeight = document.body.clientHeight
        if (curHeight < _this.winHeight) {
          _this.showStatic = true
        } else {
          _this.showStatic = false
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../styles/common';
.approval-wrap {
  background-color: #f5f4f3;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding-bottom: 1rem;
  @include box-sizing(border-box);
  overflow: auto;
  .apply-info {
    padding: 0.32rem;
    @include box-sizing(border-box);
    background-color: #fff;
    margin-bottom: 0.16rem;
    .cnt {
      font-size: 0.3rem;
      color: rgb(51, 51, 51);
      .left,
      .right {
        width: 50%;
      }
      .cnt-item {
        line-height: 0.6rem;
      }
      .time-box,
      .detail-box {
        .left {
          width: 32%;
        }
        .right {
          width: 68%;
        }
      }
      .detail-box {
        .left {
          width: 25%;
        }
        .right {
          width: 75%;
        }
      }
    }
  }
  .advice-info {
    .cnt {
      .left {
        width: 25%;
      }
      .right {
        width: 75%;
      }
    }
  }
  .send-info {
    padding: 0.32rem;
    @include box-sizing(border-box);
    background-color: #fff;
    margin-bottom: 0.16rem;
    .cnt {
      .left {
        width: 25%;
      }
      .right {
        width: 75%;
      }
    }
  }

  .form-box {
    border-bottom: 1px solid #eeebe9;
    background-color: #fff;
    height: 1rem;
    line-height: 0.9rem;
    padding: 0 0.3rem;
    label {
      width: 30%;
      font-size: 0.28rem;
      color: rgb(51, 51, 51);
    }
    input,
    .right-select {
      width: 70%;
      border: 0 none;
      line-height: 1rem;
      height: 1rem;
      text-align: right;
      font-size: 0.28rem;
      &.active {
        color: rgb(51, 51, 51);
      }
    }
    .right-select {
      color: rgb(153, 153, 153);
    }
  }
  .textarea-box {
    height: auto;
    margin-bottom: 0.1rem;
    textarea {
      display: block;
      border: 0 none;
      width: 100%;
    }
  }
}
.area_ctrl {
  background-color: #fff !important;
  .area_btn_box {
    background-color: #fff !important;
    &::before,
    &::after {
      background-color: #fff !important;
    }
  }
}
.ui-button {
  width: 100%;
  height: 1rem;
  background-color: rgb(251, 55, 91);
  text-align: center;
  line-height: 1rem;
  font-size: 0.32rem;
  color: #fff;
  cursor: pointer;
  .btn {
    width: 50%;
    &.btn1 {
      background-color: rgb(30, 135, 242);
      height: 1rem;
      line-height: 1rem;
    }
  }
}
</style>
