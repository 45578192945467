<template>
    <div class="approval-wrap leave-detail-wrap">
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                申请信息
            </div>
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">请假人：</div>
                    <div class="fl right">{{detailData.applyName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">请假类型：</div>
                    <div class="fl right">{{detailData.leaveTypeName}}</div>
                </div>
                <div class="cnt-item clear">
                    <div class="fl left">开始时间：</div><div class="fl right">{{detailData.startTime|dateFormat}}</div>
                </div>
                <div class="cnt-item clear">
                    <div class="fl left">结束时间：</div><div class="fl right">{{detailData.endTime|dateFormat}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">时长(小时)：</div>
                    <div class="fl right">{{detailData.leaveLong}}h</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">请假天数：</div>
                    <div class="fl right">{{detailData.leaveDays}}天</div>
                </div>
                 <div class="cnt-item clear detail-box">
                    <div class="fl left">请假事由：</div><div class="fl right">{{detailData.reason}}</div>
                </div>
                 <div class="cnt-item clear">
                    <div class="fl left">申请时间：</div><div class="fl right">{{detailData.createTime|dateFormat}}</div>
                </div>
            </div>  
        </div>
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                审批信息
            </div>
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">批假人：</div>
                    <div class="fl right">{{detailData.approveName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">审批状态：</div>
                    <div class="fl right" v-if="detailData.status=='0'">待审批</div>
                    <div class="fl right" v-else-if="detailData.status=='1'">审批通过</div>
                    <div class="fl right" v-else-if="detailData.status=='2'">驳回</div>
                    <div class="fl right" v-else-if="detailData.status=='-1'">撤销</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">审批时间：</div>
                    <div class="fl right">{{detailData.approveDate|dateFormat}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">审批意见：</div>
                    <div class="fl right">{{detailData.approveContent}}</div>
                </div>
            </div>  
        </div>
        <div class="apply-info send-info">
            <div class="info-title">
                <span class="line-icon"></span>
                休假信息
            </div>
            <div class="cnt">
                <div class="cnt-item clear">
                    <div class="fl left"  style="width:34%">开始休假时间：</div><div class="fl right"  style="width:66%">{{detailData.confirmStartTime|dateFormat}}</div>
                </div>
                <div class="cnt-item clear">
                    <div class="fl left" style="width:34%">结束休假时间：</div><div class="fl right" style="width:66%">{{detailData.confirmEndTime|dateFormat}}</div>
                </div>
            </div>  
        </div>
        <div class="ui-button" v-if="detailData.status=='0'" @click="handleDel">撤销</div>
        <div class="ui-button clear" v-else-if="detailData.status=='1'&&(!detailData.confirmStartTime||!detailData.confirmEndTime)">
            <div  :class="!detailData.confirmEndTime?'btn btn1 fl':''" v-if="!detailData.confirmStartTime" @click="startLeave()">开始休假</div>
            <div :class="!detailData.confirmStartTime?'btn fl':''" v-if="!detailData.confirmEndTime" @click="endLeave()">结束休假</div>
        </div>
    </div>  
</template>
<script>
    import {getLeaveDetail,getLeaveRevoke,getStartLeave,getEndLeave} from '../../api/leave'
    import {formatDate} from '../../common/js/dateformat'
    import adjust from '../../common/js/adjust';


    export default {
        components: {
            
        },
        data() {
            return {
                leaveId:'',
                detailData:{},
                type:'',
                isDel:false,
                btnClick:true,
            }
        },
        filters: {
            dateFormat(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd hh:mm');
                }
               
            }
        },
        methods: {
            getDetailFun(){
                let para = {
                    leaveId:this.leaveId
                }
                getLeaveDetail(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.detailData = data;

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            // 开始休假
            startLeave() {
                var _this = this;
                let para = {
                    leaveId:this.leaveId
                }
                if(_this.btnClick){
                    _this.btnClick = false;
                     getStartLeave(para).then(res => {
                        if(res&&res.resCode==0){
                            if(res.data&&res.data.errcode==0){
                                this.$toast('操作成功！');
                                 _this.getDetailFun();
                            }else{
                                this.$toast(res.data.errmsg||'网络错误，请重试！');
                                _this.btnClick = true;
                            }
                        }else{
                            this.$toast(res.resMsg||'网络错误，请重试！');
                            _this.btnClick = true;
                        }
                        
                    }).catch(res => {
                        _this.btnClick = true;
                    });
                }
            },
            // 结束休假
            endLeave() {
                var _this = this;
                let para = {
                    leaveId:this.leaveId
                }
                if(_this.btnClick){
                    _this.btnClick = false;
                     getEndLeave(para).then(res => {
                        if(res&&res.resCode==0){
                            if(res.data&&res.data.errcode==0){
                                this.$toast('操作成功！');
                                 _this.getDetailFun();
                            }else{
                                this.$toast(res.data.errmsg||'网络错误，请重试！');
                                _this.btnClick = true;
                            }
                        }else{
                            this.$toast(res.resMsg||'网络错误，请重试！');
                            _this.btnClick = true;
                        }
                        
                    }).catch(res => {
                        _this.btnClick = true;
                    });
                }
            },
            //撤销
            handleDel(){
                var _this = this;
                let para = {
                    leaveId:this.leaveId
                }
                if(_this.btnClick){
                    _this.btnClick = false;
                     getLeaveRevoke(para).then(res => {
                        if(res&&res.resCode==0){
                            if(res.data&&res.data.errcode==0){
                                this.isDel = true;
                                this.$toast('撤销成功！');
                                setTimeout(function(){
                                    if(_this.type==1){
                                        _this.$router.push({path: '/leave/list'});
                                    }else if(_this.type==2){
                                        _this.$router.push({path: '/leave/myList'});
                                    }
                                },2000)
                            }else{
                                this.$toast(res.data.errmsg||'网络错误，请重试！');
                                _this.btnClick = true;
                            }
                        }else{
                            this.$toast(res.resMsg||'网络错误，请重试！');
                            _this.btnClick = true;
                        }
                        
                    }).catch(res => {
                        _this.btnClick = true;
                    });
                }
               
            },

        },

        mounted() {
       	 	var _this = this;
            this.type = this.$route.params.type||'';
            this.leaveId = this.$route.params.leaveId||'';
            adjust.getTokenInfo(function(){
                _this.getDetailFun();
            });
        },
        beforeRouteLeave(to, from, next) {
            if(this.isDel){
                //to.meta.keepAlive = false;
                to.meta.isUpdate = true;  
            }else{
                to.meta.isUpdate = false; 
                
            }
           
            next();
        }
    }
</script>
<style  lang="scss">
    @import "../../styles/common";
    .leave-detail-wrap{
        padding-bottom:1rem;
        box-sizing: border-box;
        .cnt{
            .img-ul{
                li{
                    width:22%;
                    margin-right:4%;
                    margin-bottom:0.1rem;
                    cursor:pointer;
                    position:relative;
                    &:nth-of-type(4n){
                        margin-right:0;
                    }
                    &::before {
                        content: "";
                        padding-top: 100%;
                        display: block;
                    }
                    img{
                        position:absolute;
                        left:0;
                        top:0;
                        width:100%;
                        height:100%;
                    }
                }
            }
        }
    }
    .info-title{
        position:relative;
        line-height:0.4rem;
        padding-left:0.18rem;
        font-size:0.32rem;
        color:rgb(51,51,51);
        margin-bottom:0.32rem;
        .line-icon{
            position:absolute;
            left:0rem;
            top:0rem;
            width:0.08rem;
            height:0.4rem;
            background-color:rgb(38,41,56);
            @include border-radius(0.2rem);
        }
    }
    .approval-wrap{
        background-color:#F5F4F3;
        width:100%;
        .apply-info{
            padding:0.32rem;
            @include box-sizing(border-box);
            background-color:#fff;
            margin-bottom:0.16rem;
            .cnt{
                font-size:0.3rem;
                color:rgb(51,51,51);
                .left,.right{
                    width:50%;
                }
                .cnt-item{
                    line-height:0.6rem;
                }
                .time-box,.detail-box{
                    .left{
                        width:32%;
                    }
                    .right{
                        width:68%;
                    }
                }
                .detail-box{
                    .left{
                        width:25%;
                    }
                    .right{
                        width:75%;
                    }
                }
                

            }

        }
        .send-info{
            .cnt{
                .left{
                    width:25%;
                }
                .right{
                    width:75%;
                }
            }

        }
        .send-info{
            padding:0.32rem;
            @include box-sizing(border-box);
            background-color:#fff;
        }

        .form-box{
            border-bottom:1px solid #EEEBE9;
            background-color:#fff;
            height:1rem;
            line-height:0.95rem;
            padding:0 0.30rem;
            label{
                width:30%;
                font-size:0.28rem;
                color:rgb(51,51,51);
            }
            input,.right-select{
                width:70%;
                border: 0 none;
                line-height:0.95rem;
                height:0.95rem;
                text-align:right;
                font-size:0.28rem;
                &.active{
                    color:rgb(51,51,51);
                }
            }
            .right-select{
                color:rgb(153,153,153);
            }
        }
        .textarea-box{
            height:auto;
            margin-bottom:0.1rem;
            textarea{
                display:block;
                border:0 none;
                width:100%;
            }
        }
        
    }
    .area_ctrl{
        background-color:#fff!important;
        .area_btn_box{
            background-color:#fff!important;
            &::before,&::after{
                background-color:#fff!important;
            }
        }
    }
    .ui-button{
        width:100%;
        height:1rem;
        background-color:rgb(251,55,91);
        text-align:center;
        line-height:1rem;
        font-size:0.32rem;
        color:#fff;
        cursor:pointer;
        .btn{
            width:50%;
            &.btn1{
                background-color:rgb(30,135,242)
            }
        }
    }
    
</style>



