// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import VueRouter from 'vue-router'
import routes from './routes'
import rem from './common/js/rem'
import { Alert, Confirm, Toast, Loading } from 'wc-messagebox'
import 'wc-messagebox/style.css'
import './assets/iconfont/iconfont.css'
import { DatetimePicker, Checklist } from 'mint-ui';
import { Calendar } from 'vant';
import AwesomePicker from 'vue-awesome-picker';
import 'mint-ui/lib/style.css'
import 'babel-polyfill'
import Es6Promise from 'es6-promise'

require('es6-promise').polyfill()
Es6Promise.polyfill()
console.log(rem)
Vue.use(Calendar);




Vue.use(AwesomePicker);

Vue.use(Alert)
Vue.use(Confirm)
Vue.use(Toast)
Vue.use(Loading)

//Vue.use(Swiper)


Vue.use(VueRouter)
Vue.component(DatetimePicker.name, DatetimePicker)
Vue.component(Checklist.name, Checklist)



//Vue.config.productionTip = false

var router = new VueRouter({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },
    //base:'/school0514/',
    //mode: 'hash',
    routes
})
/* eslint-disable no-new */
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
});
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

