export default {
    // qnUrl:function(url){
    //     url = url.replace(/(\/|&nbsp;|\s+)$/g,"");
    //     if(!/^(http|https)/g.test(url)){
    //         if(url && url!="" && url.indexOf('niannianyun')>=0){
    //             return Url.fileDomain+url;
    //         } else if(/^uploadFiles/g.test(url)  && !/^http|https/.test(url)){
    //             return 'http://www.niannian99.com/'+url;
    //         }else{
    //             return window.location.host+url;
    //         }
    //     }else{
    //         return url;
    //     }
    // },
    // // formatImg:function (img) {
    //     if(img && /og0bztn1p|file.niannian99/.test(img)){
    //         return img + '?imageView2/1/w/1000/h/1000';
    //     }else {
    //         return img;
    //     }
    // },
    //时间转换
    dateFormat:function (date,num,type) {
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        if(num){
            if(type){
                return y + '-' + m + '-' + d;
            }else{
                return y + '年' + m + '月' + d + '日';
            }
        }else{
            if(type){
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute;
            }else{
                return y + '年' + m + '月' + d + '日  ' + h + ':' + minute;
            }
        }
    },
    dateFormat2(date){
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return month + '月' + day + '日';
    },
    // //读取服务器时间
    // getCurrentTime(callback){
    //     var xhr = null;
    //     var time = null, curDate = null;
    //     if(window.XMLHttpRequest){
    //         xhr = new window.XMLHttpRequest();
    //     }else{ // ie
    //         xhr = new ActiveObject("Microsoft")
    //     }
    //     // 通过get的方式请求当前文件
    //     xhr.open("get","/");
    //     xhr.send(null);
    //     // 监听请求状态变化
    //     xhr.onreadystatechange = function(){
    //         if(xhr.readyState===2){
    //             // 获取响应头里的时间戳
    //             time = xhr.getResponseHeader("Date");
    //             curDate = new Date(time);
    //             callback(curDate);
    //         }
    //     }
    // },
    //数字加逗号
    toThousands(num) {
        var result = '', counter = 0;
        num = (num || 0).toString();
        for (var i = num.length - 1; i >= 0; i--) {
            counter++;
            result = num.charAt(i) + result;
            if (!(counter % 3) && i != 0) {
                result = ',' + result;

            }
        }
        return result;
    },
    toJSON:function(data){
        try{
            return JSON.parse(data);
        }catch(e){
            return null;
        }
    },
    toJSONSTR:function(data){
        try{
            return JSON.stringify(data);
        }catch(e){
            console.log(e.description)
            return "";
        }
    },
    getQueryString:function() {
        let str = location.href;
        let num = str.indexOf('?');
        const param = {};
        str = str.substr(num + 1);
        const arr = str.split('&');
        for (let i = 0; i < arr.length; i++) {
            num = arr[i].indexOf('=');
            if (num > 0) {
                const name = arr[i].substring(0, num);
                const value = arr[i].substr(num + 1);
                param[name] = decodeURI(value);
            }
        }
        return param
    },
    putb64(picBase,callback){
        var pic = picBase,that=this;
        var fileName = 'nianniayun/run/'+parseInt(new Date().getTime()/1000)+'.png';
        this.getToken({"fileName":fileName},function(res){
            /*picUrl用来存储返回来的url*/
            /*把头部的data:image/png;base64,去掉。（注意：base64后面的逗号也去掉）*/
            picBase=picBase.substring(22);
            /*通过base64编码字符流计算文件流大小函数*/
            function fileSize(str) {
                var fileSize;
                if(str.indexOf('=')>0)  {
                    var indexOf=str.indexOf('=');
                    str=str.substring(0,indexOf);//把末尾的’=‘号去掉
                }
                fileSize=parseInt(str.length-(str.length/8)*2);
                return fileSize;
            }

            var url = "http://up-z0.qiniup.com/putb64/"+fileSize(picBase);
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange=function(){
                if (xhr.readyState==4){
                    var keyText=xhr.responseText;
                    /*返回的key是字符串，需要装换成json*/
                    keyText=that.toJSON(keyText);
                    callback && callback(keyText.key);
                }
            };
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/octet-stream");
            xhr.setRequestHeader("Authorization", "UpToken "+res+"");
            xhr.send(picBase);
        });

    },
    // getToken(obj,callback) {
    //     Ajax.post({
    //         url: Url.appBaseUrl + '/qiniu/getToken?fileName='+obj.fileName,
    //         success: (res) => {
    //             if(res.success && res.data){
    //                 callback && callback(res.data.token);
    //             }
    //         }
    //     })
    // },
    //终端类型
    versions : function () {
        var u = navigator.userAgent, app = navigator.appVersion,us=u.toLowerCase();
        return {         //移动终端浏览器版本信息
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            isIOS11:function(){
                var ver = u.toLocaleLowerCase().match(/cpu iphone os (.*?) like mac os/);
                if(!ver){
                    return false;
                }else{
                    var ver=ver[1].replace(/_/g,".");
                    if (parseFloat(ver)>=11) {
                        return true;
                    }else{
                        return false;
                    }
                }
            },
            webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
            weixin:(/MicroMessenger/.test(u)) ? true : false //判断是否为微信打开
        }
    }(),
    setupWebViewJavascriptBridge:function(callback) {
        if (window.WebViewJavascriptBridge) { return callback(WebViewJavascriptBridge); }
        if (window.WVJBCallbacks) {return window.WVJBCallbacks.push(callback); }
        window.WVJBCallbacks = [callback];
        var WVJBIframe = document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(function() { document.documentElement.removeChild(WVJBIframe) }, 0);
    },
    getWebViewJsBridge:function(name,data,callback){
        var self = this;
        self.setupWebViewJavascriptBridge(function(bridge) {
            bridge.callHandler(name,data,function(response){
                callback && callback(response);
            })
        });
    }
}