<template>
  <div class="approval-wrap">
    <div class="apply-info">
      <div class="info-title">
        <span class="line-icon"></span>
        申请信息
      </div>
      <div class="cnt">
        <div class="clear cnt-item">
          <div class="fl left">
            申请人：
            <span>{{detailData.driveName}}</span>
          </div>
          <div class="fl right">
            车牌号：
            <span>{{detailData.carNo}}</span>
          </div>
        </div>
        <div class="cnt-item">
          <div class="left" style="width:100%">
            申请类型：
            <span>{{detailData.repaireTypeName}}</span>
          </div>
          <!-- <div class="fl right">紧急程度：<span>一般</span></div> -->
        </div>
        <div class="cnt-item">
          <div class>
            主要部件：
            <span>{{detailData.repaireParts?detailData.repaireParts.join('，'):''}}</span>
          </div>
        </div>
        <div class="cnt-item clear time-box">
          <div class="fl left">期望修理时间：</div>
          <div class="fl right">{{detailData.applyDate|dateFormat}}</div>
        </div>
        <div class="cnt-item clear detail-box">
          <div class="fl left">申请说明：</div>
          <div class="fl right">{{detailData.applyContent}}</div>
        </div>
        <div class="img-box ui-img-wrap">
          <ul class="img-ul clear">
            <li class="fl" v-for="(list,index) in (applyImgs?applyImgs:[])" :key="index">
              <div class="img">
                <img class="jMaxImg big-img" :src="list" @click="handleImg(index,1)" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="apply-info send-info">
      <div class="info-title">
        <span class="line-icon"></span>
        审批意见
      </div>
      <div class="cnt">
        <div class="clear cnt-item">
          <div class="fl left">审批意见：</div>
          <div class="fl right">{{detailData.applyMsg}}</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">维修厂：</div>
          <div class="fl right">{{detailData.repaireCompany}}</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">维修地址：</div>
          <div class="fl right">{{detailData.repaireAddress}}</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">联系人员：</div>
          <div class="fl right">{{detailData.repaireCompanyMan}}</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">联系电话：</div>
          <div class="fl right">{{detailData.repairePhone}}</div>
        </div>
        <div class="cnt-item clear">
          <div class="fl left">维修时间：</div>
          <div class="fl right">{{detailData.repaireDate|dateFormat}}</div>
        </div>
      </div>
    </div>
    <div class="apply-info send-info" v-if="detailData.carKm">
      <div class="info-title">
        <span class="line-icon"></span>
        送车信息
      </div>
      <div class="cnt">
        <div class="clear cnt-item">
          <div class="fl left">车辆里程：</div>
          <div class="fl right">{{detailData.carKm}}km</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">车辆油量：</div>
          <div class="fl right">{{detailData.carOil}}L</div>
        </div>
        <div class="img-box ui-img-wrap">
          <ul class="img-ul clear">
            <li class="fl" v-for="(list,index) in (carOilKmImages?carOilKmImages:[])" :key="index">
              <div class="img">
                <img class="jMaxImg big-img" :src="list" @click="handleImg(index,2)" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="apply-info send-info" v-if="detailData.repaireDetail">
      <div class="info-title">
        <span class="line-icon"></span>
        维修信息
      </div>
      <div class="cnt">
        <!-- <div class="clear cnt-item">
                    <div class="fl left">材料费用：</div>
                    <div class="fl right">￥{{detailData.repaireMoney}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">工时费用：</div>
                    <div class="fl right">￥{{detailData.manCost}}</div>
        </div>-->
        <div class="clear cnt-item">
          <div class="fl left">维修明细：</div>
          <div class="fl right" v-html="detailData.repaireDetail"></div>
        </div>
        <div class="cnt-item">
          <div>维修和工单照片：</div>
          <div class="img-box ui-img-wrap">
            <ul class="img-ul clear">
              <li class="fl" v-for="(list,index) in (repaireImages?repaireImages:[])" :key="index">
                <div class="img">
                  <img class="jMaxImg big-img" :src="list" @click="handleImg(index,3)" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="apply-info send-info" v-if="detailData.carKmAfter">
      <div class="info-title">
        <span class="line-icon"></span>
        接车信息
      </div>
      <div class="cnt">
        <div class="clear cnt-item">
          <div class="fl left">车辆里程：</div>
          <div class="fl right">{{detailData.carKmAfter}}km</div>
        </div>
        <div class="clear cnt-item">
          <div class="fl left">车辆油量：</div>
          <div class="fl right">{{detailData.carOilAfter}}L</div>
        </div>
        <div class="cnt-item">
          <div>里程照片：</div>
          <div class="img-box ui-img-wrap">
            <ul class="img-ul clear">
              <li class="fl" v-for="(list,index) in (carOilKmImagesAfter?carOilKmImagesAfter:[])" :key="index">
                <div class="img">
                  <img class="jMaxImg big-img" :src="list" @click="handleImg(index,4)" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <cover-img ref="applyImg" :imgArr="imgArr"></cover-img>
    <div
      class="ui-button"
      v-if="detailData.status=='0'||detailData.status=='1'"
      @click="handleDel"
    >撤销</div>
    <div class="ui-button" v-else-if="detailData.status=='2'" @click="handleJump('send')">送车维修</div>
    <div class="ui-button" v-else-if="detailData.status=='3'" @click="handleJump('get')">接车</div>
  </div>
</template>
<script>
import adjust from '../../common/js/adjust'
import coverImg from '../../components/coverImg'
import { getRepaireDetail, getRepaireDelete } from '../../api/maintenance'
import { formatDate } from '../../common/js/dateformat'

export default {
  components: {
    coverImg
  },
  data () {
    return {
      repaireId: '',
      detailData: {},
      viewImgSwiper: false,
      applyImgs: [],
      carOilKmImages: [],
      imgArr: [],
      repaireImages: [],
      carOilKmImagesAfter: [],
      type: '',
      isDel: false,
      btnClick: true
    }
  },
  filters: {
    dateFormat (time) {
      if (time) {
        var date = new Date(time)
        return formatDate(date, 'yyyy-MM-dd hh:mm')
      }
    }
  },
  methods: {
    // 图片放大
    handleImg (index, type) {
      if (type == 1) {
        this.imgArr = this.applyImgs
      } else if (type == 2) {
        this.imgArr = this.carOilKmImages
      } else if (type == 3) {
        this.imgArr = this.repaireImages
      } else if (type == 4) {
        this.imgArr = this.carOilKmImagesAfter
      } else {
        this.imgArr = []
      }
      this.$refs.applyImg.viewImgSwiper = true
      if (this.$refs.applyImg.swiperImgBox) {
        this.$refs.applyImg.swiperImgBox.slideTo(index, 50, false)
      }
    },
    getDetailFun () {
      let para = {
        repaireId: this.repaireId
      }
      getRepaireDetail(para)
        .then(res => {
          if (res && res.resCode == 0) {
            if (res.data && res.data.errcode == 0 && res.data.data) {
              var data = res.data.data
              this.detailData = data
              if (this.detailData.applyImages) {
                this.applyImgs = this.detailData.applyImages.split(',')
              }
              if (this.detailData.carOilKmImages) {
                this.carOilKmImages = this.detailData.carOilKmImages.split(',')
              }
              if (this.detailData.repaireImages) {
                this.repaireImages = this.detailData.repaireImages.split(',')
              }
              if (this.detailData.carOilKmImagesAfter) {
                this.carOilKmImagesAfter = this.detailData.carOilKmImagesAfter.split(
                  ','
                )
              }
            } else {
              this.$toast(res.data.errmsg || '网络错误，请重试！')
            }
          } else {
            this.$toast(res.resMsg || '网络错误，请重试！')
          }
        })
        .catch(res => {})
    },
    // 撤销
    handleDel () {
      var _this = this
      let para = {
        repaireId: this.repaireId
      }
      if (_this.btnClick) {
        _this.btnClick = false
        getRepaireDelete(para)
          .then(res => {
            if (res && res.resCode == 0) {
              if (res.data && res.data.errcode == 0) {
                this.isDel = true
                this.$toast('撤销成功！')
                setTimeout(function () {
                  if (_this.type == 1) {
                    _this.$router.push({ path: '/maintenance/list' })
                  } else if (_this.type == 2) {
                    _this.$router.push({ path: '/maintenance/myList' })
                  }
                }, 2000)
              } else {
                this.$toast(res.data.errmsg || '网络错误，请重试！')
                _this.btnClick = true
              }
            } else {
              this.$toast(res.resMsg || '网络错误，请重试！')
              _this.btnClick = true
            }
          })
          .catch(res => {
            _this.btnClick = true
          })
      }
    },
    handleJump (type) {
      if (type && type === 'send') {
        this.$router.push({
          path: '/maintenance/sendCar/' + this.repaireId + '/' + this.type
        })
      } else if (type && type === 'get') {
        this.$router.push({
          path: '/maintenance/receiveCar/' + this.repaireId + '/' + this.type
        })
      }
    }
  },

  mounted () {
    var _this = this
    this.type = this.$route.params.type || ''
    this.repaireId = this.$route.params.repaireId || ''
    adjust.getTokenInfo(function () {
      _this.getDetailFun()
    })
  },
  beforeRouteLeave (to, from, next) {
    if (this.isDel) {
      // to.meta.keepAlive = false;
      to.meta.isUpdate = true
    } else {
      to.meta.isUpdate = false
    }

    next()
  }
}
</script>
<style scoped lang="scss">
@import '../../styles/common';
.info-title {
  position: relative;
  line-height: 0.4rem;
  padding-left: 0.18rem;
  font-size: 0.32rem;
  color: rgb(51, 51, 51);
  margin-bottom: 0.32rem;
  .line-icon {
    position: absolute;
    left: 0rem;
    top: 0rem;
    width: 0.08rem;
    height: 0.4rem;
    background-color: rgb(38, 41, 56);
    @include border-radius(0.2rem);
  }
}
.approval-wrap {
  background-color: #f5f4f3;
  width: 100%;
  padding-bottom: 1rem;
  @include box-sizing(border-box);
  .apply-info {
    padding: 0.32rem;
    @include box-sizing(border-box);
    background-color: #fff;
    margin-bottom: 0.16rem;
    .cnt {
      font-size: 0.3rem;
      color: rgb(51, 51, 51);
      .left,
      .right {
        width: 50%;
      }
      .cnt-item {
        line-height: 0.6rem;
      }
      .time-box,
      .detail-box {
        .left {
          width: 32%;
        }
        .right {
          width: 68%;
        }
      }
      .detail-box {
        .left {
          width: 25%;
        }
        .right {
          width: 75%;
        }
      }
    }
  }
  .send-info {
    .cnt {
      .left {
        width: 25%;
      }
      .right {
        width: 75%;
      }
    }
  }
  .send-info {
    padding: 0.32rem;
    @include box-sizing(border-box);
    background-color: #fff;
  }

  .form-box {
    border-bottom: 1px solid #eeebe9;
    background-color: #fff;
    height: 1rem;
    line-height: 0.95rem;
    padding: 0 0.3rem;
    label {
      width: 30%;
      font-size: 0.28rem;
      color: rgb(51, 51, 51);
    }
    input,
    .right-select {
      width: 70%;
      border: 0 none;
      line-height: 0.95rem;
      height: 0.95rem;
      text-align: right;
      font-size: 0.28rem;
      &.active {
        color: rgb(51, 51, 51);
      }
    }
    .right-select {
      color: rgb(153, 153, 153);
    }
  }
  .textarea-box {
    height: auto;
    margin-bottom: 0.1rem;
    textarea {
      display: block;
      border: 0 none;
      width: 100%;
    }
  }
}
.area_ctrl {
  background-color: #fff !important;
  .area_btn_box {
    background-color: #fff !important;
    &::before,
    &::after {
      background-color: #fff !important;
    }
  }
}
.ui-button {
  width: 100%;
  height: 1rem;
  background-color: rgb(251, 55, 91);
  text-align: center;
  line-height: 1rem;
  font-size: 0.32rem;
  color: #fff;
  cursor: pointer;
  .btn {
    width: 50%;
    &.btn1 {
      background-color: rgb(30, 135, 242);
    }
  }
}
</style>
