<template>
    <div class="list-wrap">
        <div id="mescroll" class="mescroll">
            <ul class="cnt-ul">
                <li class="" v-for='(list,index) in dataList' :key="index" @click="handleDetail(list)">
                    <div class="item1"> 
                        <span class="it11">{{list.docName}}</span>
                    </div>
                    <div class="item2 clear">
                        <span class="fl i1">{{list.docTypeName}}</span>
                        <span class="fl i2">{{list.dispatch}}</span>
                        <span class="fl i3">{{list.readStatusStr}}</span>
                        <span class="fl i4">{{list.createDate|dateFormat}}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    
</template>
<script>
    import adjust from '../../common/js/adjust';
    import MeScroll from 'mescroll.js'
    import 'mescroll.js/mescroll.min.css'
    import {formatDate} from '../../common/js/dateformat'
    import {getDucumentList} from '../../api/document'
    // import '../../common/js/plug/mui/mui.pullToRefresh.js'
    // import '../../common/js/plug/mui/mui.pullToRefresh.material.js'
    // import mui from '../../common/js/plug/mui/mui.min.js'

    export default {
        data() {
            return {
                mescroll: null,
                pageNo:1,
                pageSize:10,
                dataList:null,
                scrollData:0,
                
            }
        },
        filters: {
            dateFormat(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd');
                }   
            }
        },
        methods: {

            handleDetail(list){
                this.$router.push({path: '/document/detail/'+list.docId});
            },
            //上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
            upCallback(page) {
                let para = {
                    pageNo : page.num,
                }
                getDucumentList(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            if (page.num == 1) this.dataList = [];
                            this.dataList = this.dataList.concat(data);
                            this.$nextTick(() => {
                                this.mescroll.endSuccess(data.length);
                            })
                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                
                }).catch(res => {
                   this.mescroll.endErr();
                });
                
            }
        },

        mounted() {
            this.scrollData = 0;
            var _this = this;
            adjust.getTokenInfo(function(){
                 //创建MeScroll对象
                _this.mescroll = new MeScroll("mescroll", { //在vue的mounted生命周期初始化mescroll,确保此处配置的id能够被找到
                    up: {
                        callback: _this.upCallback,
                        toTop: { //配置回到顶部按钮
                           //src: require('@/assets/img/mescroll-totop.png') //此处动态引入assets的文件需用require
                        },
                        empty: {
                        //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
                            warpId: "mescroll", //父布局的id (1.3.5版本支持传入dom元素)
                            icon: require('@/common/images/icon_empty.png'), //图标,默认null,支持网络图
                            tip: "暂无数据" //提示
                        },
                    }
                });
            });
           
        },
        activated(){
            var _this = this;
            if(_this.$route.meta.isUpdate){
                _this.scrollData = 0;
                _this.mescroll.resetUpScroll();
            }
            document.getElementById('mescroll').scrollTop = this.scrollData;
        },
        beforeRouteEnter(to, from, next) {
            var _this = this;
            next();
        },
        beforeRouteLeave(to, from, next) {
            this.scrollData = document.getElementById('mescroll').scrollTop;
            next();
        }
    }
</script>
<style lang="scss">
@import "../../styles/common";
    .list-wrap{
        background-color:#F5F4F3;
        width:100%;
        height:100%;
        position:fixed;
        left:0;
        top:0;
    }
    .tab-wrap{
        height:0.8rem;
        line-height:0.8rem;
        background-color:#fff;
        margin-bottom:0.16rem;
        position:fixed;
        left:0;
        top:0;
        width:100%;
    }
    .tab-ul{
        li{
            width:33.33%;
            height:0.8rem;
            text-align:center;
            line-height:0.8rem;            
            font-size:0.30rem;
            box-sizing:border-box;
            cursor:pointer;
            color:rgb(153,153,153);
            &.active{
                color:rgb(38,41,56);
                border-bottom:1px solid rgb(38,41,56);
            }
        }
    }
     .mescroll {
        position: fixed;
        top: 0;
        left: 0;
        height: auto;
    }
    .cnt-ul{
        li{
            font-size:0.28rem;
            background-color:#fff;
            padding:0.3rem 0.3rem;
            margin-bottom:0.1rem;
            .item1{
                line-height:0.5rem;
                word-wrap: break-word;
                .date{
                    color:rgb(102,102,102);
                }
            }
            .it11{
                color:rgb(51,51,51);
                margin-right:0.08rem;
            }
            .it12{
                color:rgb(254,52,90);
            }
            .item2,.item3{
                color:rgb(102,102,102);
                line-height:0.66rem;
                .it21{
                    margin-right:0.08rem;
                }
                .i1,.i3{
                    width:20%;
                }
                .i2,.i4{
                    width:30%
                }
            }
        }
    }
</style>