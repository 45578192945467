<template>
    <div class="approval-wrap">
        <div class="apply-info send-info">
            <div class="cnt">
                <div class="clear cnt-item">
                    <div class="fl left">司机姓名：</div>
                    <div class="fl right">{{memberName}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">车牌号码：</div>
                    <div class="fl right">{{detailData.carNo}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">上报月份：</div>
                    <div class="fl right">{{detailData.reportDate}}</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">车辆里程：</div>
                    <div class="fl right">{{detailData.carKm}}公里</div>
                </div>
                <div class="clear cnt-item">
                    <div class="fl left">剩余油量：</div>
                    <div class="fl right">{{detailData.oilMass}}升</div>
                </div>
                <div class="cnt-item">
                    <div>油量/里程照片：</div>
                    <div class="img-box ui-img-wrap">
                        <ul class="img-ul clear">
                            <li class="fl" v-for="(list,index) in (images?images:[])" :key="index">
                                <div class='img'>
                                    <img class='jMaxImg big-img'  :src='list' @click="handleImg(index,3)">
                                </div>
                             </li>
                        </ul>
                    </div>
                </div>
            </div>  
        </div>
        <div class="ui-button"  @click="handleDel" v-if="detailData.canRevoke">撤销</div>
        <cover-img ref="applyImg" :imgArr="images"></cover-img>
    </div>  
</template>
<script>
    import coverImg from '../../components/coverImg'
    import {getOilReportDetail,getOilReportDelete} from '../../api/oilReport'
    import {getMemberInfo} from '../../api/common'
    import {formatDate} from '../../common/js/dateformat'
    


    export default {
        components: {
            coverImg
        },
        data() {
            return {
                reportId:'',
                detailData:{},
                memberName:'',
                images:[],
                isDel:false,
                btnClick:true,
            }
        },
        filters: {
            dateFormat(time) {
                if(time){
                    var date = new Date(time);
                    return formatDate(date,'yyyy-MM-dd hh:mm');
                }
               
            }
        },
        methods: {
            //图片放大
            handleImg(index,type){
                this.$refs.applyImg.viewImgSwiper = true;
                if(this.$refs.applyImg.swiperImgBox){
                    this.$refs.applyImg.swiperImgBox.slideTo(index, 50, false);
                }
            },
            //获取到自己的信息
            getMyInfo(){
                getMemberInfo({}).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.memberName = data.memberName||'';

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            getDetailFun(){
                let para = {
                    reportId:this.reportId
                }
                getOilReportDetail(para).then(res => {
                    if(res&&res.resCode==0){
                        if(res.data&&res.data.errcode==0&&res.data.data){
                            var data = res.data.data;
                            this.detailData = data;
                            if(this.detailData.images){
                                this.images = this.detailData.images.split(',');
                            }

                        }else{
                            this.$toast(res.data.errmsg||'网络错误，请重试！');
                        }
                    }else{
                        this.$toast(res.resMsg||'网络错误，请重试！');
                    }
                    
                }).catch(res => {
                  
                });
            },
            //撤销
            handleDel(){
                var _this = this;
                let para = {
                    reportId:this.reportId
                }
                if(_this.btnClick){
                    _this.btnClick = false;
                    getOilReportDelete(para).then(res => {
                        if(res&&res.resCode==0){
                            if(res.data&&res.data.errcode==0){
                                this.isDel = true;
                                this.$toast('撤销成功！');
                                setTimeout(function(){
                                    _this.$router.push({path: '/oilReport/list'});
                                },2000)
                            }else{
                                this.$toast(res.data.errmsg||'网络错误，请重试！');
                                _this.btnClick = true;
                            }
                        }else{
                            this.$toast(res.resMsg||'网络错误，请重试！');
                            _this.btnClick = true;
                        }
                        
                    }).catch(res => {
                        _this.btnClick = true;
                      
                    });
                }
                
            },

        },

        mounted() {
            this.reportId = this.$route.params.reportId||'';
            this.getMyInfo();
            this.getDetailFun();
          

        },
        beforeRouteLeave(to, from, next) {
            if(this.isDel){
                to.meta.isUpdate = true;  
            }else{
                to.meta.isUpdate = false;   
            }
            next();
        }
    }
</script>
<style scoped lang="scss">
    @import "../../styles/common";
    .approval-wrap{
        background-color:#F5F4F3;
        width:100%;
        .apply-info{
            padding:0.32rem;
            @include box-sizing(border-box);
            background-color:#fff;
            margin-bottom:0.16rem;
            .cnt{
                font-size:0.3rem;
                color:rgb(51,51,51);
                .left,.right{
                    width:50%;
                }
                .cnt-item{
                    line-height:0.6rem;
                }
                .time-box,.detail-box{
                    .left{
                        width:32%;
                    }
                    .right{
                        width:68%;
                    }
                }
                .detail-box{
                    .left{
                        width:25%;
                    }
                    .right{
                        width:75%;
                    }
                }

            }

        }
        .send-info{
            .cnt{
                .left{
                    width:25%;
                }
                .right{
                    width:75%;
                }
            }

        }
        .send-info{
            padding:0.32rem;
            @include box-sizing(border-box);
            background-color:#fff;
        }

        .form-box{
            border-bottom:1px solid #EEEBE9;
            background-color:#fff;
            height:1rem;
            line-height:0.95rem;
            padding:0 0.30rem;
            label{
                width:30%;
                font-size:0.28rem;
                color:rgb(51,51,51);
            }
            input,.right-select{
                width:70%;
                border: 0 none;
                line-height:0.95rem;
                height:0.95rem;
                text-align:right;
                font-size:0.28rem;
                &.active{
                    color:rgb(51,51,51);
                }
            }
            .right-select{
                color:rgb(153,153,153);
            }
        }
        .textarea-box{
            height:auto;
            margin-bottom:0.1rem;
            textarea{
                display:block;
                border:0 none;
                width:100%;
            }
        }
        
    }
    .area_ctrl{
        background-color:#fff!important;
        .area_btn_box{
            background-color:#fff!important;
            &::before,&::after{
                background-color:#fff!important;
            }
        }
    }
    .ui-button{
        width:100%;
        height:1rem;
        background-color:rgb(251,55,91);
        text-align:center;
        line-height:1rem;
        font-size:0.32rem;
        color:#fff;
        cursor:pointer;
        .btn{
            width:50%;
            &.btn1{
                background-color:rgb(30,135,242)
            }
        }
    }
</style>



